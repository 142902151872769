import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  Wrapper,
  BrandArea,
  BrandLogo,
  BrandDetails,
  BrandTitle,
  EventCount,
  BrandImage,
} from './style';

const CategoryBoxes = ({
  name,
  logo,
  event_count,
  // color_type,
  color,
  loading,
  onClick
}) => {

  if (loading) {
    return (
      <Wrapper $color={`#43535b50`}>
        <BrandArea>
          {/* <BrandLogo $sx={{ width: '55px !important', marginLeft: '10px' }}>
            <Skeleton
              height={60}
              width={55}
              borderRadius={10}
              baseColor={'#FFFCF9'}
              style={{ opacity: .4 }}
              highlightColor={'#21212150'}
            />
          </BrandLogo> */}

          <BrandDetails $sx={{ width: '60%', marginLeft: '5px' }}>
            <Skeleton
              count={2}
              height={20}
              baseColor={'#FFFCF9'}
              style={{ opacity: .4 }}
              highlightColor={'#21212150'}
            />
          </BrandDetails>
        </BrandArea>
      </Wrapper>
    )
  };

  return (
    <Wrapper $color={color} onClick={() => onClick?.(name)}>
      <BrandArea>
        {/* {!!logo &&
          <BrandLogo>
            <BrandImage src={logo} alt={`${name || 'brand'} logo`} />
          </BrandLogo>
        } */}
        <BrandDetails>
          <BrandTitle>{name}</BrandTitle>
          <EventCount>{event_count} events</EventCount>
        </BrandDetails>
      </BrandArea>
    </Wrapper>
  )
};

export default CategoryBoxes;
