import styled, { css } from 'styled-components';

const sizeGenenarate = (size, type, br) => {
  // br -> { "sm": 576, "md": 768, "lg": 992, "xl": 1200, "xxl": 1400 }
  const modalSizes = {
    lg: { width: '684px', height: '596px' },
    md: { width: '448px', height: '353px' },
    default: { width: 'auto', height: 'auto' },
  }
  switch (size) {
    case 'lg':
      return modalSizes.lg;
    case 'md':
      return modalSizes.md;
    default:
      return modalSizes.default;
  };
};
export const BaseModal = styled.div`
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border: none;
  background: #fff;
  box-shadow: 0px 12px 52px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).width};
  /* height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height}; */
  
  min-height: ${({ $size, theme }) => sizeGenenarate($size, theme.breakpoints).height};
  
  max-height: 90vh;
  max-width: 90vw;
  min-width: 300px;
  min-height: 300px;

  overflow: hidden;
  display: -webkit-box;
  ${({ $customModalStyle }) => $customModalStyle}
`;

export const Overlay = styled.div`
  position: fixed;
  background: #1E1E1E72;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  overflow: hidden;
`;

export const ModalContent = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  ${({ $customContentStyle }) => $customContentStyle}
`;

export const ModalHeader = styled.div`
  display: flex;
  /* position: relative; */
  align-items: center;
  justify-content: center;
  height: 40px;
  padding-top: 20px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  ${({ $hasCustomTitle }) => $hasCustomTitle && css`
    justify-content: unset;
    padding-left: 16px;
  `}
`;

export const ModalTitle = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #203662;
  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  cursor: pointer;
`;

export const SheetHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $sx }) => $sx && $sx}
`;

export const SheetCloseBtn = styled.button`
  position: absolute;
  right: 16px;
  /* padding: 8.04px; */
  width: 24px;
  height: 24px;
  outline: none;
  box-sizing: border-box;
  position: absolute;
  background: #FFFFFF;
  border: 0.6px solid #E1E1E1;
  border-radius: 4.8px;
  display: grid;
  place-items: center;
  &>svg {
    width: 10px;
    height: 10px;
  }
`;
