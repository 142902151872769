import React from 'react';
import { ReactComponent as AvatarPlaceHolder } from 'assets/img/icons/speaker.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  Container,
  Content,
  PersonInfo,
  Avatar,
  TTitle,
  TName,
  TProfession,
  TLocation,
  PlaceHolderAvatar,
  DetailBtn
} from './style';

const Speaker = ({
  name,
  title,
  profession,
  jobLocation,
  avatar,
  openSpeakerDetail,
  description,
  events
}) => {
  const { t } = React.useContext(LanguageContext);

  const handleDetail = () => {
    const payload = {
      name,
      title,
      profession,
      jobLocation,
      avatar,
      description,
      events
    };
    openSpeakerDetail(payload);
  }

  return (
    <Container>
      {avatar ?
        <Avatar src={avatar} />
        :
        <PlaceHolderAvatar>
          <AvatarPlaceHolder />
        </PlaceHolderAvatar>
      }
      <Content>
        <PersonInfo>
          <TTitle>{title}</TTitle>
          <TName>{name}</TName>
          <TProfession>{profession}</TProfession>
          <TLocation>{jobLocation}</TLocation>
        </PersonInfo>

        <DetailBtn onClick={handleDetail} >{t('words.detail')}</DetailBtn>
      </Content>
    </Container>
  )
};

export default Speaker;
