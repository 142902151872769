import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/img/icons/arrow.svg';
import { PATHS } from 'routes/paths';
import { LanguageContext } from 'context/withLanguages';
import { useDimensions } from 'hooks/useDimensions';
import Seo from 'helpers/Seo';
import { emailValidate } from 'helpers/validate';
import { forgotPasswordSendCode, forgotPasswordValidateCode } from 'services/authService';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ErrorContainer from 'components/ErrorContainer';
import CodeModal from './components/CodeModal';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  Title,
  SubText,
  CircleContainer,
  BackgroundLine,
  Form,
  BackButtonArea,
  BackButton
} from './style';

const ForgotPassword = () => {
  const { t, language } = React.useContext(LanguageContext);
  const codeModalRef = React.useRef(null);
  const [searchParams] = useSearchParams();
  const [email, setEmail] = React.useState({ value: '', error: '' });
  const [process, setProcess] = React.useState({ loading: '', error: '' });
  const nav = useNavigate();
  const dims = useDimensions();

  React.useEffect(() => {
    if (searchParams.get('email')) {
      setEmail({ ...email, value: searchParams.get('email') });
    }
    window.history.replaceState({}, '', window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    nav(PATHS.LOGIN);
  };

  const handleSendCode = async () => {
    setProcess({ loading: true, error: '' });
    const emailErr = emailValidate(email.value);
    if (emailErr) {
      setProcess({ loading: false, error: '' });
      setEmail({ ...email, error: emailErr && t(`validation_errors.${emailErr}`) });
      return;
    }

    try {
      await forgotPasswordSendCode({ email: email.value, lang: language.code });
      codeModalRef.current.openModal();
      setProcess({ loading: false, error: '' });
    } catch (error) {
      console.log('aa', error);
      setProcess({ loading: false, error: error });
    }
  };

  const resendCode = () => {
    return forgotPasswordSendCode({ email: email.value, lang: language.code });
  };

  const handleCodeSuccess = async (code) => {
    try {
      await forgotPasswordValidateCode({
        email: email.value,
        code: code,
        lang: language.code
      });
      nav(PATHS.CHANGE_PASSWORD.split(':')[0] + `${code}?email=${email.value}`);
      return true;
    } catch (error) {
      console.log('validate error', error);
      throw error;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendCode();
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, x: window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: window.innerWidth }}
    >
      <Seo
        title={t('pages.forgot_password.seo_title')}
      />
      <Modal
        ref={codeModalRef}
        size={'lg'}
        title={dims.isMobile ? 'congratulations' : ''}
        isCloseOutsideClick={false}
        mobileHeightRatio={0}
      >
        <CodeModal
          closeModal={() => codeModalRef.current?.closeModal()}
          email={email.value}
          handleCodeSuccess={handleCodeSuccess}
          resendCode={resendCode}
        />
      </Modal>
      
      <LeftContainer>
        <BackButtonArea onClick={handleBack} >
          <BackButton>
            <BackIcon />
          </BackButton>
          {t('pages.forgot_password.welcome_back')}
        </BackButtonArea>

        <CircleContainer>
          <BackgroundLine $size={'100px'} />
          <BackgroundLine $size={'220px'} />
          <BackgroundLine $size={'320px'} />
          <BackgroundLine $size={'420px'} />
          <BackgroundLine $size={'520px'} />
          <BackgroundLine $size={'620px'} />
          <BackgroundLine $size={'720px'} />
          <BackgroundLine $size={'820px'} />
          <BackgroundLine $size={'920px'} />
        </CircleContainer>
        <Form>
          <Title>{t('pages.forgot_password.title')}</Title>
          <SubText>{t('pages.forgot_password.sub_message')}</SubText>

          <TextInput
            type={'email'}
            name={'email'}
            label={t('labels.email')}
            placeholder={t('labels.email_placeholder')}
            value={email.value}
            onChange={e => setEmail({ value: e.target.value, error: '' })}
            error={email.error}
            fullWidth
            autocomplete={'email'}
            // labelStyle={{ color: '#B0B7C3' }}
            containerStyle={{ marginBottom: '32px' }}
            onKeyPress={handleKeyPress}
          />

          <ErrorContainer
            message={process.error}
            sx={{ marginTop: '32px' }}
          />
          <Button
            title={t('pages.forgot_password.send_code_button')}
            onClick={handleSendCode}
            size={'md'}
            loading={process.loading}
            containerStyle={dims.isMobile ? { width: '100%' } : { paddingInline: '63px' }}
          />

        </Form>
      </LeftContainer>
      {!dims.isMobile &&
        <RightContainer />
      }
    </Wrapper>
  )
};

export default ForgotPassword;
