import React from 'react';
import { UserContext } from 'context/UserContext';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import ErrorContainer from 'components/ErrorContainer';
import { phoneValidate } from 'helpers/validate';
import { LanguageContext } from 'context/withLanguages';
import {
  Container
} from '../style';

const ChangePhone = ({
  value,
  handleTextChange,
  openConfirmModal,
}) => {
  const { changePhoneSendCode } = React.useContext(UserContext);
  const { t } = React.useContext(LanguageContext);
  const [user, setUser] = React.useState({ gsm: { value: '', error: false } });
  const [stat, setStat] = React.useState({ loading: false, error: null });

  const changeText = (text, type) => {
    setUser({ ...user, [type]: { value: text, error: false } });
  };

  const handleChangePhone = async () => {
    const phError = phoneValidate(user.gsm.value);
    if (phError) {
      setUser({ gsm: { value: user.gsm.value, error: phError && t(`validation_errors.${phError}`) } });
      return;
    }
    setStat({ error: null, loading: true });
    try {
      await changePhoneSendCode(user.gsm.value);
      openConfirmModal({ phone: user.gsm.value });
      setStat({ error: null, loading: false });
    } catch (error) {
      setStat({ error: error, loading: false });
    }
  };

  return (
    <Container>
      <TextInput
        type={'tel'}
        label={'Cep Telefonu'}
        name={'phone'}
        placeholder={'555 555 55 55'}
        value={value}
        disabled={true}
        containerStyle={{ width: '100%' }}
        autoComplete={'tel'}
      />

      <TextInput
        type={'tel'}
        label={'Yeni Cep Telefonu'}
        name={'phone'}
        placeholder={'555 555 55 55'}
        value={user.gsm.value}
        onChange={e => {
          if (e.target.value >= 0) {
            changeText(e.target.value, 'gsm')
          }
        }}
        error={user.gsm.error}
        containerStyle={{ width: '100%' }}
        maxLength={10}
        autoComplete={'tel'}
      />
      {stat.error && <ErrorContainer message={stat.error} />}
      <Button
        size={'md'}
        title={'Kaydet'}
        fullSize
        containerStyle={{ marginBottom: '20px', marginTop: '30px', }}
        onClick={handleChangePhone}
        loading={stat.loading}
      />
    </Container>
  )
};

export default ChangePhone;
