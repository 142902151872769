import React from 'react';
import { ReactComponent as FilterNotFound } from 'assets/img/icons/filter-not-found.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  Container,
  Title,
  SubTitle
} from './style';

const NoEvents = ({ customText }) => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Container>
      {customText ?
        <Title>{customText}</Title>
        :
        <React.Fragment>
          <FilterNotFound />
          <Title>{t('pages.events.event_not_found')}</Title>
          <SubTitle>{t('pages.events.event_not_found2')}</SubTitle>
        </React.Fragment>
      }
    </Container>
  )
};

export default NoEvents;
