import {
  dashboardInstance
} from 'services/http';
import errorHandler from './errorHandler';

export const getDashboardCounts = async ({ lang }) => {
  try {
    const { data } = await dashboardInstance({ lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
