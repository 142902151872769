import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "context/UserContext";
import qs from 'query-string';

const RestrictedRoute = ({
  redirectPath = '/',
  children,
}) => {
  const { isAuth } = useContext(UserContext);
  const location = useLocation();
  // const path = window.localStorage.getItem('next');
  const next = location.search ? qs.parse(location.search).next : redirectPath;

  if (isAuth) {
    return <Navigate to={next} replace />;
  }

  return children ? children : <Outlet />;
};

export default RestrictedRoute;
