import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  padding-top: 24px;
  padding-right: 12px;
  /* min-height: 95vh; */
  overflow: hidden;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 0;
  }
  ${({ $pd }) => !!$pd && `padding-right: ${$pd + 12}px;`};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  justify-content: space-between;
`;

export const UserName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #3C587F;
  margin-right: 12px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const ProfileButton = styled(Link)`
  background: #FAFBFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  
  svg > g > path {
    fill: #3C587F;
  }

  svg  {
    width: 24px;
    height: 24px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 5px;
    border-radius: 8px;
    svg  {
      width: 18px;
      height: 18px;
    }
  }
`;

export const WelcomeText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const CardsArea = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  /* height: 294px; */
`;

export const Stats = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  overflow: hidden;
  margin-left: 24px;
  display: grid;
  grid-template-rows: 0fr;
  width: 250px;
  grid-gap: 20px;

  ${({ $isNoProduct }) => $isNoProduct && css`
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
  `}

  /* @media only screen and ((max-width: ${({ theme }) => theme.breakpoints.md}px) or (max-width: 1200px)) { */
  @media only screen and (max-width: 1200px) {
    margin-left: 0;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    grid-gap: 10px;
    & > :not(:last-child) {
      /* margin-right: 24px; */
    };
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;

  /* & > :not(:last-child) {
    margin-right: 17px;
  } */
`;

export const CalendarButtom = styled.button`
  outline: none;
  border: none;
  background: #FAFBFC;
  background: #0064A8;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  margin-right: 11px;
`;
