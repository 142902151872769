import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px 12px;
`;

export const Card = styled.div`
  
`;

export const SubTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #B0B7C3;
  margin-bottom: ${({ $noMargin }) => $noMargin ? '0' : '12px'};
`;

export const Text = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #203662;
`;

export const SpeakerContainer = styled.div`
  &>:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const SpeakerCard = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $sx }) => $sx}
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 4px;
`;

export const Section = styled.div`
  margin-bottom: 12px;
  ${({ $sx }) => $sx}
`;

export const DetailBtn = styled(Link)`
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #0064A8;
  cursor: pointer;
`;
