import React from 'react';
import ContentCard from '../ContentCard';
import {
  Wrapper
} from './style';

const ContentList = ({
  contents
}) => {
  return (
    <Wrapper>
      {contents && contents.map((content, idx) => (
        <ContentCard
          key={`content-${idx}`}
          id={content?.id}
          title={content?.title}
          image={content?.image}
          description={content?.description}
          is_active={content?.is_active}
        />
      ))}
    </Wrapper>
  )
};

export default ContentList;
