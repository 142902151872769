import React from 'react';
import { createPortal } from 'react-dom';
import {
  Container,
  Overlay
} from './style';

const el = document.createElement('div');
el.id = 'portal';
const Onboarding = () => {

  React.useEffect(() => {
    document.body.appendChild(el);
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.getElementById('portal') && document.body.removeChild(el);
      document.documentElement.style.overflow = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);

  React.useEffect(() => {
    document.addEventListener(el, (e) => {
      console.log(e);
    })
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = null;
    };
  }, []);

  const setChildActiveStyle = (el, pos = 'relative') => {
    pos && (el.style.position = pos);
    el.style.zIndex = 102;
  };

  const setChildDeactiveStyle = (el, pos = 'relative') => {
    pos && (el.style.position = pos);
    el.style.zIndex = 1;
  };

  const handleClicked = () => {
    setChildActiveStyle(document.getElementById('stat_cards'), 'relative');

    setTimeout(() => {
      setChildDeactiveStyle(document.getElementById('stat_cards'), null);
      setChildActiveStyle(document.getElementById('side_part'), null);
    }, 2000);

    setTimeout(() => {
      closeBoarding();
    }, 4000);
  }

  const closeBoarding = () => {
    document.getElementById('portal').remove();
    document.documentElement.style.overflow = null;
  };

  return createPortal(
    <React.Fragment>
      <Overlay />
      <Container>
        <p onClick={handleClicked}>close</p>
      </Container>
    </React.Fragment>,
    el
  );
};

export default Onboarding;
