import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import { ContentsContext } from 'context/ContentContext';
import Seo from 'helpers/Seo';
import FSLoading from 'components/FSLoading';
import ContentList from './components/ContentList';
import NoContent from '../Home/components/Events/components/NoEvents';
import {
  Wrapper,
  HeaderContainer,
  Title
} from './style';

const Contents = () => {
  const { t } = React.useContext(LanguageContext);
  const { getContents, states: { contents } } = React.useContext(ContentsContext);

  React.useEffect(() => {
    !contents?.data && getContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Seo
        title={t('pages.contents.title')}
      />
      <HeaderContainer>
        <Title>{t('pages.contents.title')}</Title>
      </HeaderContainer>

      {contents.loading ?
        <FSLoading type='small' />
        :
        (contents.data?.length > 0 ?
          <ContentList
            contents={contents.data}
          />
          :
          <NoContent customText={'Üzgünüz, şu anda hiç içerik yok.'} />
        )
      }

    </Wrapper>
  )
}

export default Contents;

