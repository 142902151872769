import styled, { css, keyframes } from 'styled-components';

const TABLE_RADIUS = '16px';

export const Wrapper = styled.div`
  padding-right: 24px;
  ${({ $containerStyle }) => $containerStyle};
`;

export const Table = styled.table`
  border-collapse: collapse;
  border-radius: ${TABLE_RADIUS};
  /* overflow: hidden; */
  width: 100%;
  ${({ $tableStyle }) => $tableStyle};
  /* height: 500px; */
`;

export const Thead = styled.thead`
  user-select: none;
`;

export const TableTitleRow = styled.tr`

`;

export const WeekDayText = styled.th`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1E1E1E;
  padding-bottom: ${({ $isMini }) => $isMini ? '4px' : '16px'};
  /* ${({ $isMini }) => $isMini && `padding-top: 10px`} */
  width: calc(100% / 7.2);
  ${({ $isMini }) => $isMini && css`
    padding-top: 10px;
    font-weight: 500;
    font-size: 13.7647px;
    width: calc(100% /7);
    line-height: 21px;
    text-align: center;
    color: #B0B7C3;
  `}
`;

export const DayTd = styled.td`
  background-color: transparent;
  /* width: calc(100% / 7); */
  ${({ $isMini }) => $isMini && css`
    width: calc(100% /7);
  `}
  /* height: calc(80vh / 6); */
`;

export const EventDotStack = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  flex-wrap: wrap;
  &>:not(:first-child) {
    margin-left: 4px;
  }
`;

export const EventDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ $color }) => $color || '#DF6675'};
  border-radius: 100%;
`;

export const DayBox = styled.div`
  background-color: #fff;
  display: grid;
  place-items: center;
  width: 130px;
  /* height: 150px; */
  /* height: ${({ $isMini }) => $isMini ? '45px' : '150px'}; */
  /* height: ${({ $isMini }) => $isMini ? '45px' : 'calc(70vh / 6)'}; */
  height: ${({ $isMini, $x }) => $isMini ? '45px' : `calc((100vh - ${$x + 5}px) / 7)`};
  width: 100%; /* TODO :: */
  /* padding: 10px 0; */
  border-width: ${({ $isMini }) => $isMini ? '.5px' : '1px'};
  border-style: solid;
  border-color: ${({ $isMini }) => $isMini ? '#C6CAC6' : '#F1F5FF'};
  cursor: ${({ $passive }) => $passive ? 'default' : 'pointer'};
  background-color: ${({ $selected }) => $selected ? '#0064A8' : '#fff'};
  span {
    ${({ $selected }) => $selected && `
      color: #fff;
      opacity: 1;
    `};
    ${({ $isMini }) => $isMini && `
        font-weight: 400;
        font-size: 13.7647px;
        line-height: 21px;
    `};
  }
  ${({ $dayContainerStyle }) => $dayContainerStyle};
  /* border-radius: 200px; */
`;

export const DayText = styled.span`
  display: grid;
  place-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${({ $passive }) => $passive ? '#B0B7C3' : '#030229'};
  opacity: ${({ $passive }) => $passive ? '0.3' : '0.7'};
  user-select: none;
`;

export const Tbody = styled.tbody`
  &>:first-child {
    &>:first-child {
      &>:first-child {
        border-top-left-radius: ${TABLE_RADIUS};
      }
    }
    &>:last-child {
      &>:first-child {
        border-top-right-radius: ${TABLE_RADIUS};
      }
    }
  }
  &>:last-child {
    &>:first-child {
      &>:first-child {
        border-bottom-left-radius: ${TABLE_RADIUS};
      }
    }
    &>:last-child {
      &>:first-child {
        border-bottom-right-radius: ${TABLE_RADIUS};
      }
    }
  }
  
`;

export const TitleMonthYear = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #203662;
  display: flex;
  align-items: center;
  ${({ $isMini }) => $isMini && css`
    font-size: 18px;
    line-height: 27px;
  `};
`;

export const Navigations = styled.div`
  display: flex;
  align-items: center;
  padding-right: 5%;
  position: relative;
  left: 5px;
`;

export const NavButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  margin-right: ${({ $isLeft }) => $isLeft ? '10px' : '0'};;
  svg {
    transform: ${({ $isLeft }) => $isLeft ? 'rotate(270deg)' : 'rotate(90deg)'};
  }
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $isMini }) => $isMini ? '12px' : '36px'};
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg); 
  }
`;

const dotSpin = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`;

const scaleDot = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  }
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 15px;
  height: 15px;
  animation: ${spin} 2.5s infinite linear both;
  margin: 0 auto;
  margin-left: 10px;
`;

export const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: ${dotSpin} 2.0s infinite ease-in-out both; 
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #203662;
    border-radius: 100%;
    animation: ${scaleDot} 2.0s infinite ease-in-out both; 
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
`;


// export const Tbody = styled.tbody`
//   &>:nth-child(1) {
//     &>:nth-child(1) {
//       &>:first-child {
//         border-top-left-radius: 15px;
//       }
//     }
//     &>:nth-child(7) {
//       &>:first-child {
//         border-top-right-radius: 15px;
//       }
//     }
//   }
// `;
