import React from 'react';
import SideBar from 'components/SideBar';
import { Main, Section } from './style';
import { useDimensions } from 'hooks/useDimensions';

const AppLayout = ({
  children,
}) => {
  const [navWidth, setNavWidth] = React.useState(0);
  const navRef = React.useRef();
  const { isMobile, isTablet } = useDimensions();

  React.useLayoutEffect(() => {
    setNavWidth(isMobile ? navRef.current?.getCurrentHeight() : navRef.current?.getCurrentWidth());
  }, [isMobile, navRef.current?.getCurrentWidth()]);

  React.useEffect(() => {
    document.documentElement.style.backgroundColor = '#F1F5FF';
    window.localStorage.removeItem('next');
  }, [])

  return (
    <Main $responsive={{
      '@media only screen and (max-width: 325px)': {
        overflowX: 'auto'
      }
    }}>
      <SideBar ref={navRef} />
      {/* <Section $customStyle={isMobile ? { marginTop: navWidth + 20 } : { marginLeft: navWidth + 20 }}> */}
      <Section
        $customStyle={isMobile ? { marginTop: navWidth + 20, paddingLeft: '12px' } : { marginLeft: navWidth, paddingLeft: isTablet ? '12px' : '24px' }}
        $responsive={{
          '@media only screen and (min-width: 2468px)': {
            margin: '0 auto',
            maxWidth: '1500px',
          }
        }}
      >
        {children}
      </Section>
    </Main>
  )
};

export default AppLayout;
