import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search-color.svg';
import { ReactComponent as FilterIcon } from 'assets/img/icons/filter.svg';
import { ReactComponent as ClearIcon } from 'assets/img/icons/clear.svg';
import { DATE_FILTER } from 'helpers';
import { AppContext } from 'context/AppContext';
import { EventContext } from 'context/EventContext';
import { LanguageContext } from 'context/withLanguages';
import { useDimensions } from 'hooks/useDimensions';
import { PATHS } from 'routes/paths';
import Modal from 'components/Modal';
import TextInput from 'components/TextInput';
import Dropdown from 'components/Dropdown';
import FSLoading from 'components/FSLoading';
import FilterMenu from './components/FilterMenu';
import EventCard from './components/EventCard';
import NoEvents from './components/NoEvents';
import {
  Wrapper,
  ContentHeader,
  Title,
  SeeAll,
  AllEventsArea,
  EventsContainer,
  SeeAllArea,
  FilterButton,
  FilterContainer,
} from './style';

const Events = ({ selectedTerapatic }) => {
  const [filter, setFilter] = React.useState({
    search: '',
    category: '',
    date: '',
    event_type: ''
  });
  const dims = useDimensions();
  const filterMenuRef = React.useRef(null);
  const { t } = React.useContext(LanguageContext);
  const { appState, getEventTypesData } = React.useContext(AppContext);
  const { states, filterEvents } = React.useContext(EventContext);

  const openFilterMenu = () => {
    filterMenuRef.current.openModal();
  };

  React.useEffect(() => {
    if (!Object.values(filter).every((val) => !val)) {
      filterEvents({
        event_type: filter.event_type?.value,
        title: filter.search,
        category: filter.category?.value,
        date: filter.date?.value,
      })
    }
  }, [filter]);

  React.useEffect(() => {
    handleFilterChange('category', selectedTerapatic);
  }, [selectedTerapatic])

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    });
  };


  return (
    <Wrapper>
      {dims.isMobile &&
        <Modal
          ref={filterMenuRef}
          mobileHeightRatio={0}
          title={'Filter'}
        >
          <FilterMenu
            handleFilterChange={handleFilterChange}
            filter={filter}
            closeMenu={() => filterMenuRef.current?.closeModal()}
          />
        </Modal>
      }
      <ContentHeader>
        <span>
          <Title>{t('pages.home.events')}</Title>
        </span>

        <SeeAllArea>
          <AllEventsArea to={PATHS.EVENTS}>
            <SeeAll>{t('pages.home.see_all_events')}</SeeAll>
          </AllEventsArea>
          {dims.isMobile &&
            <FilterButton onClick={openFilterMenu}>
              <FilterIcon />
            </FilterButton>
          }
        </SeeAllArea>
      </ContentHeader>
      {!dims.isMobile &&
        <FilterContainer>
          <TextInput
            placeholder={t('words.search_event')}
            inputStyle={{ height: '40px', paddingLeft: '40px' }}
            leftIcon={() => <SearchIcon />}
            rightIcon={() => filter.search ? <ClearIcon /> : null}
            rightIconClick={() => handleFilterChange('search', '')}
            value={filter.search}
            iconStyle={{ top: '17px' }}
            containerStyle={{ marginBottom: 0 }}
            onChange={e => handleFilterChange('search', e.target.value)}
          />

          <Dropdown
            placeholder={t('words.terapatik_area')}
            isLoading={appState.categories.loading}
            value={filter.category}
            options={
              appState.categories.data && appState.categories.data.map((category) => ({
                value: category.id,
                label: category.name
              }))
            }
            onChange={e => handleFilterChange('category', e)}
            containerStyle={{ width: '250px', minWidth: '200px', padding: '0 16px', marginBottom: 0 }}
            isClearable
            dropdownStyle={{
              container: {
                height: '42px'
              },
              input: {
                height: 'auto'
              },
              valueContainer: {
                height: '42px'
              },
              indicatorsContainer: {
                paddingLeft: '5px'
              }
            }}
            mobileHeightRatio={0}
          />

          <Dropdown
            placeholder={t('words.event_type')}
            isLoading={appState.eventTypes.loading}
            options={
              getEventTypesData().map((type) => ({
                value: type.id,
                label: type.name
              }))
            }
            onChange={e => handleFilterChange('event_type', e)}
            containerStyle={{ width: '250px', minWidth: '200px', paddingRight: '16px', marginBottom: 0 }}
            isClearable
            dropdownStyle={{
              container: {
                height: '42px'
              },
              input: {
                height: 'auto'
              },
              valueContainer: {
                height: '42px'
              },
              indicatorsContainer: {
                paddingLeft: '5px'
              }
            }}
            mobileHeightRatio={0}
          />

          <Dropdown
            placeholder={t('words.event_time')}
            options={Object.keys(DATE_FILTER).map((date) => ({
              value: date,
              label: t(`words.${DATE_FILTER[date]}`)
            }))}
            onChange={e => handleFilterChange('date', e)}
            containerStyle={{ width: '250px', minWidth: '200px', marginBottom: 0 }}
            isClearable
            dropdownStyle={{
              container: {
                height: '42px'
              },
              input: {
                height: 'auto'
              },
              valueContainer: {
                height: '42px'
              },
              indicatorsContainer: {
                paddingLeft: '5px'
              }
            }}
            mobileHeightRatio={0}
          />
        </FilterContainer>
      }

      {Object.values(filter).every((val) => !val) &&
        (states.events.loading ?
          <FSLoading type='small' />
          :
          (states.events.data?.length > 0 ?
            <EventsContainer>
              {states.events.data.map((event, index) => (
                <EventCard
                  key={index}
                  id={event.id}
                  title={event.title}
                  // logo={event.logo}
                  logo={event.product.logo}
                  banner={event.image}
                  description={event.description}
                  startDate={event.start_date}
                  endDate={event.end_date}
                  eventType={event?.event_type?.name}
                />
              ))}
            </EventsContainer>
            :
            <NoEvents customText={'Üzgünüz, şu anda uygun hiç etkinlik yok.'} />
          )
        )}

      {!Object.values(filter).every((val) => !val) &&
        (states.filteredData.loading ?
          <FSLoading type='small' />
          :
          (states.filteredData.data?.length > 0 ?
            <EventsContainer>
              {states.filteredData.data.map((event, index) => (
                <EventCard
                  key={index}
                  id={event.id}
                  title={event.title}
                  // logo={event.logo}
                  logo={event.product.logo}
                  banner={event.image}
                  description={event.description}
                  startDate={event.start_date}
                  endDate={event.end_date}
                  eventType={event?.event_type?.name}
                />
              ))}
            </EventsContainer>
            :
            <NoEvents />
          )
        )}
    </Wrapper>
  )
};

export default Events;
