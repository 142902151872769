import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import { LanguageContext } from 'context/withLanguages';
import Button from 'components/Button';
import { useDimensions } from 'hooks/useDimensions';
import {
  Container,
  Title,
  Text
} from '../style';

const Approved = ({
  email
}) => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);
  const nav = useNavigate();

  const handleContinue = () => {
    nav(PATHS.LOGIN + `?email=${email}`);
  };

  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{ __html: t('pages.register.successful.approved_title') }}
      />
      <Text>{t('pages.register.successful.approved_subdescription')}</Text>
      <Button
        title={t('pages.register.successful.continue_platform')}
        size={'md'}
        onClick={handleContinue}
        containerStyle={dims.isMobile ? { padding: '0 52px', marginTop: '32px', width: '100%' } : { padding: '0 52px', marginTop: '33px' }}
      />
    </Container>
  )
};

export default Approved;
