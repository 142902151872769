import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search-color.svg';
import { ReactComponent as ClearIcon } from 'assets/img/icons/clear.svg';
import { AppContext } from 'context/AppContext';
import { LanguageContext } from 'context/withLanguages';
import TextInput from 'components/TextInput';
import Dropdown from 'components/Dropdown';
import { DATE_FILTER } from 'helpers';
import {
  Container
} from './style';

const FilterArea = ({
  filter,
  handleFilterChange,
  dateMenu
}) => {
  const { t } = React.useContext(LanguageContext);
  const { appState, getCategories, getEventTypesData } = React.useContext(AppContext);

  React.useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <TextInput
        placeholder={t('words.search_event')}
        inputStyle={{ height: '40px', paddingLeft: '40px' }}
        leftIcon={() => <SearchIcon />}
        rightIcon={() => filter.search ? <ClearIcon /> : null}
        rightIconClick={() => handleFilterChange('search', '')}
        value={filter.search}
        iconStyle={{ top: '17px' }}
        containerStyle={{ marginBottom: 0 }}
        onChange={e => handleFilterChange('search', e.target.value)}
      />

      <Dropdown
        placeholder={t('words.terapatik_area')}
        isLoading={appState.categories.loading}
        options={
          appState.categories.data && appState.categories.data.map((category) => ({
            value: category.id,
            label: category.name
          }))
        }
        onChange={e => handleFilterChange('category', e)}
        containerStyle={{ width: '250px', minWidth: '200px', padding: '0 16px', marginBottom: 0 }}
        isClearable
        dropdownStyle={{
          container: {
            height: '42px'
          },
          input: {
            height: 'auto'
          },
          valueContainer: {
            height: '42px'
          },
          indicatorsContainer: {
            paddingLeft: '5px'
          }
        }}
        mobileHeightRatio={0}
      />

      <Dropdown
        placeholder={t('words.event_type')}
        isLoading={appState.eventTypes.loading}
        options={
          getEventTypesData().map((type) => ({
            value: type.id,
            label: type.name
          }))
        }
        onChange={e => handleFilterChange('event_type', e)}
        containerStyle={{ width: '250px', minWidth: '200px', paddingRight: '16px', marginBottom: 0 }}
        isClearable
        dropdownStyle={{
          container: {
            height: '42px'
          },
          input: {
            height: 'auto'
          },
          valueContainer: {
            height: '42px'
          },
          indicatorsContainer: {
            paddingLeft: '5px'
          }
        }}
        mobileHeightRatio={0}
      />

      <Dropdown
        placeholder={t('words.event_time')}
        options={Object.keys(DATE_FILTER).map((date) => ({
          value: date,
          label: t(`words.${DATE_FILTER[date]}`)
        }))}
        onChange={e => handleFilterChange('date', e)}
        containerStyle={{ width: '250px', minWidth: '200px', marginBottom: 0 }}
        isClearable
        dropdownStyle={{
          container: {
            height: '42px'
          },
          input: {
            height: 'auto'
          },
          valueContainer: {
            height: '42px'
          },
          indicatorsContainer: {
            paddingLeft: '5px'
          }
        }}
        mobileHeightRatio={0}
      />
    </Container>
  )
};

export default FilterArea;
