
const checkTcNo = (value) => {
  value = String(value);
  if (!(/^[1-9]\d{10}$/).test(value)) return false;
  const digits = value.split('');
  const d10 = Number(digits[9]);
  const d11 = Number(digits[10]);
  let sumOf10 = 0;
  let evens = 0;
  let odds = 0;

  digits.forEach((d, index) => {
    d = Number(d);
    if (index < 10) sumOf10 += d;
    if (index < 9) {
      if ((index + 1) % 2 === 0) {
        evens += d;
      } else {
        odds += d;
      }
    }
  });

  if (sumOf10 % 10 !== d11) return false;
  if (((odds * 7) + (evens * 9)) % 10 !== d10) return false;
  if ((odds * 8) % 10 !== d11) return false;
  return true;
};

export const emailValidate = (email) => {
  if (!email) {
    // return 'Email is required';
    return 'required';
  }
  // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (!re.test(String(email).toLowerCase())) {
    // return 'Email is invalid';
    return 'email';
  }
  return null;
};

export const phoneValidate = (phone) => {
  if (!phone) {
    return 'required';
    // return 'Phone is required';
  }
  const re = /^5[0-9][0-9][1-9]([0-9]){2}([0-9]){4}$/;
  if (!re.test(phone)) {
    return 'phone';
  }
  return null;
};

export const tcIdNumberValidate = (idNumber) => {
  if (!idNumber) {
    // return 'ID Number is required';
    return 'required';
  }
  if (!checkTcNo(idNumber) || idNumber === '11111111110') {
    // return 'ID Number is invalid';
    return 'id';
  }
  return null;
};

export const inputValidate = (input, length, name) => {
  if (!input) {
    // return 'ID Number is required';
    return 'required';
  }
  if (input.length < length) {
    // return `${name} must be at least ${length} characters`;
    return `min`;
  }
  return null;
};

export const dropdownValidate = (input, name) => {
  if (!input) {
    return 'required';
    // return `${name} is required`;
  }
  return null;
};

export const passwordValidate = (password, min = 6) => {
  // const re = new RegExp(`^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[a-zA-Z0-9]{${min},}$`);
  const re = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-\?]?)(?=.{${min},})`);
  if (password?.toString?.().length < min) {
    return 'password_min';
    // return `Password must be at least ${min} characters`;
  }
  if (!re.test(password)) {
    return 'password_constrait';
    // return 'Password must contain at least one upper case, lower case and one digit';
  };
  return null;
};

export const confirmPasswordValidate = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return 'password_confirm';
  }
  return null;
};

export const validatePassword = (p) => {
  const errors = {
    LENGTH: false,
    LOWER_CASE: false,
    UPPER_CASE: false,
    DIGIT: false,
  };

  errors.LENGTH = p?.toString?.().length >= 6;

  const lower = new RegExp("[a-z]");
  errors.LOWER_CASE = lower.test(p);

  const upper = new RegExp("[A-Z]");
  errors.UPPER_CASE = upper.test(p);

  const digit = new RegExp("[0-9]");
  errors.DIGIT = digit.test(p);

  return errors;
};
