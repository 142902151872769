import React from 'react';
import { slugify, timeFormatter } from 'helpers';
import { LanguageContext } from 'context/withLanguages';
import Button from 'components/Button';
import { PATHS } from 'routes/paths';
import {
  Wrapper,
  Card,
  SubTitle,
  Text,
  SpeakerContainer,
  SpeakerCard,
  Avatar,
  FlexBox,
  Section,
  DetailBtn
} from './style';

const SessionCard = ({
  id,
  title,
  moderator,
  speakers,
  startDate,
  endDate,
}) => {
  const { t } = React.useContext(LanguageContext)

  const generateUrl = () => {
    const titleSlug = slugify(title);
    return `${PATHS.EVENTS}/${titleSlug + '-' + id}`;
  };

  return (
    <Wrapper>
      <Card>
        <Section>
          <FlexBox $sx={{ marginBottom: '12px' }}>
            <SubTitle $noMargin>{t('pages.calendar.session_name')}</SubTitle>
            <DetailBtn to={generateUrl()} >{t('words.detail')}</DetailBtn>
          </FlexBox>
          <Text>{title}</Text>
        </Section>

        <Section>
          <SubTitle>{t('words.time')}</SubTitle>
          <Text>{timeFormatter(startDate, endDate)}</Text>
        </Section>

        <Section>
          <SubTitle>{t('pages.calendar.mod_session_chair')}</SubTitle>
          <SpeakerContainer>
            <SpeakerCard>
              <Avatar src={moderator?.avatar} alt={moderator?.name} />
              <Text>{moderator?.name}</Text>
            </SpeakerCard>
          </SpeakerContainer>
        </Section>

        <Section>
          <SubTitle>{t('words.speakers')}</SubTitle>
          <SpeakerContainer>
            {speakers?.map((speaker, idx) => (
              <SpeakerCard key={`sp_${idx}`}>
                <Avatar src={speaker?.avatar} alt={speaker?.name} />
                <Text>{speaker?.name}</Text>
              </SpeakerCard>
            ))}
          </SpeakerContainer>
        </Section>

        <Section $sx={{ marginBottom: '12px', marginTop: '16px' }}>
          <FlexBox>
            <Button
              title={t('buttons.add_reminder')}
              onClick={() => { }}
              fullSize
              outlined
              containerStyle={{ marginRight: '8px' }}
            />
            <Button
              title={t('words.join')}
              onClick={() => { }}
              fullSize
            />
          </FlexBox>
        </Section>
      </Card>
    </Wrapper>
  )
};

export default SessionCard;
