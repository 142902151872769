import { appInstance } from 'services/http';
import errorHandler from './errorHandler';

export const getAllCities = async ({ lang }) => {
  try {
    const { data } = await appInstance({ path: 'city', lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getAllBranches = async ({ lang }) => {
  try {
    const { data } = await appInstance({ path: 'branch', lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getAllTitles = async ({ lang }) => {
  try {
    const { data } = await appInstance({ path: 'title', lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getAllProducts = async ({ lang }) => {
  try {
    const { data } = await appInstance({ path: 'product', lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getAllCategories = async ({ lang }) => {
  try {
    const { data } = await appInstance({ path: 'category', lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
