import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  max-width: 400px;
  ${({ $containerStyle }) => $containerStyle}
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: #B0B7C3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #0064A8;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StepStyle = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 6px solid ${({ step }) => step === 'completed' ? '#0064A8' : '#B0B7C3'};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${({ step }) => step === 'completed' ? '#0064A8' : '#B0B7C3'};;
`;
