import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow.svg';

export const Container = styled.div`
  padding: 16px 32px;
  /* padding-bottom: 16px; */
  &>:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const SectionContainer = styled.div`
  background: #FAFBFC;
  border: 1px solid #0064A8;
  border-radius: 24px;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    /* display: flex; */
    /* align-items: center; */
    letter-spacing: 0.005em;
    color: #0064A8;
  }
`;

export const Arrow = styled(ArrowIcon)`
  transform: rotate(${({ $isActive }) => $isActive ? '0deg' : '180deg'});
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
`;

export const OptionContainer = styled.li`
  display: flex;
  align-items: center;
  border-color: ${({ $selected }) => $selected ? '#0064A8' : '#54565B'};
  color: ${({ $selected }) => $selected ? '#0064A8' : '#54565B'};
  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

export const OptionCircle = styled.div`
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 2px solid;
  margin-right: 8px;
  ::before {
    content: '';
    display: ${({ $selected }) => $selected ? 'block' : 'none'};
    width: 10px;
    height: 10px;
    background: #0064A8;
    border-radius: 100%;
    margin: 2px;
  }
`;

export const OptionText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: inherit;
`;

export const OptionWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 0;
  /* padding-bottom: 0; */
`;
