import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LanguageContext } from 'context/withLanguages';
import { PATHS } from 'routes/paths';
import Seo from 'helpers/Seo';
import {
  eventFormatDateStartEnd,
  EVENT_JOIN_STATUS,
  getEventParticipantTypeStyle,
  formatJoinDescription,
  slugify,
} from 'helpers';
import { getSingleEvent, /* joinEvent, */ changeReminder, /* leaveEventRequest */ } from 'services/eventService';
import { ReactComponent as BackIcon } from 'assets/img/icons/back.svg';
import { ReactComponent as EventModal } from 'assets/img/icons/event_modal.svg';
import { ReactComponent as BellIcon } from 'assets/img/icons/bell.svg';
import Button from 'components/Button';
// import DropdownMenu from 'components/DropdownMenu';
// import CalendarMenu from './components/CalendarMenu';
import Tabs from 'components/Tabs';
import EventCalendarTab from './components/EventCalendarTab';
import SpeakersTab from './components/SpeakersTab';
import ActivityDocumentsTab from './components/ActivityDocumentsTab';
import Modal from 'components/Modal';
import CheckBox from 'components/CheckBox';
import FSLoading from 'components/FSLoading';
import Tooltip from 'rc-tooltip';
import {
  Wrapper,
  BackButton,
  Container,
  EventDetails,
  ButtonsContainer,
  EventDescription,
  Banner,
  BrandLogo,
  Title,
  EventDate,
  JoinStream,
  RedDot,
  EventInfo,
  EventHeader,
  TopBanner,
  LoaderContainer,
  ReminderButton,
  UserStatusText,
  ErrorContainer,
  ErrorText
} from './style';
import { EventContext } from 'context/EventContext';

const RemindButton = ({ onClick, status = true, t, disabled }) => {
  return (
    <ReminderButton onClick={onClick} disabled={disabled}>
      {status ? <BellIcon /> : <BellClose />}
      <p>{t(status ? 'buttons.remove_reminder' : 'buttons.add_reminder')}</p>
    </ReminderButton>
  )
};

const TABS = [
  {
    key: 'calendar',
    title: 'event_calendar',
    data: []
  },
  {
    key: 'speakers',
    title: 'speakers',
    data: []
  },
  // {
  //   key: 'documents',
  //   title: 'Activity documents',
  //   data: []
  // },
];

const BellClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
    <path d="M5.164 14H15c-1.5-1-2-5.902-2-7 0-.264-.02-.523-.06-.776L5.164 14zm6.288-10.617A4.988 4.988 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113l9.73-9.73zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z" />
  </svg>
);

// const color = 'linear-gradient(90deg, #22408C 0%, #26727E 39%, #2BA272 80%, #2DB56E 100%)';
const SingleEvent = () => {
  let { slug } = useParams();
  const joinModalRef = React.useRef(null);
  const leaveModalRef = React.useRef(null);
  const reminderModalRef = React.useRef(null);
  // const [event, setEvent] = React.useState(events[0]);
  const [event, setEvent] = React.useState({ loaded: false, data: null, error: null });
  const { t, language } = React.useContext(LanguageContext);
  const { joinEvent, leaveEvent } = React.useContext(EventContext);
  const [selectedTab, setSelectedTab] = React.useState(TABS[0]);
  const [isRemind, setRemind] = React.useState(false);
  const [joinStatus, setJoinStatus] = React.useState(EVENT_JOIN_STATUS.JOIN);
  const [hasJoinAction, setJoinAction] = React.useState(false);
  const nav = useNavigate();

  const parseSlugToId = (text) => {
    const textArr = text.split('-');
    const id = textArr[textArr.length - 1];
    return id;
  };

  const getEvent = async () => {
    const eventId = parseSlugToId(slug);
    try {
      const data = await getSingleEvent({ lang: language.code, id: eventId });
      setEvent({ ...event, loaded: true, data });
      setRemind(data.event_participant?.is_reminder);

      switch (data.event_participant?.is_approved) {
        case EVENT_JOIN_STATUS.APPROVE.key:
          setJoinStatus(EVENT_JOIN_STATUS.APPROVE);
          break;
        case EVENT_JOIN_STATUS.PENDING.key:
          setJoinStatus(EVENT_JOIN_STATUS.PENDING);
          break;
        case EVENT_JOIN_STATUS.REJECT.key:
          setJoinStatus(EVENT_JOIN_STATUS.REJECT);
          break;
        default:
          setJoinStatus(EVENT_JOIN_STATUS.JOIN);
      }

      const slugText = slugify(`${data?.title}-${data?.id}`);
      if (slugText !== window.location.pathname.split('/')[2]) {
        window.history.replaceState(null, null, window.location.search ? slugText + window.location.search : slugText);
      }
      // getJoinedEvent({ lang: language.code, id: data.id });
      setTimeout(() => {
        // console.log('event.data.product.color', event.data.product.color);
        document.documentElement.style.background = data.product.color;
      }, 10);

    } catch (error) {
      setEvent({ ...event, error: 'Etkinlik Bulunamadı.' });
    }

  };

  React.useEffect(() => {
    getEvent();
    return () => {
      document.documentElement.style.background = '#F1F5FF';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    if (global.navigation.canGoBack) {
      window.history.back();
    } else {
      nav(PATHS.EVENTS);
    }
  };

  const handleChangeTab = (tab) => {
    setSelectedTab(tab);
    // changeTab(tab);
  };

  const changeTab = (tab) => {
    switch (tab.key) {
      case 'calendar':
        return <EventCalendarTab events={event.data?.event_speaker} />;
      case 'speakers':
        return <SpeakersTab sections={event.data?.event_speaker} />;
      case 'documents':
        return <ActivityDocumentsTab />;
      default:
        return <EventCalendarTab events={event.data?.event_speaker} />;
    }
  };

  const handleJoinEvent = () => {
    if (joinStatus.key === EVENT_JOIN_STATUS.JOIN.key) {
      joinModalRef.current.openModal();
    }

    if (joinStatus.key === EVENT_JOIN_STATUS.PENDING.key || joinStatus.key === EVENT_JOIN_STATUS.APPROVE.key) {
      leaveModalRef.current.openModal();
    }
  };

  const handleWatchEvent = () => {
    nav(PATHS.STAGE_AREA.split(':')[0] + `${slug}`);
  };

  const pickTextColor = (bgColor) => {
    if (bgColor.charAt(0) !== '#') {
      if (bgColor.includes('linear-gradient')) {
        bgColor = bgColor.split(',')[1].trim().split(' ')[0];
      }
    }
    const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#203662' : '#ffffff';
  };

  const handleParticipantEvent = async (isRemind) => {
    setJoinAction(true);
    try {
      await joinEvent({ id: event.data.id, is_reminder: isRemind })
      await getEvent();
    } catch (error) {
      console.log('Single Event :: join event :: ', error);
    }
    setJoinAction(false);
  };

  const changeReminderStatus = async () => {
    try {
      const data = await changeReminder({ lang: language.code, id: event.data.id });
      setRemind(data?.is_reminder);
    } catch (error) {
      console.log('Single Event :: change reminder :: ', error);
    }
  };

  const handleLeaveEvent = async () => {
    setJoinAction(true);
    try {
      // await leaveEventRequest({ id: event.data.id, lang: language.code });
      await leaveEvent({ id: event.data.id });
      await getEvent();
    } catch (error) {
      console.log('Single Event :: leave event :: ', error);
    }
    setJoinAction(false);
  };

  if (event.error) {
    return <ErrorContainer>
      <ErrorText>{event.error}</ErrorText>
    </ErrorContainer>;
  }

  if (!event.loaded) {
    return <LoaderContainer>
      <FSLoading type='small' />
    </LoaderContainer>;
  }

  return (
    <Wrapper>
      <Seo title={`${event.data.title}`} />
      <Modal
        ref={joinModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.title'),
          icon: <EventModal />,
          description: formatJoinDescription({ startDate: event.data.start_date, endDate: event.data.end_date, title: event.data.title, language, translatedText: t('modal.event.join_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: (isRemind) => handleParticipantEvent(isRemind),
          cancelText: t('modal.event.cancel'),
        }}
        mobileHeightRatio={0}
      />

      <Modal
        ref={leaveModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.leave_title'),
          icon: <EventModal />,
          description: formatJoinDescription({ startDate: event.data.start_date, endDate: event.data.end_date, title: event.data.title, language, translatedText: t('modal.event.leave_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: () => handleLeaveEvent(),
          cancelText: t('modal.event.cancel'),
          isLeave: true,
        }}
        mobileHeightRatio={0}
      />

      <Modal
        ref={reminderModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: isRemind ? 'Hatırlatıcı Kaldır' : 'Hatırlatıcı Ekle',
          icon: <EventModal />,
          // description: formatJoinDescription({ startDate: event.data.start_date, endDate: event.data.end_date, title: event.data.title, language, translatedText: t('modal.event.leave_description') }),
          description: formatJoinDescription({ startDate: event.data.start_date, endDate: event.data.end_date, title: event.data.title, language, translatedText: t(isRemind ? 'modal.event.reminder_description_remove' : 'modal.event.reminder_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: () => changeReminderStatus(),
          cancelText: t('modal.event.cancel'),
          isLeave: true,
        }}
        mobileHeightRatio={0}
      />

      <BackButton
        onClick={handleBack}
        $color={pickTextColor(event.data?.product?.color || '#F1F5FF')}>
        <BackIcon />
        {t('words.back')}
      </BackButton>


      <EventHeader>
        <TopBanner src={event.data.banner?.[0]?.image} $isSingular={event.data?.banner?.length === 1} $left />
        {event.data?.banner?.length > 1 && <TopBanner src={event.data.banner?.[1]?.image} $right />}
      </EventHeader>
      <Container>
        <EventDetails>
          {/* <Banner src={event.data.banner} /> */}
          <Banner src={event.data.image} />

          {/* <BrandLogo src={event.data.logo} /> */}
          <BrandLogo src={event.data.product.logo} />
          <Title>{event.data.title}</Title>
          <EventDate>{eventFormatDateStartEnd(event.data.start_date, event.data.end_date, language)}</EventDate>

          <ButtonsContainer>
            {(!event.data.is_watchable && event.data.is_past) &&
              <UserStatusText $color={'#D93748'}>Bu etkinlik sona ermiştir.</UserStatusText>
            }


            {(joinStatus.key === EVENT_JOIN_STATUS.APPROVE.key && event.data.is_watchable && !event.data.is_past) &&
              <Button
                title={'Etkinliği İzle'}
                onClick={handleWatchEvent}
                // disabled={joinStatus.key === EVENT_JOIN_STATUS.APPROVE.key}
                loading={hasJoinAction}
                containerStyle={{ backgroundColor: '#D93748', color: '#fff' }}
                hasPulseAnimation={true}
                pulseColor={'#D93748'}
              />
            }


            {(!event.data.is_past && joinStatus.key !== EVENT_JOIN_STATUS.REJECT.key) &&
              <Tooltip
                placement={'top'}
                mouseEnterDelay={1}
                mouseLeaveDelay={0.1}
                overlay={<div>{t(`event_status.${joinStatus.value}`)}</div>}
              >
                <Button
                  title={t(`event_status.${joinStatus.value}`)}
                  onClick={handleJoinEvent}
                  disabled={joinStatus.key === EVENT_JOIN_STATUS.REJECT.key || event.data?.event_participant?.is_watched}
                  loading={hasJoinAction}
                  containerStyle={{ backgroundColor: getEventParticipantTypeStyle(joinStatus.key).color, color: '#fff' }}
                />
              </Tooltip>
            }

            {(joinStatus.key === EVENT_JOIN_STATUS.PENDING.key || joinStatus.key === EVENT_JOIN_STATUS.APPROVE.key) &&
              !event.data.is_past &&
              <RemindButton
                // onClick={changeReminderStatus}
                onClick={() => reminderModalRef.current.openModal()}
                status={isRemind}
                t={t}
                disabled={event.data.is_watchable}
              />
            }

          </ButtonsContainer>
          {event.data?.event_participant?.is_watched ?
            // <p style={{ color: 'green', fontSize: '12px', fontWeight: 'bold', marginBottom: '20px' }}>Bu etkinliğe katıldınız.</p>
            <UserStatusText $color={'green'} $mb={'20px'}>Bu etkinliğe katıldınız.</UserStatusText>
            :
            ((joinStatus.key !== EVENT_JOIN_STATUS.JOIN.key && !event.data?.is_past) &&
              <UserStatusText $color={getEventParticipantTypeStyle(joinStatus.key).color} $mb={'20px'}>{t(`event_status_description.${joinStatus.key}`)}</UserStatusText>)
          }

          <EventDescription>{event.data.description}</EventDescription>
        </EventDetails>

        <EventInfo>
          <Tabs
            tabs={TABS}
            activeTab={selectedTab}
            chanageTab={e => handleChangeTab(e)}
          />
          {changeTab(selectedTab)}
        </EventInfo>

      </Container>
    </Wrapper>
  )
}

export default SingleEvent;
