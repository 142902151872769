import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { EVENT_JOIN_STATUS, getEventParticipantTypeStyle } from 'helpers';

export const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px 12px;
`;

export const Card = styled.div`
  
`;

export const SubTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${({ $color }) => $color || '#B0B7C3'};
  margin-bottom: ${({ $noMargin }) => $noMargin ? '0' : '12px'};
`;

export const Text = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #203662;
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #203662;
  margin-bottom: 12px;
`;

export const SpeakerContainer = styled.div`
  &>:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const SpeakerCard = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ $sx }) => $sx}
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 4px;
`;

export const Section = styled.div`
  margin-bottom: 12px;
  ${({ $sx }) => $sx}
`;

export const DetailBtn = styled(Link)`
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #0064A8;
  cursor: pointer;
`;


const getJoinStatusStyle = (type, is_past) => {
  if (is_past) {
    return getEventParticipantTypeStyle('passive');
  };
  switch (type) {
    case EVENT_JOIN_STATUS.APPROVE.key:
      return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.APPROVE.key);
    case EVENT_JOIN_STATUS.PENDING.key:
      return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.PENDING.key);
    case EVENT_JOIN_STATUS.REJECT.key:
      return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.REJECT.key);
    case 'watch':
      return {
        color: '#D93748',
        opacity: .7
      };
    default:
      return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.JOIN.key);
  }
};


export const JoinButton = styled.button`
  outline: none;
  border: none;
  background: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #0064A8;
  ${({ $type, $is_past }) => getJoinStatusStyle($type, $is_past)};
`;

export const BranchText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.01em;
  color: #203662;
  opacity: .7;
`;
