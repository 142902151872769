import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import Seo from 'helpers/Seo';
import { useDimensions } from 'hooks/useDimensions';
import { ReactComponent as BackIcon } from 'assets/img/icons/arrow.svg';
import { UserContext } from 'context/UserContext';
import { LanguageContext } from 'context/withLanguages';
import { motion, AnimatePresence } from 'framer-motion';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ProgressSteps from 'components/ProgressSteps';
import ErrorContainer from 'components/ErrorContainer';
import PersonalInfo from './components/PersonalInfo';
import JobInfo from './components/JobInfo';
import Password from './components/Password';
import SuccessfulRegister from './components/SuccessfulRegister';
import {
  emailValidate,
  inputValidate,
  phoneValidate,
  tcIdNumberValidate,
  dropdownValidate,
  confirmPasswordValidate,
  passwordValidate
} from 'helpers/validate';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  // Title,
  // SubDescription,
  // SubText,
  CircleContainer,
  BackgroundLine,
  Form,
  BackButtonArea,
  BackButton,
  Content,
} from './style';

const NEW_USER = {
  name: { value: '', error: '' },
  idNumber: { value: '', error: '' },
  email: { value: '', error: '' },
  phone: { value: '', error: '' },

  branch: { value: {}, error: '' }, // select option -> {label, value}
  title: { value: {}, error: '' }, // select option -> {label, value}
  institution: { value: '', error: '' },
  institutionCity: { value: '', error: '' },

  password: { value: '', error: '' },
  passwordConfirm: { value: '', error: '' },

  // consents: {
  //   kvkk: { value: false, error: '', isEditable: !false },
  //   gilead: { value: false, error: '', isEditable: false },
  //   iqva: { value: false, error: '', isEditable: false }
  // },
  consents: []
};

const STEPS = [
  { label: 'personal-info', step: 1 },
  { label: 'job-info', step: 2 },
  { label: 'password', step: 3 },
  { label: 'successful-register', step: 4, type: '' },
];

const Register = ({ isShowApproved }) => {
  const { t } = React.useContext(LanguageContext);
  const { register, checkMailAndIqvaConsent } = React.useContext(UserContext);
  const [user, setUser] = React.useState(NEW_USER);
  const [step, setStep] = React.useState(1);
  const [process, setProcess] = React.useState({ loading: false, error: '' });
  const consentModal = React.useRef(null);
  const [consentDetail, setConsentDetail] = React.useState(null);
  // const [doctorId, setDoctorId] = React.useState(null);
  const progressRef = React.useRef(null);
  const nav = useNavigate();
  const dims = useDimensions();

  // React.useEffect(() => {
  //   if (isShowApproved) {
  //     progressRef.current.setStep(4);
  //     setStep(4);
  //   }
  // }, [isShowApproved])

  const handleBack = () => {
    nav(PATHS.LOGIN);
  };

  const handleChangeText = (key, value) => {
    setUser({ ...user, [key]: { ...user[key], value, error: '' } });
  };

  const handleConsentChange = (key, value) => {
    // setUser(prev => ({
    //   ...prev,
    //   consents: {
    //     ...prev.consents,
    //     [key]: { ...prev.consents[key], is_checked: value, error: false }
    //   }
    // }));
    if (key === '_general_consent' && value) {
      const res = user.consents.map(e => {
        if (e.key === '_general_consent') {
          e.is_checked = true;
        } else if (e.key === '_Phone_Use') {
          e.is_checked = true;
        } else if (e.key === '_Email_Use') {
          e.is_checked = true;
        } else if (e.key === '_SMS_Use') {
          e.is_checked = true;
        }
        return e;
      });
      setUser(prev => ({
        ...prev,
        consents: res
      }));
      return;
    }
    setUser(prev => ({
      ...prev,
      consents: prev.consents.map(e => e.key === key ? { ...e, is_checked: value, error: false } : e)
    }));
  };

  const renderForm = (step) => {
    switch (step) {
      case 1:
        return <PersonalInfo user={user} handleChangeText={handleChangeText} />;
      case 2:
        return <JobInfo user={user} handleChangeText={handleChangeText} />;
      case 3:
        return <Password user={user} handleChangeText={handleChangeText}
          handleConsentChange={handleConsentChange}
          setConsentDetail={setConsentDetail}
          openConsentModal={() => consentModal.current.openModal()}
        />;
      case 4:
        return <SuccessfulRegister email={user.email.value} type={STEPS.find(e => e.label === 'successful-register').type} />;
      default:
        return null;
    }
  };

  const validateInputs = (step) => {
    switch (step) {
      case 1:
        const { name, idNumber, email, phone } = user;

        // TODO :: delete|fix this
        // const removeThis = name.value.split(' ');
        // const nameError = inputValidate(name.value, 3, 'name') || (removeThis[removeThis.length - 1] !== '' && 'Soyisim giriniz');
        const nameError = inputValidate(name.value, 3, 'name') || (name.value.split(' ').length < 2 && 'null_surname');
        const idNumberError = tcIdNumberValidate(idNumber.value);
        const emailError = emailValidate(email.value);
        const phoneError = phoneValidate(phone.value);
        if (nameError || idNumberError || emailError || phoneError) {
          setUser(prev => ({
            ...prev,
            name: { ...name, error: nameError && t(`validation_errors.${nameError}`, { min: 3 }) },
            idNumber: { ...idNumber, error: idNumberError && t(`validation_errors.${idNumberError}`) },
            email: { ...email, error: emailError && t(`validation_errors.${emailError}`) },
            phone: { ...phone, error: phoneError && t(`validation_errors.${phoneError}`) }
          }));
          return false;
        }
        return true;
      case 2:
        const { branch, title, institution, institutionCity } = user;
        const branchError = dropdownValidate(branch.value.value, 'Branch');
        const titleError = dropdownValidate(title.value.value, 'Title');
        const institutionError = inputValidate(institution.value, 3, 'Institution');
        const institutionCityError = inputValidate(institutionCity.value, 3, 'Institution city');
        if (branchError || titleError || institutionError || institutionCityError) {
          setUser(prev => ({
            ...prev,
            branch: { ...branch, error: branchError && t(`validation_errors.${branchError}`) },
            title: { ...title, error: titleError && t(`validation_errors.${titleError}`) },
            institution: { ...institution, error: institutionError && t(`validation_errors.${institutionError}`, { min: 3 }) },
            institutionCity: { ...institutionCity, error: institutionCityError && t(`validation_errors.${institutionCityError}`, { min: 3 }) }
          }));
          return false;
        }
        return true;
      case 3:
        const { password, passwordConfirm, consents } = user;
        const passwordError = passwordValidate(password.value, 6);
        const passwordConfirmError = confirmPasswordValidate(password.value, passwordConfirm.value);

        if (passwordError || passwordConfirmError) {
          setUser(prev => ({
            ...prev,
            password: { ...password, error: passwordError && t(`validation_errors.${passwordError}`, { min: 6 }) },
            passwordConfirm: { ...passwordConfirm, error: passwordConfirmError && t(`validation_errors.${passwordConfirmError}`) }
          }));
          return false;
        }

        // check consent
        const generals = consents.filter((e) => e.key === '_general_consent')[0];
        const subs = consents.filter((e) => ['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(e.key));

        if (generals.is_checked) {
          if (subs.filter((e) => e.is_checked).length === 0) {
            setProcess({ loading: false, error: 'Telefon, Sms veya E-Posta izinlerden en az birini seçmelisiniz.' });
            throw new Error('Telefon, Sms veya E-Posta izinlerden en az birini seçmelisiniz.');
            // return true;
          }
        }

        const consentError = consents.filter(e => !e.is_checked && e.is_required);
        if (consentError.length > 0) {
          setUser(prev => ({
            ...prev,
            consents: prev.consents.map(e => consentError.find(c => c.key === e.key) ? { ...e, error: true } : e)
          }));
          setProcess({ loading: false, error: 'Zorunlu izinleri kabul etmelisiniz.' });
          throw new Error('Zorunlu izinleri kabul etmelisiniz.');
          // return false;
        }
        return true;

      default:
        return null;
    }
  };

  const handleNext = async () => {
    // if (STEPS.length >= step) {
    // setProcess({ ...process, error: null });
    const result = validateInputs(step);

    if (result && STEPS[step - 1].label === 'personal-info') {
      setProcess({ ...process, error: null, loading: true });
      try {
        const data = await checkMailAndIqvaConsent(user.email.value);
        console.log('checkMailAndIqva data :: ', data);
        if (data?.is_system) { // user already registered
          setProcess({ ...process, error: 'Bu email ile önceden kayıt olunmuştur.', loading: false });
          return;
        }

        // const consentData = {};
        // data?.iqva_consent?.forEach?.((item) => {
        //   consentData.push({ [item.key]: item })
        // });

        setUser(prev => ({ ...prev, consents: data?.iqva_consent, is_iqva: data?.is_iqva, is_system: data?.is_system }));

      } catch (error) {
        console.log('checkMailAndIqva error :: ', error);
        setProcess({ ...process, error: error, loading: false });
        return;
      }
    }

    setProcess({ ...process, error: null, loading: false });

    if ('password' === STEPS[step - 1].label && result) {
      console.log(step, STEPS[step - 1]);
      return handleRegister();
    }

    if (result) {
      progressRef.current.setStep(step + 1);
      setStep(prev => prev + 1);
    }
    // }
  };

  const handleRegister = async () => {
    setProcess({ loading: true, error: '' });
    const payload = {
      name: user.name.value,
      idNumber: user.idNumber.value,
      email: user.email.value,
      phone: user.phone.value,
      branch: user.branch.value.value,
      title: user.title.value.value,
      institution: user.institution.value,
      institutionCity: user.institutionCity.value.value,
      password: user.password.value,
      consents: user.consents
      // kvkk: user.consents.kvkk.value,
      // gilead: user.consents.gilead.value,
      // iqva: user.consents.iqva.value
    };

    try {
      const data = await register(payload);
      console.log('register data:', data);
      STEPS.forEach(e => {
        if (e.label === 'successful-register') {
          e.type = data?.status || 'Approved';
        }
      });
      // console.log('register data:', data.status);
      setProcess({ loading: false, error: '' });
      progressRef.current.setStep(step + 1);
      setStep(prev => prev + 1);
    } catch (error) {
      console.log('register error:', error);
      setProcess({ loading: false, error: error });
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, x: window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: window.innerWidth }}
    >
      <Modal
        ref={consentModal}
        mobileHeightRatio={1.09}
        title={''}
        size={'lg'}
      >
        <div
          style={{ padding: '20px' }}
          dangerouslySetInnerHTML={{
            __html: consentDetail
          }}
        />
      </Modal>

      <Seo
        title={t('pages.register.seo_title')}
      />

      <LeftContainer>
        <BackButtonArea onClick={handleBack} >
          <BackButton>
            <BackIcon />
          </BackButton>
          {t('pages.register.back_button')}
        </BackButtonArea>

        <CircleContainer>
          <BackgroundLine $size={'100px'} />
          <BackgroundLine $size={'220px'} />
          <BackgroundLine $size={'320px'} />
          <BackgroundLine $size={'420px'} />
          <BackgroundLine $size={'520px'} />
          <BackgroundLine $size={'620px'} />
          <BackgroundLine $size={'720px'} />
          <BackgroundLine $size={'820px'} />
          <BackgroundLine $size={'920px'} />
        </CircleContainer>

        <Form>
          <ProgressSteps
            ref={progressRef}
            containerStyle={dims.isMobile ? {
              marginBottom: '32px',
              marginTop: '24px',
              width: '100%',
              maxWidth: 'unset',
            } : { marginBottom: '36px' }}
            steps={STEPS}
          />

          <Content>
            <AnimatePresence mode={'wait'}>
              <motion.div
                key={step}
                initial={{ x: 10, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -10, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {renderForm(step)}
              </motion.div>
            </AnimatePresence>
          </Content>

          {process.error &&
            STEPS[step - 1].label !== 'successful-register' &&
            <ErrorContainer
              message={process.error}
              sx={{ marginTop: '0' }}
            />
          }

          {
            STEPS[step - 1].label !== 'successful-register' &&
            <Button
              title={t('pages.register.next')}
              onClick={handleNext}
              loading={process.loading}
              size={'md'}
              containerStyle={dims.isMobile ? { width: '100%' } : { padding: '0 70px' }}
            />
          }
        </Form>

      </LeftContainer>

      {!dims.isMobile &&
        < RightContainer />
      }

    </Wrapper>
  )
};

export default Register;
