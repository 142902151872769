import React, { createContext, useContext, useReducer, /* useEffect, useState */ } from 'react';
import { setLocalStorage, getLocalStorage, removeLocalStorage, getDeviceId } from 'helpers';
import { LanguageContext } from './withLanguages';
import {
  authLogin,
  authLogout,
  resendVerificationCode,
  userRegister,
  verifyUserWithOTP,
  checkMailAndIqva
} from 'services/authService';
import {
  getUser,
  changeEmail,
  changeEmailSave,
  changePhone,
  changePhoneSave,
  changeProfileInfo,
  changePassword,
} from 'services/userService';

export const UserContext = createContext();

const initialState = {
  // user: getLocalStorage('user'),
  // user: null,
  userProfile: {
    value: null,
    loading: true,
    error: null
  },
  tokens: getLocalStorage('tokens'),
  loading: false,
  error: null,
  // isAuth: !!getLocalStorage('tokens'),
  isAuth: false,
};

const type = {
  SET_TOKENS: 'SET_TOKENS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  SET_LOADING: 'SET_LOADING',
  LOGOUT: 'LOGOUT',
  RESET_ERROR: 'RESET_ERROR',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILED: 'GET_USER_FAILED',
  GET_USER_LOADING: 'GET_USER_LOADING',
};

const reducer = (state, action) => {
  switch (action.type) {
    case type.SET_TOKENS:
      return {
        ...state,
        tokens: action.payload.tokens
      };
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        tokens: action.payload.tokens,
        loading: false,
        isAuth: true,
      };
    case type.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        isAuth: false,
      };
    case type.SET_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.LOGOUT:
      return {
        ...state,
        user: null,
        tokens: null,
        isAuth: false,
        loading: false
      };
    case type.RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case type.GET_USER_SUCCESS:
      return {
        ...state,
        userProfile: {
          value: action.payload.userProfile,
          loading: false,
          error: null
        },
        isAuth: true,
      };
    case type.GET_USER_FAILED:
      return {
        ...state,
        userProfile: {
          value: null,
          loading: false,
          error: action.payload.error
        },
        isAuth: false
      };
    case type.GET_USER_LOADING:
      return {
        ...state,
        userProfile: {
          value: null,
          loading: true,
          error: null
        }
      };
    default:
      throw new Error();
  }
};

export const UserProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(reducer, initialState);
  const { language } = useContext(LanguageContext);
  const userChannel = new BroadcastChannel('user');

  React.useEffect(() => {
    const tokens = getLocalStorage('tokens');
    if (tokens) {
      dispatch({
        type: type.SET_TOKENS,
        payload: { tokens }
      });
    }
  }, [])

  React.useEffect(() => {
    userChannel.onmessage = ({ data }) => {
      switch (data.payload.type) {
        case "SIGN_OUT":
          dispatch({ type: type.LOGOUT });
          break;
        case "GET_USER":
          dispatch({
            type: type.GET_USER_SUCCESS,
            payload: {
              userProfile: data.payload.data,
            },
          });
          break;
        default:
          break;
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO :: check if user is verified or not
  const login = async ({ email, password }) => {
    dispatch({ type: type.SET_LOADING });
    try {
      const data = await authLogin({ email, password, lang: language.code });
      setLocalStorage('tokens', { access_token: data.access_token, refresh_token: data.refresh_token });
      dispatch({
        type: type.SET_TOKENS,
        payload: {
          tokens: { access_token: data.access_token, refresh_token: data.refresh_token },
        }
      });
      await getMe();
      if (!data.user.is_verified) {
        resendVerificationCode({ email: data.user.email, lang: language.code });
      }
      return data;
    } catch ({ errorText, error }) {
      if (error?.response?.status === 406) {
        const err = JSON.parse(error.response.data.message);
        dispatch({
          type: type.LOGIN_FAILED,
          payload: {
            error: err?.message
          },
        });
        // eslint-disable-next-line no-throw-literal
        throw { status: 406, message: err?.errors };
      }
      dispatch({
        type: type.LOGIN_FAILED,
        payload: {
          error: errorText,
        },
      });
      throw error;
    }
  };

  const cleanStorage = () => {
    removeLocalStorage('user');
    removeLocalStorage('tokens');
  };

  const logout = async () => {
    try {
      const data = await authLogout(language.code);
      console.log('logout data:', data);
    } catch (error) {
      throw error;
    }

    userChannel.postMessage({
      payload: {
        type: "SIGN_OUT"
      }
    });
    // TODO :: if logout success, then remove local storage but if not, then don't remove local storage??
    dispatch({ type: type.LOGOUT });
    cleanStorage();
    return true;
  };

  const getMe = async (hasLoading = true) => {
    hasLoading && dispatch({ type: type.GET_USER_LOADING });
    try {
      const data = await getUser(language.code);
      dispatch({
        type: type.GET_USER_SUCCESS,
        payload: {
          userProfile: data,
        },
      });

      userChannel.postMessage({
        payload: {
          type: "GET_USER",
          data
        }
      });
      return data;
    } catch (error) {
      dispatch({
        type: type.GET_USER_FAILED,
        payload: { error: error, },
      });
      throw error;
    }
  };

  const getUserProfile = async (hasLoading = true) => {
    hasLoading && dispatch({ type: type.GET_USER_LOADING });
    if (!authState.isAuth && !authState.tokens) {
      cleanStorage();
      dispatch({
        type: type.GET_USER_FAILED,
        payload: { error: null },
      });
      return;
    }
    await getMe(hasLoading);
  };

  const register = async (user) => {
    return userRegister({ user, lang: language.code });
  };

  const verifyUser = async ({ email, code }) => {
    return verifyUserWithOTP({ email, code, lang: language.code });
  };

  const changeMailSendCode = async (email) => {
    const req = {
      email: email,
      device_uuid: getDeviceId(),
    };

    return changeEmail({ payload: req, lang: language.code });
  };

  const changeMailConfirmCode = async ({ email, code }) => {
    const req = {
      email: email,
      device_uuid: getDeviceId(),
      code: code
    };
    return changeEmailSave({ payload: req, lang: language.code });
  };

  const changePhoneSendCode = async (gsm) => {
    const req = {
      gsm: gsm,
      device_uuid: getDeviceId(),
    };

    return changePhone({ payload: req, lang: language.code });
  };

  const changePhoneConfirmCode = async ({ gsm, code }) => {
    const req = {
      gsm: gsm,
      device_uuid: getDeviceId(),
      code: +code
    };
    return changePhoneSave({ payload: req, lang: language.code });
  };

  const updateProfile = async ({ userId, payload }) => {
    try {
      await changeProfileInfo({ userId, payload, lang: language.code });
      await getUserProfile(false);
    } catch (error) {
      throw error;
    }
  };

  const updatePassword = async (payload) => {
    payload.device_uuid = getDeviceId();
    return changePassword({ payload, lang: language.code });
  };

  const checkMailAndIqvaConsent = async (email) => {
    return checkMailAndIqva({ email, lang: language.code });
  };

  // React.useMemo(() => {
  //   console.log('get user profile');

  //   if (authState.user) {
  //     (async () => {
  //       getUserProfile();
  //     })();
  //   }
  // }, [authState.isAuth])

  return (
    <UserContext.Provider value={{
      authState,
      dispatch,
      login,
      logout,
      register,
      verifyUser,
      getUserProfile,
      changeMailSendCode,
      changeMailConfirmCode,
      changePhoneSendCode,
      changePhoneConfirmCode,
      updateProfile,
      updatePassword,
      checkMailAndIqvaConsent,
      // user: authState.user,
      user: authState.userProfile.value,
      userProfile: authState.userProfile.value?.user_profile,
      isAuth: authState.isAuth,
      isMeLoading: authState.userProfile.loading,
    }}>
      {children}
    </UserContext.Provider>
  );
};
