import styled from 'styled-components';

export const Main = styled.main`
  ${({ $customStyle }) => $customStyle || ''}
  ${({ $responsive }) => $responsive || ''}
`;

export const Section = styled.section`
  ${({ $customStyle }) => $customStyle || ''}
  ${({ $responsive }) => $responsive || ''}
`;
