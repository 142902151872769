import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ROUTES } from "routes";
import ProtectedRoute from "routes/ProtectedRoute";
import RestrictedRoute from "routes/RestrictedRoute";
// import { AppContext } from "context/AppContext";
import { UserContext } from "context/UserContext";
import FSLoading from "components/FSLoading";
import { EventContext } from "context/EventContext";

const withLayout = (LayoutComp, RenderComp) => <LayoutComp><RenderComp /></LayoutComp>;

const App = () => {
  // const { initData } = React.useContext(AppContext);
  const { getUserProfile, isMeLoading, isAuth } = React.useContext(UserContext);
  const { getEvents } = React.useContext(EventContext);
  const { pathname } = useLocation();

  React.useEffect(() => {
    (async () => {
      // TODO :: check if user is logged in
      await getUserProfile();
      // getEvents();
    })();
    // getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isAuth) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map(({
      key,
      path,
      isProtected,
      component: Component,
      layout,
      navigatePath,
      isRestricted }, i) => {
      if (path) {
        if (navigatePath) {
          return (
            <Route
              id={key}
              key={key}
              path={path}
              element={<Navigate to={navigatePath} replace />}
            />
          );
        }
        if (isProtected) {
          return (
            <Route key={i} element={<ProtectedRoute />}>
              <Route
                id={key}
                key={key}
                path={path}
                element={layout ? withLayout(layout, Component) : <Component />}
              />
            </Route>
          );
        }
        if (isRestricted) {
          return (
            <Route key={i} element={<RestrictedRoute />}>
              <Route
                id={key}
                key={key}
                path={path}
                element={layout ? withLayout(layout, Component) : <Component />}
              />
            </Route>
          );
        }

        return (
          <Route
            id={key}
            key={key}
            path={path}
            element={layout ? withLayout(layout, Component) : <Component />}
          />
        );
      }
      return null;
    });

  if (isMeLoading) {
    return <FSLoading />;
  };

  return (
    <Routes>
      {getRoutes(ROUTES)}
    </Routes>
  );
};

export default App;
