import styled from "styled-components";

export const Card = styled.div`
  width: 290px;
  height: 104px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  margin-right: 25px;
  padding: 16px 12px;
  scroll-snap-align: start;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 56px;
  height: 80%;
  border-radius: 8px;
  user-select: none;
  pointer-events: none;
`;

export const ContextArea = styled.div`
  margin-left: 8px;
`;

export const TTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #B0B7C3;
`;

export const TName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #203662;
`;

export const TProfession = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #203662;
`;
