import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import Button from 'components/Button';
import { useDimensions } from 'hooks/useDimensions';
import {
  Container,
  Title,
  Text
} from '../style';

const ADMIN_MAIL = 'yusuf.yildirim@kodzillaistanbul.com';
const EMAIL_SUBJECT = 'Gilead Event Platform | Membership Request';
const EMAIL_BODY = 'Hi, I have a question about my membership request';

const Pending = () => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);

  const sendMail = () => {
    const MAILTO = `mailto:${ADMIN_MAIL}?subject=${EMAIL_SUBJECT}&body=${EMAIL_BODY}`;
    window.location.href = MAILTO;
  };

  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{ __html: t('pages.register.successful.pending_title') }}
      />
      <Text $sx={{ marginBottom: '16px' }}>{t('pages.register.successful.pending_subdescription')}</Text>
      {/* <Text>{t('pages.register.successful.pending_contact_admin')}</Text>
      <Button
        title={t('pages.register.successful.contact_mail')}
        size={'md'}
        onClick={sendMail}
        containerStyle={dims.isMobile ? { padding: '0 80px', marginTop: '32px', width: '100%' } : { padding: '0 80px', marginTop: '38px' }}
      /> */}
    </Container>
  )
};

export default Pending;
