import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 70px 32px 70px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* margin-top: 16px; */
    padding: 16px 12px 32px 12px;
  }
`;

export const Timer = styled.div`
  width: 72px;
  height: 72px;
  background: #FFFFFF;
  border: 1.12963px solid #203662;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #203662;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 56px;
    height: 56px;

    font-weight: 400;
    font-size: 18.6667px;
    line-height: 25px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  margin-bottom: 47px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 24px;
  }
`;

export const SubText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #54565B;
  ${({ $mb }) => `margin-bottom: ${$mb}}`};
  padding: 0 30px;
  ${({ $sx }) => $sx};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const CodeContainer = styled.div`
  width: 60%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`;

export const Text = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $sx }) => $sx};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    justify-content: flex-start;
  }
`;
