import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
  margin-top: 24px;
  &>:first-child {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
`;

export const SeeAll = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #0064A8;
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const AllEventsArea = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* margin-right: 12px; */
`;

export const EventsContainer = styled.div`
  display: grid;
    /* margin: 0 auto; */
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  /* margin-top: 20px; */
  padding-bottom: 20px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 768px) and (min-width: 642px)  {
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SeeAllArea = styled.div`
  display: flex;
  align-self: center;
  &>:first-of-type {
    margin-left: 15px;
  }
`;

export const FilterButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: grid;
  place-items: center;
  padding: 8px 20px;
  background: #FAFBFC;
  border-radius: 24px;
`;

export const FilterContainer = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  /* position: relative; */
  &::-webkit-scrollbar {
    display: none;
  };
`;
