import React from 'react';
import propTypes from 'prop-types';
import { LanguageContext } from 'context/withLanguages';
import {
  Nav,
  TabBar,
  TabItem,
  Underline,
} from './style';

const Tabs = ({
  tabs,
  activeTab,
  chanageTab,
}) => {
  const { t } = React.useContext(LanguageContext);
  const [allTabs, setAllTabs] = React.useState([]);

  React.useEffect(() => {
    const formatTabs = tabs.map((e, idx) => {
      return { ...e, title: e.title.replace('{{#}}', e?.data?.length) }
    });
    setAllTabs(formatTabs);
    // console.log('formatTabs', formatTabs);
  }, [tabs]);

  return (
    <Nav>
      <TabBar>
        {allTabs.length > 0 && allTabs.map((item) => (
          <TabItem
            key={item.title}
            $isActive={item.key === activeTab.key}
            onClick={() => chanageTab?.(item)}
          >
            {`${t(`pages.single_event.${item.title}`)}`}
            {item.key === activeTab.key ? (
              <Underline layoutId='underline' />
            ) : null}
          </TabItem>
        ))}
      </TabBar>
    </Nav>
  );
};

Tabs.propTypes = {
  tabs: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
  })).isRequired,
  activeTab: propTypes.shape({
    key: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
  }).isRequired,
  chanageTab: propTypes.func.isRequired,
}

export default Tabs;
