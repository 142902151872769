import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'styleConfig/theme';
import { UserProvider } from 'context/UserContext';
import { LanguageProvider } from 'context/withLanguages';
import { AppControlProvider } from 'context/AppControl';
import { AppProvider } from 'context/AppContext';
import { EventProvider } from 'context/EventContext';
import { ContentsProvider } from 'context/ContentContext';
import GlobalStyle from 'styleConfig/GlobalStyle';
import Seo from 'helpers/Seo';
import { v4 as uuidv4 } from 'uuid';
import { getLocalStorage, setLocalStorage } from 'helpers';

if (process.env.NODE_ENV === 'production' && !getLocalStorage('debug')) {
  console.log(`%cGilead Event Platform\n© ${new Date().getFullYear()}, made with by ♥️ Kodzilla https://kodzillaistanbul.com`, "color:#C50F3C; font-size:20px;font-weight: bold");
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
};

!getLocalStorage('device_uuid') && setLocalStorage('device_uuid', uuidv4());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppControlProvider>
      <LanguageProvider>
        <AppProvider>
          <UserProvider>
            <EventProvider>
              <ContentsProvider>
                <ThemeProvider theme={theme}>
                  <Seo />
                  <GlobalStyle />
                  <App />
                </ThemeProvider>
              </ContentsProvider>
            </EventProvider>
          </UserProvider>
        </AppProvider>
      </LanguageProvider>
    </AppControlProvider>
  </BrowserRouter >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
