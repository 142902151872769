import React from 'react';
import { LanguageContext } from './withLanguages';
import {
  getAllEvents,
  filterEvent,
  joinEvent as JoinEventService,
  leaveEventRequest,
  getSingleEvent as getSingleEventService,
  getCalendarEventDots,
  createQuestion,
  watchEventService,
  eventParticipantService
} from 'services/eventService';

export const EventContext = React.createContext();

const initialState = {
  events: {
    data: null,
    loading: false,
    error: null,
  },
  filteredData: {
    data: [],
    loading: false,
    error: null,
  }
};

const TYPE = {
  FETCH_EVENTS_LOADING: 'FETCH_EVENTS_LOADING',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILED: 'FETCH_EVENTS_FAILED',

  FILTER_EVENTS_LOADING: 'FILTER_EVENTS_LOADING',
  FILTER_EVENTS_SUCCESS: 'FILTER_EVENTS_SUCCESS',
  FILTER_EVENTS_FAILED: 'FILTER_EVENTS_FAILED',
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE.FETCH_EVENTS_LOADING:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
          error: null,
        },
      };
    case TYPE.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.FETCH_EVENTS_FAILED:
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: action.payload.error,
        },
      };

    case TYPE.FILTER_EVENTS_LOADING:
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          loading: true,
          error: null,
        },
      };
    case TYPE.FILTER_EVENTS_SUCCESS:
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.FILTER_EVENTS_FAILED:
      return {
        ...state,
        filteredData: {
          ...state.filteredData,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      throw new Error();
  }
};

export const EventProvider = ({ children }) => {
  const [states, dispatch] = React.useReducer(reducer, initialState);
  const { language } = React.useContext(LanguageContext);

  const getEvents = async () => {
    dispatch({ type: TYPE.FETCH_EVENTS_LOADING });
    try {
      const data = await getAllEvents({ lang: language.code });
      dispatch({ type: TYPE.FETCH_EVENTS_SUCCESS, payload: { data } });
    } catch (error) {
      dispatch({ type: TYPE.FETCH_EVENTS_FAILED, payload: { error } });
    }
  };

  const filterEvents = async (filter) => {
    dispatch({ type: TYPE.FILTER_EVENTS_LOADING });
    try {
      const _filter = Object.fromEntries(Object.entries(filter).filter(([_, v]) => (v != null && v !== '')));
      const data = await filterEvent({ filter: _filter, lang: language.code });
      dispatch({ type: TYPE.FILTER_EVENTS_SUCCESS, payload: { data } });
    } catch (error) {
      dispatch({ type: TYPE.FILTER_EVENTS_FAILED, payload: { error } });
    }
  };

  const joinEvent = async ({ id, is_reminder }) => {
    try {
      const data = await JoinEventService({ id, lang: language.code, is_reminder });
      if (states.events.data) {
        const eventClone = [...states.events.data];
        eventClone.forEach(event => {
          if (event.id === id) {
            event.event_participant = data;
          }
        });
        dispatch({ type: TYPE.FETCH_EVENTS_SUCCESS, payload: { data: eventClone } });
      }

      getAllEvents({ lang: language.code }).then(data => {
        dispatch({ type: TYPE.FETCH_EVENTS_SUCCESS, payload: { data: data } });
      });
    } catch (error) {
      console.log(`EventContext :: jonEvent error: ${error}`);
    }
  };

  const leaveEvent = async ({ id }) => {
    try {
      const data = await leaveEventRequest({ id, lang: language.code });
      if (states.events.data) {
        const eventClone = [...states.events.data];
        eventClone.forEach(event => {
          if (event.id === id) {
            delete event.event_participant;
          }
        });
        dispatch({ type: TYPE.FETCH_EVENTS_SUCCESS, payload: { data: eventClone } });
      }
      getAllEvents({ lang: language.code }).then(data => {
        dispatch({ type: TYPE.FETCH_EVENTS_SUCCESS, payload: { data: data } });
      });
    } catch (error) {
      console.log(`EventContext :: leaveevent error: ${error}`);
    }
  };

  const getSingleEvent = async ({ id }) => {
    try {
      const data = await getSingleEventService({ id, lang: language.code });
      return data;
    } catch (error) {
      console.log(`Get Single Event :: ${error}`);
      throw error;
    }
  };

  const getCalendarEvents = async ({ page = 1, limit = 10, day }) => {
    try {
      const tsDay = new Date(day).getTime() / 1000;
      const filter = {
        // pagination: 1,
        // limit,
        // page,
        today: tsDay
      }
      const data = await filterEvent({ lang: language.code, filter });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getCalendarDots = async ({ today }) => {
    try {
      const data = await getCalendarEventDots({ lang: language.code, today: today });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const createQuestiononEvent = async ({ eventId, question }) => {
    try {
      const data = await createQuestion({ eventId, question, lang: language.code });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const watchEvent = async (id) => {
    try {
      const data = await watchEventService({ lang: language.code, eventId: id });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const sendWatchedStatus = async (id) => {
    try {
      const data = await eventParticipantService({ lang: language.code, eventId: id });
      return data;
    } catch (error) {
      throw error;
    }
  };

  return (
    <EventContext.Provider
      value={{
        states,
        dispatch,
        getEvents,
        filterEvents,
        joinEvent,
        leaveEvent,
        getSingleEvent,
        getCalendarEvents,
        getCalendarDots,
        createQuestiononEvent,
        watchEvent,
        sendWatchedStatus
      }}>
      {children}
    </EventContext.Provider>
  )
};
