import React from 'react';
import { ReactComponent as EyeNoIcon } from 'assets/img/icons/eye-no.svg';
import { ReactComponent as EyeYesIcon } from 'assets/img/icons/eye-yes.svg';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import ErrorContainer from 'components/ErrorContainer';
import { PATHS } from 'routes/paths';
import Seo from 'helpers/Seo';
import { UserContext } from 'context/UserContext';
import { LanguageContext } from 'context/withLanguages';
import { emailValidate, dropdownValidate } from 'helpers/validate';
import { parseQuery } from 'helpers';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  OnBoardingArea,
  OnBoardingTitle,
  OnBoardingSubText,
  ContextCard,
  Title,
  SubText,
  ForgotPassArea,
  ForgotLinkText,
  LinkText,
  CircleContainer,
  BackgroundLine,
  ConsentError,
  ConsentDescription,
  ConsentText
} from './style';
import Modal from 'components/Modal';

const IQVA_LINK = process.env.REACT_APP_IQVA;

const Login = () => {
  const { login, authState, dispatch } = React.useContext(UserContext);
  const { t } = React.useContext(LanguageContext);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  // const [isRememberMe, setRememberMe] = React.useState(false);
  const [consentErrorData, setConsentErrorData] = React.useState(null);
  const [user, setUser] = React.useState({ email: { value: '', error: '' }, password: { value: '', error: '' } });
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    const parsedQuery = parseQuery(window.location.search);
    if (parsedQuery?.email) {
      setUser({ ...user, email: { value: parsedQuery.email, error: '' } });
      window.history.replaceState({}, '', window.location.pathname);
    }
    return () => {
      // got an error from the server, reset it if the user leaves the page
      dispatch({ type: 'RESET_ERROR' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextChange = (e, type) => {
    setUser({ ...user, [type]: { ...user[type], value: e, error: '' } });
  };

  const handleSignIn = async () => {
    const mailError = emailValidate(user.email.value);
    const psError = dropdownValidate(user.password.value, 'Password');
    if (mailError || psError) {
      return setUser({
        ...user,
        email: { ...user.email, error: mailError && t(`validation_errors.${mailError}`) },
        password: { ...user.password, error: psError && t(`validation_errors.${psError}`) }
      });
    }

    try {
      await login({ email: user.email.value, password: user.password.value });
    } catch (error) {
      if (error?.status === 406) {
        setConsentErrorData(error?.message);
        modalRef.current?.openModal();
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, x: window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: window.innerWidth }}
    >
      <Modal
        ref={modalRef}
        mobileHeightRatio={0}
        title={`Gilead Onam ERİŞİM İZİNLERİ`}
        size={'lg'}
      >
        <ConsentError>
          <ConsentDescription>Gilead Onam Sistem'indeki izinleri kaldırdığınız için giriş yapamadınız. Lütfen Gilead Onam Sistemindeki şu izinleri tekrar verin;</ConsentDescription>
          {
            consentErrorData?.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td style={{ paddingRight: '5px' }} >-</td>
                  <td>
                    <ConsentText>{item}</ConsentText>
                  </td>
                </tr>
              </React.Fragment>
            ))
          }
          <Button
            size={'md'}
            title={'Gilead Onam Sistemine Git'}
            containerStyle={{ marginTop: '10px', float: 'right', marginBottom: '20px' }}
            onClick={() => window.open(IQVA_LINK, '_blank')}
          />
        </ConsentError>
      </Modal>
      <Seo
        title={t('pages.login.title')}
      />
      <LeftContainer>
        <CircleContainer>
          <BackgroundLine $size={'100px'} />
          <BackgroundLine $size={'220px'} />
          <BackgroundLine $size={'320px'} />
          <BackgroundLine $size={'420px'} />
          <BackgroundLine $size={'520px'} />
          <BackgroundLine $size={'620px'} />
          <BackgroundLine $size={'720px'} />
          <BackgroundLine $size={'820px'} />
          <BackgroundLine $size={'920px'} />
        </CircleContainer>

        <OnBoardingArea>
          <OnBoardingTitle
            dangerouslySetInnerHTML={{ __html: t('pages.login.onboarding_title') }}
          />
          <OnBoardingSubText>
            {t('pages.login.onboarding_sub_message')}
          </OnBoardingSubText>
        </OnBoardingArea>
      </LeftContainer>

      <RightContainer>
        <ContextCard>
          <Title
            dangerouslySetInnerHTML={{ __html: t('pages.login.card_title') }}
          />
          <SubText>{t('pages.login.card_sub_message')}</SubText>

          <TextInput
            type={'email'}
            name={'email'}
            label={t('labels.email')}
            placeholder={t('labels.email_placeholder')}
            value={user.email.value}
            onChange={e => handleTextChange(e.target.value, 'email')}
            error={user.email.error}
            fullWidth
            autocomplete={'email'}
            labelStyle={{ color: '#B0B7C3' }}
          />

          <TextInput
            label={t('labels.password')}
            placeholder={t('labels.ps_placeholder')}
            value={user.password.value}
            onChange={e => handleTextChange(e.target.value, 'password')}
            fullWidth
            type={isPasswordVisible ? 'text' : 'password'}
            rightIcon={() => !isPasswordVisible ? <EyeNoIcon /> : <EyeYesIcon />}
            rightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            error={user.password.error}
            name={'new-password'}
            autoComplete={'new-password'}
            containerStyle={{ marginTop: '24px' }}
            labelStyle={{ color: '#B0B7C3' }}
            onKeyPress={handleKeyPress}
          />
          {/* <LinkText to={PATHS.FORGOT_PASSWORD}>Forgot password?</LinkText> */}

          <ForgotPassArea>
            <ForgotLinkText
              to={user.email.value ? `${PATHS.FORGOT_PASSWORD}?email=${user.email.value}` : PATHS.FORGOT_PASSWORD}
            >{t('labels.forgot_password')}</ForgotLinkText>
          </ForgotPassArea>

          {/* <CheckBox
            checked={isRememberMe}
            setChecked={e => setRememberMe(e)}
            label={<span style={{ fontSize: '14px' }}>{t('labels.remember_me')}</span>}
          /> */}

          {authState.error && <ErrorContainer message={authState.error} />}

          <Button
            title={t('words.sign_in')}
            onClick={handleSignIn}
            size={'md'}
            loading={authState.loading}
            fullSize
            // containerStyle={{ marginBlock: '32px' }}
            containerStyle={{ marginBottom: '32px' }}
          />

          <LinkText $align={'flex-start'} as={'p'}>{t('pages.login.have_account')}&nbsp;
            <LinkText to={PATHS.REGISTER} $fw={700} $color={'#0064A8'}>{t('pages.login.register')}</LinkText>
          </LinkText>
        </ContextCard>
      </RightContainer>
    </Wrapper >
  )
};

export default Login;
