import React from 'react';
import CustomCalendar from 'components/Calendar';
import SidePart from './components/SidePart';
import Seo from 'helpers/Seo';
import { LanguageContext } from 'context/withLanguages';
import { events } from 'helpers/mocks';
import {
  Wrapper,
  Title
} from './style';

const Calendar = () => {
  const { t } = React.useContext(LanguageContext);
  const sideRef = React.useRef(null);
  const calendarRef = React.useRef(null);
  const [sideW, setSideW] = React.useState(null);
  const [currentDate, onChangeDate] = React.useState(null);

  React.useEffect(() => {
    setSideW(sideRef.current?.offsetWidth)
  }, [sideRef.current?.offsetWidth])
  return (
    <Wrapper $pd={sideW}>
      <Seo
        title={t('pages.calendar.calendar')}
      />
      <Title>{t('pages.calendar.calendar')}</Title>
      <CustomCalendar
        ref={calendarRef}
        changeDate={onChangeDate}
        events={events}
      />

      <SidePart
        ref={sideRef}
        currentDate={currentDate}
        nextDay={() => calendarRef.current?.nextDay()}
        prevDay={() => calendarRef.current?.prevDay()}
        events={events}
      />

    </Wrapper>
  )
};

export default Calendar;
