import styled, { css, keyframes } from 'styled-components';

const pulse = (pulseColor= '#0064A8') => keyframes`
  0% {
    transform: scale(0.98);
    /* box-shadow: 0 0 0 0 rgba(197, 15, 60); */
    box-shadow: 0 0 0 0 ${pulseColor};
    opacity: .7;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(197, 15, 60, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(197, 15, 60, 0);
    opacity: 1;
  }
`;

const SIZES = {
  sm: { height: '32px', fontSize: '12px' },
  md: { height: '48px', fontSize: '14px' },
  lg: { height: '48px', fontSize: '14px' },
}

export const ButtonUI = styled.button`
  outline: none;
  border: none;
  width: ${({ $fullSize }) => $fullSize ? '100%' : 'fit-content'};
  color: #fff;
  background-color: #0064A8;
  /* padding: 12px 100px; */
  padding: 7.25px 18px;
  transition: background-color 0.2s ease, color 0.2s ease;
  border-radius: 16px;
  user-select: none;
  font-size: ${({ $size }) => SIZES[$size].fontSize || '15px'};
  font-family: 'Poppins';
  min-width: 80px;
  height: ${({ $size }) => SIZES[$size].height};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.005em;
  white-space: nowrap;
  min-width: auto;
  ${({ $containerStyle }) => $containerStyle}
  ${({ $outlined }) => $outlined && css`
      background-color: transparent;
      font-style: normal;
      /* font-size: 16px; */
      color: #0064A8;

      border: 1px solid #0064A8;
  `}

  :active {
    /* transition: transform .2s ease-in-out; */
    /* transform: ${props => (props.$loading || props.disabled) ? 'unset' : 'scale(0.98)'}; */
  }

  :disabled {
    cursor: default;
    transform: unset;
    background-color: #B0B7C3;
  }

  :focus-within {
    transition: box-shadow 0.4s linear;
    -webkit-box-shadow: 0px 4px 24px -12px #0064A8;
    -moz-box-shadow: 0px 4px 24px -12px #0064A8;
    box-shadow: 0px 4px 24px -12px #0064A8;
  }

  ${({ responsive, theme }) =>
    responsive &&
    css`
      @media (max-width: ${theme.breakpoints.md + 'px'}) {
        padding: 10px 80px;
      }
  `}
  ${({ $loading }) => $loading && css`  
    cursor: not-allowed;
  `}

  ${({ $hasPulseAnimation, $pulseColor }) => $hasPulseAnimation && css`
    animation: ${pulse($pulseColor)} 1.5s infinite;
  `}
  
`;

export const Loader = styled.div`
  /* width: 20px; */
  /* height: 20px; */
  width: ${({ $dotSize }) => $dotSize || '20px'};
  height: ${({ $dotSize }) => $dotSize || '20px'};
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  ${({ $sx }) => $sx || {}};
`;

export const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: ${({ $dotColor }) => $dotColor || '#fff'};
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }
`;

export const CustomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
