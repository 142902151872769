import styled, { css } from 'styled-components';

export const Card = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  /* padding: 16px 12px; */
  padding: 16px 12px 16px 11px;

  width: calc(100% - 23px);
`;

export const EventHeader = styled.div`
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0;
  margin-left: 16px;
  width: 100%;
`;

export const BannerImg = styled.img`
  width: 155px;
  height: 225px;
  user-select: none;
  padding: 1.5px;
  object-fit: contain;
  aspect-ratio: 3 / 2;

  border-radius: 12.5px;
  /* border: 1.5625px solid #B0B7C3; */

  ${({ $maxWidth, theme }) =>
    $maxWidth &&
    css`
      @media (max-width: ${theme.breakpoints.xl + 'px'}) {
        max-width: ${({ $maxWidth }) => $maxWidth.replace('%', '') * 1.8 + '%'};
      }
  `}
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #203662;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #54565B;
  /* white-space: break-spaces; */
  margin-top: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`;

export const InfoArea = styled.span`
  display: flex;
  align-items: center;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #0064A8;
  width: 100%;
  justify-content: flex-end;
`;

export const DetailContainer = styled.div`
  display: flex;
  margin-top: 18px;
  align-items: center;
  &> :first-child {
    margin-right: 24px;
  }
  justify-content: space-between;
  /* width: 100%;
  margin-top: 23px; */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: flex-start;
    & > :first-child {
      margin-bottom: 12px;
    }
  }
`;
