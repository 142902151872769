import styled, { css } from 'styled-components';
import { NavLink as DomNavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Container = styled.aside`
  position: relative;
  width: 120px;
  height: 100vh;
  height: calc(100vh - 4rem);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  left: 0;

  @media only screen and (max-width: 1030px) {
    width: 100px;
  };
`;

export const MobileContainer = styled.aside`
  position: relative;
  /* width: 100%; */
  /* width: -webkit-fill-available; */
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  /* position: sticky; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: calc(100vw - 24px);
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: scroll; */
  overflow-x: hidden;
  overflow-y: auto;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: auto;
    a {
      display: grid;
      place-items: center;
      svg {
       width: 116.81px; 
       height: 32px;
      }
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  /* background: red; */
  width: 100%;
  margin-top: 100px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 0;
    padding-bottom: 100px;
  }
  .lang-bar {
    width: calc(100% - 21px);
  }
`;

export const NavLink = styled(DomNavLink)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;

  &.active {
    .active_bar {
      display: block;
      position: absolute;
      left: 5px;
      width: 8px;
      height: 100%;
      background: #0064A8;
      border-radius: 32px;
    }
  }
`;

export const LogoutArea = styled.button`
  display: flex;
  position: relative;
  outline: none;
  border: none;
  background: transparent;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: ${({ $$fullSize }) => ($$fullSize ? '100%' : 'auto')};
  padding: 10px 0;
  cursor: pointer;
`;

export const MenuItem = styled.li`
  width: 100%;
  margin: ${({ $mr }) => $mr || '24px 0'};
  .custom {
    width: 27px;
    height: 27px;
    & > path {
      fill: #B0B7C3;
    }
  }
  &:hover,
  ${NavLink}.active {
    .nav_icon > g > path {
      fill: #0064A8;
    }
    .custom > path {
      fill: #0064A8;
    }
  }

  &:hover {
    a > .nav_icon > g > path {
      fill: #0064A8;
    }
  }
`;

export const Line = styled.li`
  width: 80%;
  height: 1px;
  /* background-color: ${({ theme }) => theme.colors.placeholder}; */
  background: #B0B7C3;
  opacity: 0.32;
  margin: 24px 0 48px 0;
  /* flex: none; */
  /* order: 1; */
  /* flex-grow: 0; */
`;

export const SelectContainer = styled.select`
  width: 70%;
  height: 40px;
  border: 1px solid #0064A8;
  border: none;
  outline: none;
  background-color: #FAFBFC;
  border-radius: 16px;
  /* padding: 0 10px; */
  user-select: none;
  
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M9.87998 1.28957L5.99998 5.16957L2.11998 1.28957C1.72998 0.89957 1.09998 0.89957 0.70998 1.28957C0.31998 1.67957 0.31998 2.30957 0.70998 2.69957L5.29998 7.28957C5.68998 7.67957 6.31998 7.67957 6.70998 7.28957L11.3 2.69957C11.69 2.30957 11.69 1.67957 11.3 1.28957C10.91 0.90957 10.27 0.89957 9.87998 1.28957Z' fill='%23B0B7C3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 18px center;

  padding: 0 32px 0 22px;

  color: #B0B7C3;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  text-transform: uppercase;
  ${({ $sx }) => $sx && $sx};
`;

export const HamburgerMenu = styled.button`
  outline: none;
  border: none;
  background: #FAFBFC;
  border-radius: 16px;
  display: grid;
  place-items: center;
  padding: 8px;
  z-index: 202;
  transition: all .3s ease-in-out;
  ${({ $isActive }) => $isActive && css`
    background: #0064A8;
    svg > g > path {
      fill: #fff;
    }
  `}
`;

export const MobileOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: #1E1E1E;
  opacity: .7;
  z-index: 201;
`;

export const MobileMenu = styled(motion.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 59px;
  height: 100vh;
  width: 316px;
  background: #fff;
  top: 0;
  right: 0;
  z-index: 202;
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (orientation: landscape) {
    padding-top: 0;
  }
`;

export const MobileNavLink = styled(DomNavLink)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;

  &.active {
    .active_bar {
      display: block;
      position: absolute;
      left: 5px;
      width: 8px;
      height: 100%;
      background: #0064A8;
      border-radius: 32px;
    }
  }
`;

export const MobileExitButton = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  .nav_icon {
    margin-left: 36px;
    margin-right: 31px;
    min-width: 32px;
  }
`;

export const MobilePathText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #B0B7C3;
  ${({ $sx }) => $sx && $sx};
`;

export const MobileMenuItem = styled.li`
  width: 100%;
  margin: ${({ $mr }) => $mr || '24px 0'};
  .nav_icon {
    margin-left: 36px;
    margin-right: 31px;
  }
  .custom {
    width: 27px;
    height: 27px;
    & > path {
      fill: #B0B7C3;
    }
  }
  &:hover,
  ${MobileNavLink}.active {
    .nav_icon > g > path {
      fill: #0064A8;
    }
    .custom > path {
      fill: #0064A8;
    }
    ${MobilePathText} {
      color: #0064A8;
    }
  }
  &:hover {
    a > .nav_icon > g > path {
      fill: #0064A8;
    }
  }
`;

