import React from 'react';
import { EventContext } from 'context/EventContext';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/qa-ok.svg';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { ModalCloseButtonUI } from 'components/Modal';
import {
  Wrapper,
  Overlay,
  SuccessfulContent,
  SuccessTitleText,
  IconContainer,
  SuccessText
} from './style';

const QuestionModal = ({
  closeModal,
  eventId
}) => {
  const { createQuestiononEvent } = React.useContext(EventContext);
  const [question, setQuestion] = React.useState({
    question: { value: '', error: '' },
    hasProcess: false,
    hasSuccessful: false,
  });

  const handleTextChange = (val, key) => {
    setQuestion({ ...question, [key]: { value: val, error: '' } });
  };

  const handleSendQuestion = async () => {
    if (!question.question.value) {
      setQuestion({ ...question, question: { ...question.question, error: 'Question is required' } });
      return;
    }

    setQuestion({ ...question, hasProcess: true });
    const payload = {
      question: question.question.value,
      eventId: eventId,
    };

    try {
      await createQuestiononEvent(payload);
      setQuestion({
        question: { value: '', error: '' },
        hasProcess: false,
        hasSuccessful: true,
      });
    } catch (error) {
      setQuestion({ ...question, question: { ...question.question, error: error }, hasProcess: false });
    }

  };

  const handleKey = (event) => {
    if (event.key === 'Enter') {
      handleSendQuestion();
    }
  };

  return (
    <Wrapper>
      {question.hasSuccessful &&
        <Overlay>
          <ModalCloseButtonUI
            closeModal={() => { closeModal?.() }}
          />
          <SuccessfulContent>
            <SuccessTitleText>Tebrikler</SuccessTitleText>
            <IconContainer>
              <SuccessIcon />
            </IconContainer>
            <SuccessText>Sorunuz başarıyla gönderildi.</SuccessText>
          </SuccessfulContent>
        </Overlay>
      }
      <TextInput
        as={'textarea'}
        type={'text'}
        label={'Question'}
        placeholder={'Write a message...'}
        value={question.question.value}
        onChange={e => handleTextChange(e.target.value, 'question')}
        disabled={question.hasProcess}
        error={question.question.error}
        containerStyle={{ width: '100%' }}
        inputStyle={{ resize: 'none', paddingTop: '10px', height: '100px', paddingBottom: '10px' }}
        cols={'4'}
        onKeyDown={handleKey}
      />
      <Button
        title={'Send'}
        onClick={handleSendQuestion}
        loading={question.hasProcess}
        // fullSize
        containerStyle={{ marginBottom: '14px', float: 'right', padding: '0 40px' }}
      />
    </Wrapper>
  )
};

export default QuestionModal;
