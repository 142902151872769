import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 80px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 17px;
    min-width: 300px;
  }
`;

export const BackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  /* position: relative; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 24px;
  margin-top: 24px;
  svg {
    margin-right: 17px;
    path {
      fill: #203662;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: fixed;
    margin: 0;
    top: 92px;

    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    svg {
      margin-right: 12px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  top: 27px;
  background: #FFFFFF;
  border-radius: 0 0 16px 16px;
  padding: 24px;
  width: calc(98% - 42px);
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 62px);
  }
`;

export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  top: 27px;
  position: relative;
  border-radius: 16px 16px 0 0;
  width: calc(98% - 42px);
  background: #fff;
  border-bottom: 1px solid #B0B7C3;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 14px);
    padding: 10px 0;
    flex-direction: column;
    border-radius: 0;
  }
`;

export const ContentDetail = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #54565B;
  white-space: break-spaces;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #203662;
  word-break: break-word;
  padding-bottom: 15px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const TopBanner = styled.img`
  width: 49%;
  height: 400px;
  object-fit: cover;
  border: 1px solid #54565B;
  width: 100%;
  border-radius: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    border-radius: 0;
    object-fit: contain;
    height: auto;
    border: none;
    &:first-child {
      ${({ $isSingular }) => !$isSingular && `
        margin-bottom: 14px;
      `}
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 85vh;
  }
`;

export const ErrorContainer = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* height: calc(100vh - 92px); */
    height: calc(100vh - 100px);
  }
`;

export const ErrorText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;
  color: #54565B;
`;
