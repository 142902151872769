import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 19px;
  padding-top: 36px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-left: 0;
    padding-top: 21px;
  }
`;

export const Content = styled.div`
  display: grid;
  /* grid-template-columns: fit-content(25ch) 1fr; */
  /* grid-template-columns: minmax(150px, 200px) 1fr; */
  grid-template-columns: 180px 1fr;
  padding-bottom: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-bottom: 10px;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    place-items: baseline;
  }
`;

export const Time = styled.p`
  margin-right: 40px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: right;
  color: #203662;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #54565B;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
