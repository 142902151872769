import React from 'react';
import { RE_DIGIT } from 'helpers';
import { CodeInput, CodeInputArea } from './style';


const OneTimeCode = ({
  value,
  valueLength,
  onChange,
  inputMargin,
  containerStyle,
  error,
  onComplate,
  mobileStyle
}) => {

  const valueItems = React.useMemo(() => {
    const valueArray = value.split('');
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = (e, idx) => {
    let targetValue = e.target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = e.target.nextElementSibling;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(e.target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue);
      e.target.blur();
    }
  };

  const inputOnKeyDown = (e) => {
    if (value.trim().length === valueLength) {
      if (e.key === 'Enter') {
        onComplate?.();
      }
    }

    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(e.target);
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(e.target);
    }

    const targetValue = e.target.value;

    e.target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(e.target);
  };

  const inputOnFocus = (e) => {
    const prevInputEl = e.target.previousElementSibling;
    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }
    e.target.setSelectionRange(0, e.target.value.length);
  };

  return (
    <CodeInputArea
      $inputMargin={inputMargin}
      $containerStyle={containerStyle}
      $mobileStyle={mobileStyle}
    >
      {valueItems.map((digit, idx) => (
        <CodeInput
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={inputOnFocus}
          $error={error}
        />
      ))}
    </CodeInputArea>
  );
};

export default OneTimeCode;
