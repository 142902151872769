import { userInstance, authInstance } from './http';
import errorHandler from './errorHandler';
// import { getDeviceId } from 'helpers';
// import { getLocalStorage, setLocalStorage } from 'helpers';

export const getUser = async (lang) => {
  try {
    const { data } = await userInstance(lang).get('/me');
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changeEmail = async ({ lang, payload }) => {
  try {
    const { data } = await userInstance(lang).post('/change/email', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changeEmailSave = async ({ lang, payload }) => {
  try {
    const { data } = await userInstance(lang).post('/change/email/save', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changePhone = async ({ lang, payload }) => {
  try {
    const { data } = await userInstance(lang).post('/change/gsm', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changePhoneSave = async ({ lang, payload }) => {
  try {
    const { data } = await userInstance(lang).post('/change/gsm/save', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changeProfileInfo = async ({ lang, userId, payload }) => {
  try {
    const { data } = await userInstance(lang).put(`/${userId}`, payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const changePassword = async ({ lang, payload }) => {
  try {
    const { data } = await authInstance(lang).post('/change/password', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
