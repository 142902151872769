import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 80px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 17px;
    min-width: 300px;
  }
`;

export const BackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  /* position: relative; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: ${({ $color }) => $color || '#203662'};
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 24px;
  margin-top: 24px;
  svg {
    margin-right: 17px;
    path {
      fill: ${({ $color }) => $color || '#203662'};
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* padding: 0 20px; */
    /* padding: 0 20px; */
    position: fixed;
    margin: 0;
    top: 92px;

    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    svg {
      margin-right: 12px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  top: 27px;
  background: #FFFFFF;
  border-radius: 0 0 16px 16px;
  /* border-radius: 16px; */
  padding: 24px;
  /* width: calc(98% - 36px); */
  width: calc(98% - 42px);
  /* TODO :: responsive */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* width: calc(98% - 70px); */
    width: calc(100% - 62px);
    /* width: calc(100% - 48px); */
  }
`;

export const EventHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;
  top: 27px;
  position: relative;
  border-radius: 16px 16px 0 0;
  /* width: calc(98% - 36px); */
  width: calc(98% - 42px);
  background: #fff;
  border-bottom: 1px solid #B0B7C3;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* width: calc(100% - 62px); */
    width: calc(100% - 14px);
    padding: 10px 0;
    flex-direction: column;
    border-radius: 0;
  }
  /* &>:not(:first-child) {
    border-radius: 0px 16px 16px 0px;
  } */

`;

export const EventDetails = styled.div`

`;

export const ButtonsContainer = styled.div`
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 20px;
  /* &>:first-child {
    margin-right: 16px;
    margin-top: 20px;
  } */
  &>:not(:last-child) {
    margin-right: 16px;
    margin-top: 20px;
  }
  flex-wrap: wrap;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* flex-direction: row; */
  }
  @media only screen and (max-width: 430px) {
    /* flex-direction: column; */
  }
`;

export const Banner = styled.img`
  border-radius: 12.5px;
  border: 0.5px solid #B0B7C3;
  /* width: 381px; */
  width: 50%;
  height: 490px;
  object-fit: contain;
  float: left;
  margin-right: 28px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`;

export const EventDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #54565B;
  white-space: break-spaces;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const BrandLogo = styled.img`
  max-width: 200px;
  width: unset;
  height: 44px;
  margin-bottom: 5px;
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #203662;
  word-break: break-word;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const EventDate = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #203662;
  padding-top: 24px;
  padding-bottom: 12px;
  word-break: break-word;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const JoinStream = styled.button`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  /* font-size: 14px; */
  line-height: 120%;
  /* letter-spacing: 0.005em; */
  /* color: #C50F3C; */
  
  font-size: 27.1765px;
  letter-spacing: 0.005em;
  color: #C50F3C;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(197, 15, 60, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(197, 15, 60, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(197, 15, 60, 0);
  }
`;

export const RedDot = styled.div`
  /* width: 16px;
  height: 16px;
  background: #DF6675;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  margin-right: 3px; */
  width: 31.06px;
  height: 31.06px;
  border: 1.94118px solid #FFFFFF;
  border-radius: 100%;
  background: #C50F3C;
  margin-right: 5px;
  animation: ${pulse} 2s infinite;
`;

export const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 36px;
`;

export const TopBanner = styled.img`
  width: 49%;
  height: 400px;
  object-fit: cover;
  border: 1px solid #54565B;

  ${({ $right }) => $right && `
    border-radius: 0px 16px 16px 0px;
  `}

  ${({ $left }) => $left && `
    border-radius: 16px 0px 0px 16px;
  `}

  ${({ $isSingular }) => $isSingular && `
    width: 100%;
    border-radius: 16px;
  `}

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    border-radius: 0;
    object-fit: contain;
    height: auto;
    border: none;
    &:first-child {
      ${({ $isSingular }) => !$isSingular && `
        margin-bottom: 14px;
      `}
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 85vh;
  }
`;

export const ReminderButton = styled.button`
  border: none;
  outline: none;
  user-select: none;
  padding: 0 10px;
  /* width: 40px; */
  display: flex;
  p {
    color: #fff;
    margin-left: 5px;
    white-space: nowrap;
  }
  /* height: 40px; */
  height: 32px;
  background: #8DC1C5;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 20px;
  
  & > svg {
    path {
      fill: #fff;
    }
  }

  :disabled {
    cursor: default;
    transform: unset;
    background-color: #B0B7C3;
  }
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    /* width: 100%; */
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* width: auto; */
  }
`;

export const UserStatusText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  color: ${({ $color }) => $color || '#54565B'};
  margin-bottom: ${({ $mb }) => $mb || null};
`;

export const ErrorContainer = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* height: calc(100vh - 92px); */
    height: calc(100vh - 100px);
  }
`;

export const ErrorText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 21px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

  }
  color: #54565B;
`;
