import React from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { UserContext } from 'context/UserContext';
import ErrorContainer from 'components/ErrorContainer';
import { emailValidate } from 'helpers/validate';
import { LanguageContext } from 'context/withLanguages';
import {
  Container
} from '../style';

const ChangeMail = ({
  value,
  handleTextChange,
  openConfirmModal,
}) => {
  const { changeMailSendCode } = React.useContext(UserContext);
  const { t } = React.useContext(LanguageContext);
  const [user, setUser] = React.useState({ email: { value: '', error: false } });
  const [stat, setStat] = React.useState({ loading: false, error: null });

  const changeText = (text, type) => {
    setUser({ ...user, [type]: { value: text, error: false } });
  };

  const handleChangeEmail = async () => {
    const mailError = emailValidate(user.email.value);
    if (mailError) {
      setUser({ email: { value: user.email.value, error: mailError && t(`validation_errors.${mailError}`) } });
      return;
    }
    setStat({ error: null, loading: true });
    try {
      await changeMailSendCode(user.email.value);
      openConfirmModal({ email: user.email.value });
      setStat({ error: null, loading: false });
    } catch (error) {
      setStat({ error: error, loading: false });
    }
  };

  return (
    <Container>
      <TextInput
        label={'E-posta Adresi'}
        placeholder={'E-posta Adresi'}
        value={value}
        disabled={true}
        containerStyle={{ width: '100%' }}
      />

      <TextInput
        type={'email'}
        name={'email'}
        label={'Yeni E-posta Adresi'}
        placeholder={'E-posta Adresi'}
        value={user.email.value}
        onChange={e => changeText(e.target.value, 'email')}
        error={user.email.error}
        containerStyle={{ width: '100%',}}
        autocomplete={'email'}
      />

      {stat.error && <ErrorContainer message={stat.error} />}
      <Button
        size={'md'}
        title={'Kaydet'}
        fullSize
        containerStyle={{ marginBottom: '20px', marginTop: '30px', }}
        onClick={handleChangeEmail}
        loading={stat.loading}
      />
    </Container>
  )
};

export default ChangeMail;
