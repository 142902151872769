import { v4 as uuidv4 } from 'uuid';
import moment from "moment/moment";

export const RE_DIGIT = new RegExp(/^\d+$/);

export const slugify = (str) => {
  const a = 'àáäâãåăæąćčçđďèéëêěęģğĥìíïîįıłĺľńňñòóöôõøœøŕřśşšťţùúüûůűųýÿžź·/_,:;';
  const b = 'aaaaaaaacccddeeeeeeegghiiiiiilllnnooooooooorrsssttuuuuuyyzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/\\--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const formatDateToNumeric = (date, seperator = '.') => {
  const newDate = new Date(date);
  if (JSON.stringify(newDate) === 'null') {
    return '';
  }
  return newDate.toLocaleDateString('tr-TR', { year: 'numeric', month: 'numeric', day: 'numeric' }).replace(' ', seperator);
};

export const eventFormatDateStartEnd = (start, end, language) => {
  if (!language) {
    language = {};
    language.code = 'tr';
  }
  return `${moment(start).utc().locale(language.code).format("DD MMMM YYYY dddd | LT")} - ${moment(end).utc().locale(language.code).format("LT")}`;
};

export const timeFormatter = (startDate, endDate, language) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (!language) {
    language = {};
    language.code = 'tr';
  }

  if (end.toString() === 'Invalid Date' || start.toString() === 'Invalid Date') {
    return '-';
  }
  return `${moment(start).utc().locale(language.code).format("LT")} - ${moment(end).utc().locale(language.code).format("LT")}`;
};

export const formatJoinDescription = ({ startDate, endDate, title, language, translatedText }) => {
  const startedDate = new Date(startDate);
  const endedDate = new Date(endDate);
  if (endedDate.toString() === 'Invalid Date' || startedDate.toString() === 'Invalid Date') {
    return '-';
  }
  if (!language) {
    language = {};
    language.code = 'tr';
  }

  const day = moment(startedDate).utc().locale(language.code).format('DD');
  const month = moment(startedDate).utc().locale(language.code).format('MMMM');
  const year = moment(startedDate).utc().locale(language.code).format('YYYY');
  const dayOfWeek = moment(startedDate).utc().locale(language.code).format('dddd');
  const startedTime = moment(startedDate).utc().locale(language.code).format('LT');
  const endedTime = moment(endedDate).utc().locale(language.code).format('LT');

  const convertedText = translatedText.replace('{{day}}', day).replace('{{month}}', month).replace('{{year}}', year).replace('{{dayOfWeek}}', dayOfWeek).replace('{{startedTime}}', startedTime).replace('{{endedTime}}', endedTime).replace('{{title}}', title);
  return convertedText;
};

export const getWeekDays = ({ short = true, locale = 'tr' }) => {
  const formatter = new Intl.DateTimeFormat(locale, { weekday: 'long' })

  return Array.from(Array(7).keys())
    .map((day) => formatter.format(new Date(Date.UTC(2021, 5, day))))
    .map((weekDay) => {
      if (short) {
        return weekDay.substring(0, 3).toUpperCase()
      }
      return weekDay.substring(0, 1).toUpperCase().concat(weekDay.substring(1))
    })
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getLocalStorage = (key) => {
  const data = window.localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch (_) {
    return data;
  }
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const parseQuery = (str) => {
  if (typeof str != "string" || str.length === 0) return {};
  str.charAt(0) === "?" && (str = str.substr(1));
  const s = str.split("&");
  const s_length = s.length;
  let bit, query = {}, first, second;
  for (let i = 0; i < s_length; i++) {
    bit = s[i].split("=");
    first = decodeURIComponent(bit[0]);
    if (first.length === 0) continue;
    second = decodeURIComponent(bit[1]);
    if (typeof query[first] == "undefined") query[first] = second;
    else if (query[first] instanceof Array) query[first].push(second);
    else query[first] = [query[first], second];
  }
  return query;
};

export const getDeviceId = () => {
  return getLocalStorage('device_uuid') || (setLocalStorage('device_uuid', uuidv4()), getLocalStorage('device_uuid'))
};

export const EVENT_JOIN_STATUS = {
  JOIN: { key: 'join', value: 'join' },
  PENDING: { key: 'pending', value: 'pending' },
  APPROVE: { key: 'approve', value: 'approve' },
  REJECT: { key: 'reject', value: 'reject' },
};

export const getEventParticipantTypeStyle = (type) => {
  switch (type) {
    case 'pending':
      return {
        color: '#FFC400',
      };
    case 'approve':
      return {
        color: '#2db56e',
      };
    case 'reject':
      return {
        color: '#FF5C5C',
      };
    case 'passive':
      return {
        color: '#B0B7C3',
      };
    default:
      return {
        color: '#0064A8',
      };
  }
};

export const DATE_FILTER = {
  upcoming: 'upcoming_events',
  now: 'now_events',
  past: 'past_events',
};
