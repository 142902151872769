import styled from 'styled-components';

export const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  /* width: 354px; */
  width: 300px;
  height: 100%;
  background: #E6ECFE;
  padding: 50px 24px 24px 24px;
`;

export const Content = styled.div`
  height: calc(100% - 124px);
  position: relative;
`;

export const Container = styled.div`
  margin-top: 20px;
  height: calc(100%);
  overflow-y: auto;
  padding-right: 10px;
  &>:not(:last-child) {
   margin-bottom: 16px;
  }
`;

export const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  user-select: none;
`;

export const Navigations = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: 5px;
`;

export const NavButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  padding: 5px;
  margin-right: ${({ $isLeft }) => $isLeft ? '10px' : '0'};;
  svg {
    transform: ${({ $isLeft }) => $isLeft ? 'rotate(270deg)' : 'rotate(90deg)'};
  }
`;

export const BlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #FFFFFF;
  border-radius: 16px;
`;

export const BlankContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

export const BlankText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #203662;
  padding: 0 30px;
  margin-top: 24px;
`;

export const NextDayBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #0064A8;
  margin-top: 24px;
`;
