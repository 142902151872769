import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 40vh;
  }
`;

export const Frame = styled.iframe`
  width: ${({ $width }) => $width || '100%'};
  height: ${({ $height }) => $height || '100%'};
  border: none;
  border-radius: 8px;
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg); 
  }
`;

const dotSpin = keyframes`
  80%, 100% {
    transform: rotate(360deg);
  }
`;

const scaleDot = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  animation: ${spin} 2.5s infinite linear both;
  margin: 0 auto;
  ${({ $loading }) => (!$loading && 'display: none;')}
`;

export const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: ${dotSpin} 2.0s infinite ease-in-out both; 
  &:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #203662;
    border-radius: 100%;
    animation: ${scaleDot} 2.0s infinite ease-in-out both; 
  }
  &:nth-child(1) { animation-delay: -1.1s; }
  &:nth-child(2) { animation-delay: -1.0s; }
  &:nth-child(3) { animation-delay: -0.9s; }
  &:nth-child(4) { animation-delay: -0.8s; }
  &:nth-child(5) { animation-delay: -0.7s; }
  &:nth-child(6) { animation-delay: -0.6s; }
  &:nth-child(1):before { animation-delay: -1.1s; }
  &:nth-child(2):before { animation-delay: -1.0s; }
  &:nth-child(3):before { animation-delay: -0.9s; }
  &:nth-child(4):before { animation-delay: -0.8s; }
  &:nth-child(5):before { animation-delay: -0.7s; }
  &:nth-child(6):before { animation-delay: -0.6s; }
`;
