
const theme = {
  colors: {
    primary: '#54565B',
    gilead: '#D93748'
  },
  breakpoints: {
    xsm: 380,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 2468,
  }
};

export default theme;
