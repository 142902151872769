import ReactSelect from 'react-select';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { ReactComponent as ErrorIcon } from 'assets/img/icons/error.svg';
// import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow.svg';
import makeAnimated from 'react-select/animated';
import {
  Label,
  Wrapper,
  ErrorContainer,
  ErrorText,

  MobileWrapper,
  MobileContainer,
  MobileInput,
  MobileLabel,
  MobileErrorText,
  MobileErrorContainer,
  SheetHeader,
  SheetCloseBtn,
  OptionWrapper,
  MobileOptionContainer,
  OptionCircle,
  OptionText,
  IconContainer,
  ArrowIcon
} from './style';
import { useDimensions } from 'hooks/useDimensions';
import { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import 'react-spring-bottom-sheet/dist/style.css';

const Dropdown = ({
  placeholder,
  optionFormat,
  onChange,
  options,
  defaultValue,
  value,
  noOptionsMessage,
  isClearable,
  isLoading,
  title,
  containerStyle,
  fullWidth,
  error,
  hasTop,
  disabled,
  labelStyle,
  dropdownStyle,
  mobileHeightRatio = 1.41,
  ...props
}, ref) => {
  const animatedComponents = makeAnimated();
  const dims = useDimensions();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ value: '', label: '' });

  useImperativeHandle(ref, () => ({
    openSheet: openBottomSheet,
    closeSheet: closeBottomSheet,
  }));

  useEffect(() => {
    if (value) {
      // setSelected(options?.find((opt) => opt.value === value));
      setSelected(value);
      // console.log('options?.find((opt) => opt.value === value)', value);
    }
  }, [value]);

  const openBottomSheet = () => {
    disabled || setOpen(true);
  };

  const closeBottomSheet = () => {
    setOpen(false);
  };

  const onDismiss = () => {
    setOpen(false);
  };

  const selectOption = (option) => {
    onChange(option);
    setSelected(option);
    setOpen(false);
  };

  if (dims.isMobile) {
    return (
      <MobileWrapper $containerStyle={containerStyle} $fullWidth={fullWidth}>
        <BottomSheet
          ref={ref}
          open={open}
          header={<SheetHeader>
            <MobileLabel>{title}</MobileLabel>
            <SheetCloseBtn onClick={closeBottomSheet}>X</SheetCloseBtn>
          </SheetHeader>}
          snapPoints={({ minHeight }) => mobileHeightRatio > 0 ? dims.height / mobileHeightRatio : minHeight}
          onDismiss={onDismiss}
        >
          <OptionWrapper>
            {options?.map((option, idx) => (
              <MobileOptionContainer
                key={`mobile_opt_${option?.value || idx}`}
                onClick={() => selectOption(option)}
                $selected={option.value === selected?.value}
              >
                <OptionCircle $selected={option.value === selected?.value} />
                <OptionText>{option.label}</OptionText>
              </MobileOptionContainer>
            ))}
          </OptionWrapper>
        </BottomSheet>

        {title && <Label $labelStyle={labelStyle} >{title}</Label>}
        <MobileContainer $containerStyle={dropdownStyle?.container} onClick={openBottomSheet}>
          <MobileInput
            placeholder={placeholder}
            value={selected?.label}
            disabled={true}
            $disabled={disabled}
            $error={!!error}
            $open={open}
            $inputStyle={dropdownStyle?.input}
            {...props}
          />
          <IconContainer $iconTop={dropdownStyle?.container?.height && ((+dropdownStyle?.container?.height.replace('px', '') - 2) / 2)}>
            <ArrowIcon $open={open} />
          </IconContainer>
          {!!error && <MobileErrorContainer>
            <ErrorIcon />
            <MobileErrorText>{error}</MobileErrorText>
          </MobileErrorContainer>
          }
        </MobileContainer>

      </MobileWrapper>
    );
  };

  return (
    <Wrapper ref={ref} $hasTop={hasTop} $containerStyle={containerStyle} $fullWidth={fullWidth}>
      {/* <Label $error={!!error}>{title}</Label> */}
      {title && <Label>{title}</Label>}
      <ReactSelect
        placeholder={placeholder}
        // formatOptionLabel={optionFormat || defaultFormat}
        components={animatedComponents}
        options={options}
        defaultValue={defaultValue}
        value={value}
        isDisabled={disabled}
        noOptionsMessage={(e) => noOptionsMessage?.(e) || 'Bulunamadı'}
        onChange={onChange}
        isClearable={isClearable}
        isLoading={isLoading}
        styles={{
          container: (provided, state) => ({
            ...provided,
            height: '56px',
            borderRadius: '16px',
            width: '100%',
            top: '4px',
            outline: 'none',
            border: 'none',
            ...dropdownStyle?.container
          }),
          control: (provided, state) => ({
            ...provided,
            borderRadius: '16px',
            borderColor: '#B0B7C3',

            ...(state.isFocused && {
              borderColor: '#0064A8',
            }),
            ...(state.isDisabled && {
              backgroundColor: '#fff',
            }),
            ...(state.isDisabled && {
              opacity: .8,
            }),
            // borderWidth: '.2px',
            borderWidth: '1px',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            border: 'none !important',
            padding: '0 16px !important',
            fontFamily: 'Poppins',
            fontSize: '14px',
            color: '#54565B',
            ...(state.isDisabled && {
              backgroundColor: '#fff',
            }),
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            padding: '0 16px',
            ...dropdownStyle?.indicatorsContainer
          }),
          clearIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: '0',
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none'
          }),
          input: (provided, state) => ({
            ...provided,
            height: '53px',
            border: 'none !important',
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '0.005em',
            color: '#54565B',
            padding: '0 16px',
            fontFamily: 'Poppins',
            ...dropdownStyle?.input
            // '&:focus': {}
          }),
          menu: (p) => ({
            ...p,
            marginTop: '15px !important',
            zIndex: 9,
          }),
          option: (provided, state) => ({
            ...provided,
            fontFamily: 'Poppins',
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '0.005em',
            padding: '16px',
            color: state.isSelected ? '#fff' : '#54565B',
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: '#B0B7C3',
            fontSize: '14px',
            lineHeight: '120%',
            letterSpacing: '0.005em',
            fontFamily: 'Poppins',
            padding: '0 16px',

            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: '1',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            // ...(state.isDisabled && {
            //   opacity: .8,
            // }),
            padding: '0',
            ...dropdownStyle?.valueContainer
          }),
          menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 9999
          }),
        }}
        menuShouldScrollIntoView={false}
        menuPosition="fixed" 
        {...props}
      />
      {!!error && <ErrorContainer>
        <ErrorIcon />
        <ErrorText>{error}</ErrorText>
      </ErrorContainer>
      }
    </Wrapper>
  )
};

export default forwardRef(Dropdown);
