import styled from 'styled-components';
import { motion } from 'framer-motion';

const lineHeight = 2;

export const Nav = styled.nav`
  display: block;
  position: relative;
`;

export const TabBar = styled.ul`
  display: flex;
  border-bottom: ${lineHeight}px solid rgba(233, 234, 236, 0.6);
  list-style: none;
  display: -webkit-box;
  overflow-x: scroll;
  padding-bottom: 4px;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const TabItem = styled.li`
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: ${({ $isActive }) => $isActive ? '#0064A8' : '#B0B7C3'};

  &:not(:last-child) {
    margin-right: 32px;
  }

  padding-bottom: 3px;
  
  user-select: none;
  cursor: pointer;
  transition: color .5s cubic-bezier(.25,.8,.5,1);
`;

export const Underline = styled(motion.div)`
  position: absolute;
  bottom: -${lineHeight}px;
  left: 0;
  right: 0;
  height: ${lineHeight}px;
  background: #0064A8;
`;
