import React from 'react';
import { ReactComponent as LogoFull } from 'assets/img/logo/logo-full.svg';
// import { LanguageContext } from 'context/withLanguages';
import { Link } from 'react-router-dom';
// import { PATHS } from 'routes/paths';
import {
  Container,
  // SelectContainer,
} from './style';

const AuthHeader = ({ ...props }, ref) => {
  // const { getAvailableLanguages, changeLanguage, language } = React.useContext(LanguageContext);

  return (
    <Container id='header' ref={ref}>
      {/* <Link to={PATHS.AUTH}> */}
      <Link to={'/'}>
        <LogoFull title={'Gilead'} />
      </Link>

      {/* <SelectContainer
        onChange={(e) => changeLanguage(e.target.value)}
        value={language.code}
      >
        {getAvailableLanguages().map((lang, index) => (
          <option key={index} value={lang.code}>
            {lang.code}
          </option>
        ))}
      </SelectContainer> */}
    </Container>
  )
};

export default React.forwardRef(AuthHeader);
