import React from 'react';
import { EVENT_JOIN_STATUS, formatJoinDescription, getEventParticipantTypeStyle, slugify, timeFormatter } from 'helpers';
import { LanguageContext } from 'context/withLanguages';
import { EventContext } from 'context/EventContext';
import { ReactComponent as EventModalIcon } from 'assets/img/icons/event_modal.svg';
import Modal from 'components/Modal';
import { CustomButton } from 'components/Button';
// import { leaveEventRequest } from 'services/eventService';
// import { AppContext } from 'context/AppContext';
import { PATHS } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import {
  Wrapper,
  Card,
  SubTitle,
  Text,
  Title,
  SpeakerContainer,
  SpeakerCard,
  Avatar,
  FlexBox,
  Section,
  JoinButton,
  BranchText
} from './style';

const WatchButton = ({ handleWatchEvent }) => {
  return (
    <JoinButton $type={'watch'} onClick={() => handleWatchEvent()}>
      Etkinliği İzle
    </JoinButton>
  );
};

const SessionCard = ({
  id,
  title,
  start_date,
  end_date,
  event_speaker,
  event_participant,
  is_past,
  is_active,
  is_watchable,
  ...rest
}) => {
  const nav = useNavigate();
  const { t, language } = React.useContext(LanguageContext);
  const { joinEvent, leaveEvent } = React.useContext(EventContext);
  // const { getBranchesData } = React.useContext(AppContext);
  const [hasJoinProcess, setJoinProcess] = React.useState(false);
  const joinModalRef = React.useRef();
  const leaveModalRef = React.useRef();

  const getModerators = () => {
    const moderators = event_speaker ? event_speaker.filter(e => e.is_moderator) : [];
    const mods = [];
    moderators.forEach(e => {
      // const speaker = e.speakers[0];
      mods.findIndex(s => s.id === e.speakers[0].id) === -1 && mods.push(...e.speakers);
    });
    return mods || [];
  };


  const getSpeakers = () => {
    const speakerList = event_speaker ? event_speaker.filter(e => !e.is_moderator) : [];
    const speakers = [];
    speakerList.forEach(e => {
      // const speaker = e.speakers[0];
      speakers.findIndex(s => s.id === e.speakers[0].id) === -1 && speakers.push(...e.speakers);
    });
    return speakers || [];
  };

  const handleParticipantEvent = async (isRemind) => {
    setJoinProcess(true);
    try {
      await joinEvent({ id: id, is_reminder: isRemind });
    } catch (error) {
      console.log('Dashboard :: join event :: ', error);
    }
    setJoinProcess(false);
  };

  const handleLeaveEvent = async () => {
    setJoinProcess(true);
    try {
      await leaveEvent({ id });
    } catch (error) {
      console.log('Dashboard :: leave event :: ', error);
    }
    setJoinProcess(false);
  };

  const joinEventStatus = () => {
    joinModalRef.current.openModal();
  };

  const waitingEventStatus = () => {
    leaveModalRef.current.openModal();
  };


  const getJoinStatusStyle = (type) => {
    if (is_past || !is_active) {
      return getEventParticipantTypeStyle('passive');
    };
    switch (type) {
      case EVENT_JOIN_STATUS.APPROVE.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.APPROVE.key);
      case EVENT_JOIN_STATUS.PENDING.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.PENDING.key);
      case EVENT_JOIN_STATUS.REJECT.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.REJECT.key);
      default:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.JOIN.key);
    }
  };

  const handleWatchEvent = () => {
    const titleSlug = slugify(title || '') + '-' + id;
    nav(PATHS.STAGE_AREA.split(':')[0] + `${titleSlug}`);
  };

  const joinStatus = () => {
    if (is_watchable) {
      return <WatchButton handleWatchEvent={() => handleWatchEvent()} />;
    };

    if (is_past) {
      return <p style={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>Bu etkinlik sona ermiştir</p>;
    };

    switch (event_participant?.is_approved) {
      case EVENT_JOIN_STATUS.PENDING.key:
        // return <JoinButton $loading={hasJoinProcess} $type={'pending'} onClick={waitingEventStatus} >{t(`event_status.${EVENT_JOIN_STATUS.PENDING.value}`)}</JoinButton>;
        return <CustomButton
          Cmp={JoinButton}
          loading={hasJoinProcess}
          $type={'pending'}
          $is_past={is_past}
          onClick={waitingEventStatus}
          title={t(`event_status.${EVENT_JOIN_STATUS.PENDING.value}`)}
          dotColor={'#0064A8'}
          dotSize={'10px'}
        />;
      case EVENT_JOIN_STATUS.APPROVE.key:
        // return <JoinButton $loading={hasJoinProcess} $type={'approve'}>{t(`event_status.${EVENT_JOIN_STATUS.APPROVE.value}`)}</JoinButton>;
        return <CustomButton
          Cmp={JoinButton}
          loading={hasJoinProcess}
          $type={'approve'}
          $is_past={is_past}
          onClick={waitingEventStatus}
          title={t(`event_status.${EVENT_JOIN_STATUS.APPROVE.value}`)}
          dotColor={'#0064A8'}
          dotSize={'10px'}
        />;
      case EVENT_JOIN_STATUS.REJECT.key:
        // return <JoinButton $loading={hasJoinProcess} $type={'reject'}>{t(`event_status.${EVENT_JOIN_STATUS.REJECT.value}`)}</JoinButton>;
        return <CustomButton
          Cmp={JoinButton}
          loading={hasJoinProcess}
          $type={'reject'}
          $is_past={is_past}
          onClick={() => { }}
          title={t(`event_status.${EVENT_JOIN_STATUS.REJECT.value}`)}
          dotColor={'#0064A8'}
          dotSize={'10px'}
        />;
      default:
        // return <JoinButton $loading={hasJoinProcess} $type={'join'} onClick={joinEventStatus}>{t(`event_status.${EVENT_JOIN_STATUS.JOIN.value}`)}</JoinButton>;
        return <CustomButton
          Cmp={JoinButton}
          loading={hasJoinProcess}
          $type={'join'}
          $is_past={is_past}
          title={t(`event_status.${EVENT_JOIN_STATUS.JOIN.value}`)}
          onClick={joinEventStatus}
          dotColor={'#0064A8'}
          dotSize={'10px'}
        />;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moderators = React.useMemo(() => getModerators(), [event_speaker]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const speakers = React.useMemo(() => getSpeakers(), [event_speaker]);

  return (
    <Wrapper>
      {/* <CustomButton
        Cmp={JoinButton}
        title={'title'}
        dotColor={'#0064A8'}
        dotSize={'10px'}
        loading
      /> */}
      <Modal
        ref={joinModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.title'),
          icon: <EventModalIcon />,
          description: formatJoinDescription({ startDate: start_date, endDate: end_date, title: title, language, translatedText: t('modal.event.join_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: (isRemind) => handleParticipantEvent(isRemind),
          cancelText: t('modal.event.cancel'),
        }}
        mobileHeightRatio={0}
      />
      <Modal
        ref={leaveModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.leave_title'),
          icon: <EventModalIcon />,
          description: formatJoinDescription({ startDate: start_date, endDate: end_date, title: title, language, translatedText: t('modal.event.leave_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: () => handleLeaveEvent(),
          cancelText: t('modal.event.cancel'),
          isLeave: true,
        }}
        mobileHeightRatio={0}
      />

      <Title>{t('pages.home.activities_of_day')}</Title>
      <Card>
        <Section>
          <FlexBox $sx={{ marginBottom: '12px' }}>
            <SubTitle $noMargin>{t('pages.home.session_name')}</SubTitle>
            {joinStatus()}
          </FlexBox>
          <Text>{title}</Text>
        </Section>

        <Section>
          <SubTitle>{t('words.time')}</SubTitle>
          <Text>{timeFormatter(start_date, end_date, language)}</Text>
        </Section>

        <Section>
          <SubTitle>{t('pages.home.mod_session_chair')}</SubTitle>
          <SpeakerContainer>
            {moderators.map((moderator, idx) => (
              <SpeakerCard key={`moderator_${idx}`}>
                <Avatar src={moderator?.image} alt={moderator?.name_surname} />
                <div>
                  <BranchText>{moderator?.title?.name}</BranchText>
                  <Text>{moderator?.name_surname}</Text>
                </div>
              </SpeakerCard>
            ))}
          </SpeakerContainer>
        </Section>

        <Section>
          <SubTitle>{t('words.speakers')}</SubTitle>
          <SpeakerContainer>
            {speakers?.map((speaker, idx) => (
              <SpeakerCard key={`speaker_${idx}`}>
                <Avatar src={speaker?.image} alt={speaker?.name_surname} />
                <div>
                  <BranchText>{speaker?.title?.name}</BranchText>
                  <Text>{speaker?.name_surname}</Text>
                </div>
              </SpeakerCard>
            ))}
          </SpeakerContainer>
        </Section>

      </Card>
    </Wrapper>
  )
};

export default SessionCard;
