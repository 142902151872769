import React from 'react';
import { ReactComponent as LoginIcon } from 'assets/img/icons/login.svg';
import {
  Container,
  BackImage,
  OnBoardingArea,
  OnBoardingTitle,
  OnBoardingSubText,
  GoHome
} from './style';

const NotFoundPage = () => {
  return (
    <Container>
      <BackImage>
        <OnBoardingArea>
          <OnBoardingTitle>404!</OnBoardingTitle>
          <OnBoardingSubText>Görünüşe göre burada hiçbir şey bulamamışsın.</OnBoardingSubText>
          <GoHome to={'/'}>
            <LoginIcon /> Ana Sayfaya Dön</GoHome>
        </OnBoardingArea>
      </BackImage>
    </Container>
  )
};

export default NotFoundPage;
