import React from 'react';
import Seo from 'helpers/Seo';
import { formatJoinDescription, EVENT_JOIN_STATUS } from 'helpers';
import { LanguageContext } from 'context/withLanguages';
import { ReactComponent as FilterIcon } from 'assets/img/icons/filter.svg';
import Modal from 'components/Modal';
import FSLoading from 'components/FSLoading';
import EventContainer from './components/EventContainer';
import { ReactComponent as EventModal } from 'assets/img/icons/event_modal.svg';
import {
  Wrapper,
  HeaderContainer,
  Title,
  FilterButton
} from './style';
import { useDimensions } from 'hooks/useDimensions';
// import FilterMenu from './components/FilterMenu';
import FilterMenu from 'pages/App/Home/components/Events/components/FilterMenu';
import { EventContext } from 'context/EventContext';
import NoEvents from '../Home/components/Events/components/NoEvents';
import FilterArea from './components/FilterArea';

const Events = () => {
  const dims = useDimensions();
  const joinModalRef = React.useRef(null);
  const leaveModalRef = React.useRef(null);
  const filterMenuRef = React.useRef(null);
  const { t, language } = React.useContext(LanguageContext);
  const { states: { events, filteredData }, filterEvents, /* getEvents, */ joinEvent, leaveEvent } = React.useContext(EventContext);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [filter, setFilter] = React.useState({
    search: '',
    category: '',
    branch: '',
    event_type: ''
  });

  // React.useEffect(() => {
  // getEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  React.useEffect(() => {
    if (!Object.values(filter).every((val) => !val)) {
      filterEvents({
        event_type: filter.event_type?.value,
        title: filter.search,
        category: filter.category?.value,
        date: filter.date?.value,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const handleFilterChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  const handleJoinEvent = (event) => {
    setSelectedEvent(event);
    if (event.event_participant?.is_approved === EVENT_JOIN_STATUS.JOIN.key || !event.event_participant?.is_approved) {
      joinModalRef.current.openModal();
    }

    if (event.event_participant?.is_approved === EVENT_JOIN_STATUS.PENDING.key || event.event_participant?.is_approved === EVENT_JOIN_STATUS.APPROVE.key) {
      leaveModalRef.current.openModal();
    }
  };

  const openFilterMenu = () => {
    filterMenuRef.current.openModal();
  };

  const handleParticipantEvent = async (isRemind) => {
    try {
      await joinEvent({ id: selectedEvent.id, is_reminder: isRemind });

      if (!Object.values(filter).every((val) => !val)) {
        await filterEvents({
          event_type: filter.event_type?.value,
          title: filter.search,
          category: filter.category?.value,
          date: filter.date?.value,
        })
      }
    } catch (error) {
      console.log('Events :: join event :: ', error);
    }
  };

  const handleLeaveEvent = async (event) => {
    try {
      await leaveEvent({ id: event.id });
      if (!Object.values(filter).every((val) => !val)) {
        await filterEvents({
          event_type: filter.event_type?.value,
          title: filter.search,
          category: filter.category?.value,
          date: filter.date?.value,
        })
      }
    } catch (error) {
      console.log('Events :: leave event :: ', error);
    }
  };

  return (
    <Wrapper>
      <Seo
        title={t('pages.events.events')}
      />

      {dims.isMobile &&
        <Modal
          ref={filterMenuRef}
          mobileHeightRatio={0}
          title={'Filter'}
        >
          <FilterMenu
            handleFilterChange={handleFilterChange}
            filter={filter}
            closeMenu={() => filterMenuRef.current?.closeModal()}
          />
        </Modal>
      }

      <Modal
        ref={joinModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.title'),
          icon: <EventModal />,
          description: formatJoinDescription({ startDate: selectedEvent?.startDate, endDate: selectedEvent?.endDate, title: selectedEvent?.title, language, translatedText: t('modal.event.join_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: (isRemind) => handleParticipantEvent(isRemind),
          cancelText: t('modal.event.cancel'),
        }}
        mobileHeightRatio={0}
      />

      <Modal
        ref={leaveModalRef}
        showCloseButton={false}
        title={'EMİN MİSİNİZ?'}
        type={'confirmation'}
        size={'md'}
        confirmProps={{
          title: t('modal.event.leave_title'),
          icon: <EventModal />,
          description: formatJoinDescription({ startDate: selectedEvent?.startDate, endDate: selectedEvent?.endDate, title: selectedEvent?.title, language, translatedText: t('modal.event.leave_description') }),
          onConfirmText: t('modal.event.confirm'),
          onConfirmClick: () => handleLeaveEvent(selectedEvent),
          cancelText: t('modal.event.cancel'),
          isLeave: true,
        }}
        mobileHeightRatio={0}
      />

      <HeaderContainer>
        <Title>{t('pages.events.events')}</Title>
        {dims.isMobile &&
          <FilterButton onClick={openFilterMenu}>
            <FilterIcon />
          </FilterButton>
        }
      </HeaderContainer>
      {/* Web Filter */}
      {!dims.isMobile &&
        <FilterArea
          filter={filter}
          handleFilterChange={handleFilterChange}
        />
      }

      {Object.values(filter).every((val) => !val) &&
        (events.loading ?
          <FSLoading type='small' />
          :
          (events.data?.length > 0 ?
            <EventContainer
              events={events.data}
              handleJoinEvent={handleJoinEvent}
            />
            :
            <NoEvents customText={'Üzgünüz, şu anda uygun hiç etkinlik yok.'} />
          )
        )}

      {!Object.values(filter).every((val) => !val) &&
        (filteredData.loading ?
          <FSLoading type='small' />
          :
          (filteredData.data?.length > 0 ?
            <EventContainer
              events={filteredData.data}
              handleJoinEvent={handleJoinEvent}
            />
            :
            <NoEvents />
          )
        )}
    </Wrapper>
  )
}

export default Events;
