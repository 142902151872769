import React from 'react';
import { AppContext } from 'context/AppContext';
import { LanguageContext } from 'context/withLanguages';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search-color.svg';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { DATE_FILTER } from 'helpers';
import {
  Container,
  SectionContainer,
  Arrow,
  OptionWrapper,
  OptionContainer,
  OptionCircle,
  OptionText
} from './style';

const MENU_TYPES = {
  SECTION: 'section',
  DATE: 'date',
}

const MenuSection = ({
  options,
  selectOption,
  selected,
  isActive
}) => {
  if (!isActive) {
    return null;
  }
  return (
    <OptionWrapper $isActive={isActive}>
      {options?.map((option, idx) => (
        <OptionContainer
          key={`mobile_opt_${option?.value || idx}`}
          onClick={() => selectOption(option)}
          $selected={option.value === selected?.value}
        >
          <OptionCircle $selected={option.value === selected?.value} />
          <OptionText>{option.label}</OptionText>
        </OptionContainer>
      ))}
    </OptionWrapper>
  )
};

const FilterMenu = ({
  handleFilterChange,
  filter,
  closeMenu
}) => {
  const { t } = React.useContext(LanguageContext);
  const [selected, setSelected] = React.useState(null);
  const { appState, getCategories, getEventTypesData } = React.useContext(AppContext);

  React.useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div>
        <TextInput
          placeholder={t('words.search_event')}
          inputStyle={{ height: '40px', paddingLeft: '40px' }}
          leftIcon={() => <SearchIcon />}
          iconStyle={{ top: '17px' }}
          fullWidth
          containerStyle={{ marginBottom: 0 }}
          onChange={e => handleFilterChange('search', e.target.value)}
          value={filter.search}
        />
      </div>
      <div>
        <SectionContainer
          onClick={() => setSelected(prev => prev === MENU_TYPES.SECTION ? null : MENU_TYPES.SECTION)}
        >
          <p>{t('words.terapatik_area')}</p>
          <Arrow $isActive={selected === MENU_TYPES.SECTION} />
        </SectionContainer>
        <MenuSection
          options={appState.categories.data && appState.categories.data.map((category) => ({
            value: category.id,
            label: category.name
          }))
          }
          selectOption={(option) => filter.category?.value !== option?.value ? handleFilterChange('category', option) : handleFilterChange('category', '')}
          selected={filter.category}
          isActive={selected === MENU_TYPES.SECTION}
        />
      </div>

      <div>
        <SectionContainer
          onClick={() => setSelected(prev => prev === MENU_TYPES.EVENT_TYPE ? null : MENU_TYPES.EVENT_TYPE)}
        >
          <p>{t('words.event_type')}</p>
          <Arrow $isActive={selected === MENU_TYPES.EVENT_TYPE} />
        </SectionContainer>
        <MenuSection
          options={getEventTypesData().map((event) => ({
            value: event.id,
            label: event.name
          }))
          }
          selectOption={(option) => filter.event_type?.value !== option?.value ? handleFilterChange('event_type', option) : handleFilterChange('event_type', '')}
          selected={filter.event_type}
          isActive={selected === MENU_TYPES.EVENT_TYPE}
        />
      </div>

      <div>
        <SectionContainer
          onClick={() => setSelected(prev => prev === MENU_TYPES.DATE ? null : MENU_TYPES.DATE)}
        >
          <p>{t('words.event_time')}</p>
          <Arrow $isActive={selected === MENU_TYPES.DATE} />
        </SectionContainer>
        <MenuSection
          // options={[{ value: '1', label: 'date 1' }, { value: '2', label: 'date 2' }]}
          options={Object.keys(DATE_FILTER).map((date) => ({
            value: date,
            label: t(`words.${DATE_FILTER[date]}`)
          }))}
          isActive={selected === MENU_TYPES.DATE}
          selected={filter.date}
          selectOption={(option) => filter.date?.value !== option?.value ? handleFilterChange('date', option) : handleFilterChange('date', '')}
        />
      </div>

      <Button
        title={'Apply'}
        onClick={() => closeMenu()}
        size={'lg'}
        fullSize
      />
    </Container>
  )
};

export default FilterMenu;
