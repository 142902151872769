import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import Button from 'components/Button';
import { eventFormatDateStartEnd, slugify, EVENT_JOIN_STATUS, getEventParticipantTypeStyle } from 'helpers';
import ImagePlaceHolder from 'assets/img/images/image-placeholder.svg';
import {
  Card,
  Container,
  Content,
  Image,
  BannerImg,
  Title,
  Description,
  EventDetailArea,
  DateText,
  DetailArea,
  DetailBtn,
  EventNotAvailableText
} from './style';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';

const EventCard = ({
  id,
  title,
  logo,
  banner,
  description,
  startDate,
  endDate,
  eventType,
  event_participant,
  handleJoinEvent,
  is_past,
  is_active,
  is_watchable
}) => {
  const { t, language } = React.useContext(LanguageContext);
  const nav = useNavigate();

  const handleJoin = async () => {
    if (is_past || !is_active) {
      return;
    }
    const payload = {
      id,
      title,
      startDate,
      endDate,
      event_participant
    }
    await handleJoinEvent(payload);
  };

  const generateUrl = () => {
    const titleSlug = slugify(title || '');
    return `/events/${titleSlug + '-' + id}`;
  };

  const getJoinStatusStyle = (type) => {
    if (is_past || !is_active) {
      return getEventParticipantTypeStyle('passive');
    };
    switch (type) {
      case EVENT_JOIN_STATUS.APPROVE.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.APPROVE.key);
      case EVENT_JOIN_STATUS.PENDING.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.PENDING.key);
      case EVENT_JOIN_STATUS.REJECT.key:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.REJECT.key);
      default:
        return getEventParticipantTypeStyle(EVENT_JOIN_STATUS.JOIN.key);
    }
  };

  const handleWatchEvent = () => {
    const titleSlug = slugify(title || '') + '-' + id;
    nav(PATHS.STAGE_AREA.split(':')[0] + `${titleSlug}`);
  };

  const renderJoinButton = () => {
    if (is_past) {
      return <EventNotAvailableText>Bu etkinlik sona ermiştir</EventNotAvailableText>
    }
    switch (event_participant?.is_approved) {
      case EVENT_JOIN_STATUS.APPROVE.key:
        return <Button
          title={t(`event_status.${EVENT_JOIN_STATUS.APPROVE.value}`)}
          onClick={handleJoin}
          size={'sm'}
          // containerStyle={{ backgroundColor: getEventParticipantTypeStyle(EVENT_JOIN_STATUS.APPROVE.key).color }}
          containerStyle={{ backgroundColor: getJoinStatusStyle(EVENT_JOIN_STATUS.APPROVE.key).color }}
        />;
      case EVENT_JOIN_STATUS.PENDING.key:
        return <Button
          title={t(`event_status.${EVENT_JOIN_STATUS.PENDING.value}`)}
          onClick={handleJoin}
          size={'sm'}
          // containerStyle={{ backgroundColor: getEventParticipantTypeStyle(EVENT_JOIN_STATUS.PENDING.key).color }}
          containerStyle={{ backgroundColor: getJoinStatusStyle(EVENT_JOIN_STATUS.PENDING.key).color }}
        />;
      case EVENT_JOIN_STATUS.REJECT.key:
        return <Button
          title={t(`event_status.${EVENT_JOIN_STATUS.REJECT.value}`)}
          onClick={handleJoin}
          size={'sm'}
          // containerStyle={{ backgroundColor: getEventParticipantTypeStyle(EVENT_JOIN_STATUS.REJECT.key).color }}
          containerStyle={{ backgroundColor: getJoinStatusStyle(EVENT_JOIN_STATUS.REJECT.key).color }}
        />;
      default:
        return <Button
          title={t(`event_status.${EVENT_JOIN_STATUS.JOIN.value}`)}
          onClick={handleJoin}
          size={'sm'}
          // containerStyle={{ backgroundColor: getEventParticipantTypeStyle(EVENT_JOIN_STATUS.JOIN.key).color }}
          containerStyle={{ backgroundColor: getJoinStatusStyle(EVENT_JOIN_STATUS.JOIN.key).color }}
        />;
    }
  };

  return (
    <Card>
      <Container>
        <BannerImg
          src={banner}
          alt={title}
          onError={(e) => {
            e.onerror = null;
            e.target.src = ImagePlaceHolder;
            e.target.style.objectFit = 'scale-down';
            e.target.style.border = '1.5625px solid #B0B7C3';
            e.target.style.padding = '0';
          }}
        />

        <Content>
          <Image
            $width={'fit-content'}
            $maxWidth={'30%'}
            $height={'25px'}
            src={logo}
            alt={title}
            onError={(e) => {
              e.onerror = null;
              e.target.src = ImagePlaceHolder;
            }}
          />
          <Title title={title}>{title}</Title>

          <Description title={description}>{description}</Description>

          <DateText>{eventFormatDateStartEnd(startDate, endDate, language)}</DateText>

          <EventDetailArea>
            {is_watchable ?
              <Button
                title={'Etkinliği İzle'}
                onClick={handleWatchEvent}
                size={'sm'}
                containerStyle={{ backgroundColor: '#D93748' }}
                hasPulseAnimation={true}
                pulseColor={'#D93748'}
              />
              :
              renderJoinButton()
            }
            {/* {eventType + ' •\u00A0'} */}
            <DetailArea>
              {eventType + ' •\u00A0'}
              <DetailBtn to={generateUrl()}>{t('words.detail')}</DetailBtn>
            </DetailArea>
          </EventDetailArea>
        </Content>
      </Container>

    </Card>
  )
};

export default EventCard;
