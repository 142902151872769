import React from 'react';
import TextInput from 'components/TextInput';
import CheckBox from 'components/CheckBox';
import { ReactComponent as EyeNoIcon } from 'assets/img/icons/eye-no.svg';
import { ReactComponent as EyeYesIcon } from 'assets/img/icons/eye-yes.svg';
import { ReactComponent as StatusIcon } from 'assets/img/icons/ok-circle.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  Wrapper, Title,
  SubDescription,
  TextSection,
  SubTitle,
  ConsentWarning,
  IqvaConsent
} from '../commonStyle';
import { useDimensions } from 'hooks/useDimensions';

const IQVA_LINK = process.env.REACT_APP_IQVA;

// _general_consent
const Password = ({
  user,
  handleChangeText,
  handleConsentChange,
  setConsentDetail,
  openConsentModal
}) => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);
  // const [consentData, setConsentData] = React.useState({});
  const [psVisible, setPsVisible] = React.useState({ ps: false, confirmPs: false });
  // const [contactConsent, setContactConsent] = React.useState(false);

  // React.useEffect(() => {
  //   setConsentData(user.consents);
  // }, [])

  // React.useEffect(() => {
  //   if (contactConsent) {
  //     consentData.forEach((consent) => {
  //       if (['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key)) {
  //         consent.is_checked = true;
  //       }
  //     });
  //     setConsentData(JSON.parse(JSON.stringify(consentData)));
  //   }
  // }, [contactConsent])

  const propagation = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const generalConsentCheck = (c_key) => {
    if (!['_Phone_Use', '_SMS_Use', '_Email_Use', '_general_consent'].includes(c_key)) {
      return false;
    }

    const generals = user.consents.filter((e) => e.key === '_general_consent')[0];

    if (!generals.is_checked) {
      user.consents.forEach((consent) => {
        if (['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key)) {
          consent.is_checked = false;
        }
      });
      if (['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(c_key)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Wrapper>
      <Title
        dangerouslySetInnerHTML={{ __html: t('pages.register.ps_info.title') }}
      />
      <SubDescription>{t('pages.register.ps_info.subdescription')}</SubDescription>

      <TextSection $sx={{ marginTop: '48px' }}>
        <TextInput
          label={t('pages.register.ps_info.ps')}
          placeholder={t('pages.register.ps_info.ps_placeholder')}
          value={user.password.value}
          onChange={e => handleChangeText('password', e.target.value)}
          type={psVisible.ps ? 'text' : 'password'}
          rightIcon={() => !psVisible.ps ? <EyeNoIcon /> : <EyeYesIcon />}
          rightIconClick={() => setPsVisible({ ...psVisible, ps: !psVisible.ps })}
          error={user.password.error}
          name={'new-password'}
          autoComplete={'new-password'}
          fullWidth
          containerStyle={dims.isMobile ? {} : { width: '100%', marginRight: '24px' }}

        />
        <TextInput
          label={t('pages.register.ps_info.ps_confirm')}
          placeholder={t('pages.register.ps_info.ps_confirm_placeholder')}
          value={user.passwordConfirm.value}
          onChange={e => handleChangeText('passwordConfirm', e.target.value)}
          type={psVisible.confirmPs ? 'text' : 'password'}
          rightIcon={() => !psVisible.confirmPs ? <EyeNoIcon /> : <EyeYesIcon />}
          rightIconClick={() => setPsVisible({ ...psVisible, confirmPs: !psVisible.confirmPs })}
          error={user.passwordConfirm.error}
          name={'new-password'}
          autoComplete={'new-password'}
          fullWidth
        />
      </TextSection>

      <SubTitle $sx={{ marginTop: '13px' }}>{t('pages.register.ps_info.legal_text')}</SubTitle>
      {user?.is_iqva && (
        <React.Fragment>
          <ConsentWarning>Daha önce vermiş olduğunuz izinler, aşağıda listelenmiştir.
            <span style={{ cursor: 'pointer' }} onClick={() => window.open(IQVA_LINK, '_blank')}>
              <b> Bu izinleri Gilead Onam platformundan güncelleyebilirsiniz.</b>
            </span>
          </ConsentWarning>
        </React.Fragment>
      )}
      {/* Rights */}
      {user?.is_iqva && (
        user.consents.map((consent, index) => (
          <IqvaConsent
            $isChecked={consent.is_checked}
            $containerStyle={dims.isMobile ? {
              paddingRight: '15px',
              paddingLeft: ['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key) && '31px',
            } : {
              paddingLeft: ['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key) && '31px'
            }}
          >
            <StatusIcon />
            <span>{consent.title}
              {consent.text && (<span
                style={{ color: '#0072c6', cursor: 'pointer', marginLeft: '5px', fontSize: '12px' }}
                onClick={(e) => { propagation(e); setConsentDetail(consent.text); openConsentModal?.(); }}
              >detay</span>)}
            </span>
          </IqvaConsent>
        ))
      )}
      {!user?.is_iqva && user.consents.map((consent, index) => (
        <CheckBox
          key={consent.key}
          checked={consent.is_checked}
          setChecked={e => {
            handleConsentChange(consent.key, e)
          }}
          // disabled={consent.key === '_general_consent' && !consent.is_checked}
          disabled={generalConsentCheck(consent.key) || user?.is_iqva}
          containerStyle={dims.isMobile ? {
            paddingRight: '15px',
            paddingLeft: ['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key) && '31px',
          } : {
            paddingLeft: ['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(consent.key) && '31px'
          }}
          label={<span>{consent.title}
            {consent.text && <span
              style={{ color: '#0072c6', cursor: 'pointer', marginLeft: '5px', fontSize: '12px' }}
              onClick={(e) => { propagation(e); setConsentDetail(consent.text); openConsentModal?.(); }}
            >detay</span>}
          </span>}
          // disabled={!consent.isEditable}
          // containerStyle={dims.isMobile ? { paddingRight: '15px' } : {}}
          error={consent.error}
        />
      ))}
    </Wrapper>
  )
};

export default Password;
