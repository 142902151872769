import styled from 'styled-components';

export const Wrapper = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  /* width: 680px; */
  width: calc(100% - 240px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  transition-property: width,height;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  /* @media only screen and ((max-width: ${({ theme }) => theme.breakpoints.md}px) or (max-width: 1200px)) { */
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    grid-gap: 10px;
  }
`;
