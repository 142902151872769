import styled from "styled-components";
import { Link } from "react-router-dom";

export const Card = styled.div`
  width: 448px;
  /* height: 226px; */
  height: 240px;
  background: #FFFFFF;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin-right: 25px;
  padding: 16px 12px;
  scroll-snap-align: start;
  align-items: center;
  /* padding: 16px 12px 16px 11px; */
  padding: 16px 12px 0 11px;
`;

export const EventHeader = styled.div`
  display: flex;
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const Image = styled.img`
  max-width: ${({ $maxWidth }) => $maxWidth};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: 4px;
  user-select: none;
  ${({ $border }) => $border && 'border: 0.5px solid #B0B7C3'}
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #203662;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #54565B;
  /* white-space: break-spaces; */
  margin-top: 11px;
  /* margin-bottom: 23px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const EventDetailArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 23px;
`;

export const InfoArea = styled.span`
  display: flex;
  align-items: center;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #0064A8;
  width: 100%;
  justify-content: flex-end;
`;

export const DateText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #54565B;
  width: 100%;
`;

export const DetailBtn = styled(Link)`
  background-color: transparent;
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #0064A8;
  cursor: pointer;
`;

// export const DetailBtn = styled.button`
//   outline: none;
//   border: none;
//   background-color: transparent;
//   user-select: none;
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 13px;
//   line-height: 120%;
//   text-align: center;
//   letter-spacing: 0.005em;
//   color: #0064A8;
//   cursor: pointer;
// `;
