import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 24px;
  padding-right: 24px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0;
    padding-right: 12px;
    padding-bottom: 30px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const StartEditButton = styled.button`
  outline: none;
  border: none;
  background-color: ${({ $isActive }) => $isActive ? '#0064A8' : '#FAFBFC'};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-left: 10px;
  margin-right: 10px;
  svg  {
    width: 24px;
    height: 24px;
  }
  svg > g > path {
    fill: ${({ $isActive }) => $isActive && '#ffff'}
  };

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 6px;
    svg {
      width: 20px;
      height: 20px;
    }
    margin-left: 8px;
    border-radius: 12px;
  }
`;

export const ProfileSettings = styled.div`
  padding-bottom: 24px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  & > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: column;
      & > * {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;

export const SubTitleArea = styled.div`
  display: -webkit-box;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding-block: 36px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0;
    padding-bottom: 24px;
  }
`;

export const SubTitle = styled.h3`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 120%;
  }
`;

export const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: rgba(233, 234, 236, 0.6);
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-top: 36px; */

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    justify-content: space-between;
    margin-bottom: 24px;
  }
`;

export const MasterSection = styled.div`
  /* display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  } */
  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    justify-content: space-between;
  } */
`;

export const SectionArea = styled.div`
  /* max-width: 50%; */
`;

export const PermissionText = styled.span`
  width: 98%;
  cursor: inherit;
  
  margin-bottom: 20px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  display: block;

  ${({ $indent }) => $indent && css`
    padding-left: 31px;
  `}

  *:where(a, button) {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-decoration: underline;
    cursor: pointer;
    color: #0064A8;
  }

  *:where(button) {
    outline: none;
    border: none;
    background-color: transparent;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    *:where(a, button) {
      font-size: 14px;
    }
  }

  svg {
    margin-right: 5px;
  }

  ${({ $isChecked }) => !$isChecked && css`
    svg {
      path {
        fill: #B0B7C3;
      }
    }
  `};
`;

export const InfoText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #203662;
  margin-top: 6px;
`;
