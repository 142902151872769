import React from 'react';
import Seo from 'helpers/Seo';
import Modal from 'components/Modal';
import FSLoading from 'components/FSLoading';
import { PATHS } from 'routes/paths';
import { LanguageContext } from 'context/withLanguages';
import { EventContext } from 'context/EventContext';
import { ReactComponent as BackIcon } from 'assets/img/icons/back.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { slugify } from 'helpers';
import { ReactComponent as QuestionIcon } from 'assets/img/icons/question.svg';
import { ReactComponent as DetailIcon } from 'assets/img/icons/info.svg';
import Meet from './components/Meet';
import AskQuestion from './components/AskQuestion';
import QuestionModal from './components/QuestionModal';
import {
  Wrapper,
  BackButton,
  Container,
  TopContainer,
  Title,
  QAModalTitleContainer,
  DetailButton,
  LoadingWrapper
} from './style';

const StageArea = () => {
  const nav = useNavigate();
  const qaModalRef = React.useRef(null);
  const { slug } = useParams();
  const { t } = React.useContext(LanguageContext);
  const { getSingleEvent, watchEvent, sendWatchedStatus } = React.useContext(EventContext);
  const [event, setEvent] = React.useState({ loaded: false, data: null, error: null });

  const parseSlugToId = (text) => {
    const textArr = text.split('-');
    const id = textArr[textArr.length - 1];
    return id;
  };

  const getEvent = async () => {
    try {
      // const eventId = parseSlugToId(slug);
      // const data = await getSingleEvent({ id: eventId })
      const data = await watchEvent(parseSlugToId(slug));
      console.log('watch data', data);
      setEvent({ ...event, loaded: true, data });

      console.log('data::', data);
      const slugText = slugify(`${data?.title}-${data?.id}`);
      if (slugText !== window.location.pathname.split('/')[2]) {
        window.history.replaceState(null, null, window.location.search ? slugText + window.location.search : slugText);
      }

      setTimeout(() => {
        sendWatchedStatus(data?.event_participant?.id);
      }, 2000);

    } catch (error) {
      // setEvent({ ...event, loaded: true, error: 'Event not found' });
      setEvent({ ...event, loaded: true, error });
      console.log('getEvent :: error', error);
    }
  };

  React.useEffect(() => {
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    nav(PATHS.EVENTS);
  };

  if (!event.loaded) {
    return <LoadingWrapper>
      <FSLoading type='small' />
    </LoadingWrapper>;
  };

  if (event.error) {
    return (
      <Wrapper>
        <Seo
          title={`${slug}`}
        />
        <BackButton onClick={handleBack}>
          <BackIcon />
          {t('words.back')}
        </BackButton>

        <Container>
          <Title>{event.error}</Title>
        </Container>
      </Wrapper>
    );
  };
  return (
    <Wrapper>
      <Seo title={`${slug}`} />
      <Modal
        ref={qaModalRef}
        size={'md'}
        customTitle={
          <QAModalTitleContainer>
            <QuestionIcon />
            {t('words.ask_question')}
          </QAModalTitleContainer>}
        mobileHeightRatio={0}
        isCloseOutsideClick={false}
      >
        <QuestionModal
          closeModal={() => qaModalRef.current.closeModal?.()}
          eventId={event.data.id}
        />
      </Modal>
      <BackButton onClick={handleBack}>
        <BackIcon />
        {t('words.back')}
      </BackButton>

      <Container>
        <TopContainer>
          <Title>
            {event.data.title}
          </Title>
          {event.data.url_type !== 'zoom-sdk' &&
            <AskQuestion
              openModal={() => qaModalRef.current.openModal?.()}
            />
          }
        </TopContainer>
        <Meet
          title={event.data.title}
          src={event.data.url}
          urlType={event.data.url_type}
          urlInfo={event.data.url_info}
          eventId={parseSlugToId(slug)}
          height='100%'
          width='100%'
        />
      </Container>
      {/* <AskQuestion /> */}
    </Wrapper>
  )
};

export default StageArea;
