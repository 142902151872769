import React from 'react';
import { inputValidate } from 'helpers/validate';
import { LanguageContext } from 'context/withLanguages';
import OneTimeCode from 'components/OneTimeCode';
import Button from 'components/Button';
import ErrorContainer from 'components/ErrorContainer';
import {
  Wrapper,
  Timer,
  Title,
  SubText,
  CodeContainer,
  Text
} from './style';

const TIMER_COUNT = 180;
let counter = null;

const CodeModal = ({
  closeModal,
  email,
  handleCodeSuccess,
  resendCode,
}) => {
  const { t } = React.useContext(LanguageContext);
  const [otp, setOtp] = React.useState('');
  const [timer, setTimer] = React.useState(TIMER_COUNT);
  const [process, setProcess] = React.useState({ loading: '', error: '' });

  React.useEffect(() => {
    startTimer();
    return () => clearInterval(counter);
  }, []);

  const startTimer = () => {
    clearInterval(counter);
    counter = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(counter);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSendCode = async () => {
    setProcess({ loading: true, error: '' });
    if (timer === 0) {
      setProcess({ loading: false, error: '' });
      return;
    }
    if (!!inputValidate(otp.trim(), 4, 'otp')) {
      setProcess({ loading: false, error: 'Lütfen e-posta adresinize gönderilen 4 haneli doğrulama kodunu giriniz' });
      return;
    }
    try {
      await handleCodeSuccess?.(otp);
      closeModal?.();
    } catch (error) {
      setProcess({ loading: false, error: error });
    }
  };

  const handleResendCode = async () => {
    if (timer > 0) {
      return;
    }
    try {
      await resendCode?.();
      setTimer(TIMER_COUNT);
      startTimer();
    } catch (error) {
      console.log('error', error);
    }
  };

  const renderTitle = React.useMemo(() => {
    const text = t('pages.forgot_password.code_modal.mail_message', { email });
    return text.replace(email, `<span style="color: #0064A8; padding: 0; font-weight: 600">${email}</span>`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <Wrapper>
      <Timer>{timer}</Timer>
      <Title dangerouslySetInnerHTML={{ __html: t('pages.forgot_password.code_modal.title') }} />

      <SubText $mb={'10px'} dangerouslySetInnerHTML={{ __html: renderTitle }} />
      <SubText $mb={'51px'}>{t('pages.forgot_password.code_modal.redirect_text')}</SubText>

      <CodeContainer>
        <OneTimeCode
          value={otp}
          valueLength={4}
          onChange={val => { setOtp(val); setProcess({ loading: false, error: '' }) }}
          error={process.error}
          onComplate={handleSendCode}
          containerStyle={{ justifyContent: 'center' }}
        />

        <ErrorContainer message={process.error} />
        <Button
          title={t('pages.forgot_password.code_modal.verify_button')}
          onClick={handleSendCode}
          size={'md'}
          loading={process.loading}
          fullSize
          containerStyle={{ marginBottom: '40px' }}
          disabled={timer === 0}
        />
        <Text>{t('pages.forgot_password.code_modal.is_received')}&nbsp;
          <Text
            onClick={handleResendCode}
            $sx={{
              fontWeight: 700, color: '#0064A8', opacity: (timer !== 0 ? .32 : 1),
              cursor: 'pointer', userSelect: 'none'
            }}>
            {t('pages.forgot_password.code_modal.resend_code')}
          </Text>
        </Text>
      </CodeContainer>

    </Wrapper>
  )
};

export default CodeModal;
