import axios from 'axios';
import { getLocalStorage } from 'helpers';

export const BASE_API = process.env.REACT_APP_BASE_API;
// const defaultLang = getLocalStorage('lang') || 'tr';
const defaultLang = 'tr';

export const withToken = async (config) => {
  const tokens = getLocalStorage('tokens');

  if (tokens?.access_token) {
    config.headers.Authorization = `Bearer ${tokens.access_token}`;
  }
  return config;
};

const _authInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const authInstance = (/* lang = defaultLang */) => {
  const lang = defaultLang;
  _authInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/auth`;
  _authInstance.interceptors.request.use(withToken);
  return _authInstance;
};

const _iqvaInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/iqva`
});

export const iqvaInstance = (/* lang = defaultLang */) => {
  const lang = defaultLang;
  _iqvaInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/iqva`;
  return _iqvaInstance;
};

const _userInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/user`
});

export const userInstance = (/* lang = defaultLang */) => {
  const lang = defaultLang;
  _userInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/user`;
  _userInstance.interceptors.request.use(withToken);
  return _userInstance;
};

export const _appInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const appInstance = ({ path, /* lang = defaultLang */ }) => {
  const lang = defaultLang;
  _appInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/${path}`;
  _appInstance.interceptors.request.use(withToken);
  return _appInstance;
};

const _eventInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const eventInstance = ({ /* lang = defaultLang, */ q }) => {
  const lang = defaultLang;
  // _eventInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/event` + (q ? `?${q}` : '');
  _eventInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/event`;
  _eventInstance.interceptors.request.use(withToken);
  return _eventInstance;
};

const _eventParticipantInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const eventParticipantInstance = ({ /* lang = defaultLang, */ q }) => {
  const lang = defaultLang;
  _eventParticipantInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/event-participant`;
  _eventParticipantInstance.interceptors.request.use(withToken);
  return _eventParticipantInstance;
};

const _eventPathInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const eventMasterInstance = ({ path = "event", /* lang = defaultLang */ }) => {
  const lang = defaultLang;
  _eventPathInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/${path}`;
  _eventPathInstance.interceptors.request.use(withToken);
  return _eventPathInstance;
};

export const _dashboardInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/dashboard`
});

export const dashboardInstance = ({/* lang = defaultLang */ }) => {
  const lang = defaultLang;
  _dashboardInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/dashboard`;
  _dashboardInstance.interceptors.request.use(withToken);
  return _dashboardInstance;
};

const _contentInstance = axios.create({
  baseURL: `${BASE_API}/api/v1/${defaultLang}/auth`
});

export const contentInstance = ({ /* lang = defaultLang, */ }) => {
  const lang = defaultLang;
  _contentInstance.defaults.baseURL = `${BASE_API}/api/v1/${lang}/content`;
  _contentInstance.interceptors.request.use(withToken);
  return _contentInstance;
};
