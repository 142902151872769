import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Seo from 'helpers/Seo';
import { validatePassword, confirmPasswordValidate } from 'helpers/validate';
import { LanguageContext } from 'context/withLanguages';
import { resetPasswordWCode } from 'services/authService';
import { parseQuery } from 'helpers';
import { useDimensions } from 'hooks/useDimensions';
import { ReactComponent as EyeNoIcon } from 'assets/img/icons/eye-no.svg';
import { ReactComponent as EyeYesIcon } from 'assets/img/icons/eye-yes.svg';
import { ReactComponent as BackIcon } from 'assets/img/icons/arrow.svg';
import { ReactComponent as XCIcon } from 'assets/img/icons/x-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/ok-circle.svg';
import { PATHS } from 'routes/paths';
import TextInput from 'components/TextInput';
import Modal from 'components/Modal';
import Button from 'components/Button';
import SuccessModal from './components/SuccessModal';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  Title,
  SubText,
  CircleContainer,
  BackgroundLine,
  Form,
  BackButtonArea,
  BackButton,
  PasswordInfo,
  PasswordSecurityContainer,
  PasswordSecurity,
  PasswordInfoContext,
  PasswordInfoText
} from './style';

const USER_STATE = {
  email: { value: '', error: '' },
  password: { value: '', error: '' },
  passwordConfirm: { value: '', error: '' },
};

const PASSWORD_SECURITY = {
  LENGTH: false,
  LOWER_CASE: false,
  UPPER_CASE: false,
  DIGIT: false,
};

// const PASSWORD_SECURITY_TEXT = {
//   LENGTH: 'Your password must be at least 6 digits long.',
//   LOWER_CASE: 'Must contain lowercase letters.',
//   UPPER_CASE: 'Must contain capital letters.',
//   DIGIT: 'It must contain at least one digit.',
// };

const ChangePassword = () => {
  const { t, language } = React.useContext(LanguageContext);
  const { token } = useParams();
  const dims = useDimensions();
  const nav = useNavigate();
  const modalRef = React.useRef(null);
  const [user, setUser] = React.useState(USER_STATE);
  const [psVisible, setPsVisible] = React.useState({ ps: false, confirmPs: false })
  const [psSecurity, setPsSecurity] = React.useState(PASSWORD_SECURITY)
  const [process, setProcess] = React.useState({ loading: false, error: '' });

  React.useEffect(() => {
    const parsedQuery = parseQuery(window.location.search);
    if (parsedQuery?.email) {
      setUser({ ...user, email: { value: parsedQuery.email, error: '' } });
      window.history.replaceState({}, '', window.location.pathname);
    } else {
      nav(PATHS.FORGOT_PASSWORD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const error = validatePassword(user.password.value)
    setPsSecurity({ ...error })
  }, [user.password.value])

  const handleBack = () => {
    nav(PATHS.LOGIN + `?email=${user.email.value}`);
  };

  const handleChangeText = (key, value) => {
    setUser({ ...user, [key]: { ...user[key], value, error: '' } });
  };

  const handleResetPassword = async () => {
    setProcess({ loading: true, error: '' });
    const hasPass = user.password.value?.toString?.().length < 6;
    const hasRePass = user.passwordConfirm.value?.toString?.().length < 6;
    if (hasPass || hasRePass) {
      setUser({
        ...user,
        password: { ...user.password, error: hasPass },
        passwordConfirm: { ...user.passwordConfirm, error: hasRePass }
      });
      setProcess({ loading: false, error: '' });
      return;
    }

    const psError = confirmPasswordValidate(user.password.value, user.passwordConfirm.value)
    if (psError ||
      !psSecurity.LENGTH ||
      !psSecurity.LOWER_CASE ||
      !psSecurity.UPPER_CASE ||
      !psSecurity.DIGIT) {
      setUser({ ...user, passwordConfirm: { ...user.passwordConfirm, error: psError && t(`validation_errors.${psError}`) } });
      setProcess({ loading: false, error: '' });
      return;
    }

    try {
      await resetPasswordWCode({
        email: user.email.value,
        password: user.password.value,
        code: token,
        lang: language.code
      });
      setProcess({ loading: false, error: '' });
      modalRef.current.openModal();
    } catch (error) {
      setProcess({ loading: false, error: error });
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, x: window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: window.innerWidth }}
    >
      <Seo title={'Change Password'} />

      <Modal
        ref={modalRef}
        title={t('words.congratulations')}
        size={'md'}
        mobileHeightRatio={0}
        onClose={() => nav(PATHS.LOGIN + `?email=${user.email.value}`)}
        isCloseOutsideClick={false}
        showCloseButton={false}
      >
        <SuccessModal
          navigate={() => nav(PATHS.LOGIN + `?email=${user.email.value}`)}
        />
      </Modal>

      <LeftContainer>

        <BackButtonArea onClick={handleBack} >
          <BackButton>
            <BackIcon />
          </BackButton>
          {t('pages.change_password.welcome_back')}
        </BackButtonArea>

        <CircleContainer>
          <BackgroundLine $size={'100px'} />
          <BackgroundLine $size={'220px'} />
          <BackgroundLine $size={'320px'} />
          <BackgroundLine $size={'420px'} />
          <BackgroundLine $size={'520px'} />
          <BackgroundLine $size={'620px'} />
          <BackgroundLine $size={'720px'} />
          <BackgroundLine $size={'820px'} />
          <BackgroundLine $size={'920px'} />
        </CircleContainer>
        <Form>
          <Title>{t('pages.change_password.title')}</Title>
          <SubText>{t('pages.change_password.sub_text')}</SubText>

          <TextInput
            type={'email'}
            name={'email'}
            label={t('labels.email')}
            placeholder={t('labels.email_placeholder')}
            value={user.email.value}
            onChange={e => handleChangeText('email', e.target.value)}
            error={user.email.error}
            fullWidth
            autocomplete={'email'}
            disabled
          />

          <TextInput
            label={t('pages.change_password.new_ps')}
            placeholder={t('pages.change_password.re_ps_placeholder')}
            value={user.password.value}
            onChange={e => handleChangeText('password', e.target.value)}
            type={psVisible.ps ? 'text' : 'password'}
            rightIcon={() => !psVisible.ps ? <EyeNoIcon /> : <EyeYesIcon />}
            rightIconClick={() => setPsVisible({ ...psVisible, ps: !psVisible.ps })}
            error={user.password.error}
            name={'new-password'}
            autoComplete={'new-password'}
            fullWidth
            containerStyle={{ marginRight: '24px' }}
          />

          <TextInput
            label={t('pages.change_password.re_password')}
            placeholder={t('pages.change_password.re_ps_placeholder')}
            value={user.passwordConfirm.value}
            onChange={e => handleChangeText('passwordConfirm', e.target.value)}
            type={psVisible.confirmPs ? 'text' : 'password'}
            rightIcon={() => !psVisible.confirmPs ? <EyeNoIcon /> : <EyeYesIcon />}
            rightIconClick={() => setPsVisible({ ...psVisible, confirmPs: !psVisible.confirmPs })}
            error={user.passwordConfirm.error}
            name={'new-password'}
            autoComplete={'new-password'}
            fullWidth
          />

          <PasswordInfo>
            <PasswordSecurityContainer>
              {Object.keys(psSecurity).map((key, index) => (
                <PasswordSecurity $isFine={psSecurity[key]} key={index} />
              ))}
            </PasswordSecurityContainer>

            {Object.keys(psSecurity).map((key, index) => (
              <PasswordInfoContext key={index}>
                {psSecurity[key] ? <CheckIcon /> : <XCIcon />}
                <PasswordInfoText>{t(`pages.change_password.errors.${key}`)}</PasswordInfoText>
              </PasswordInfoContext>
            ))}
          </PasswordInfo>

          <Button
            title={t('pages.change_password.confirm_button')}
            onClick={handleResetPassword}
            loading={process.loading}
            size={'md'}
            containerStyle={dims.isMobile ? { width: '100%', marginTop: '24px' } : { marginBlock: '32px', paddingInline: '40px' }}
          />
        </Form>
      </LeftContainer>

      {!dims.isMobile &&
        <RightContainer />
      }

    </Wrapper>
  )
};

export default ChangePassword;
