import React, { useContext } from "react";
import { Navigate, Outlet, /* useLocation */ } from "react-router-dom";
import { UserContext } from "context/UserContext";
import { AUTH_PATH, PATHS } from "./paths";

const ProtectedRoute = ({
  redirectPath = AUTH_PATH,
  children,
}) => {
  const { user, isAuth } = useContext(UserContext);
  // const location = useLocation();

  if (isAuth &&
    !user?.is_verified &&
    window.location.pathname !== PATHS.VERIFY_ACCOUNT) {
    return <Navigate to={PATHS.VERIFY_ACCOUNT} replace />;
  }


  if (isAuth &&
    user?.is_verified &&
    window.location.pathname === PATHS.VERIFY_ACCOUNT) {
    return <Navigate to={'/'} replace />;
  }

  if (!isAuth) {
    return <Navigate
      to={redirectPath}
      // to={`${redirectPath}${location.pathname.substring(1) ? `?next=/${location.pathname.substring(1)}` : ''}`}
      replace
    />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
