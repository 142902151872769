import React from 'react';
import { ReactComponent as AppLogo } from 'assets/img/logo/logo.svg';
import { ReactComponent as AppFullLogo } from 'assets/img/logo/logo-full.svg';
import { ReactComponent as ProfileIcon } from 'assets/img/icons/nav_menu/profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/img/icons/nav_menu/logout.svg';
import { ReactComponent as MenuIcon } from 'assets/img/icons/menu.svg';
import { Link } from 'react-router-dom';
import { MENU } from 'constants/navMenu';
import { LanguageContext } from 'context/withLanguages';
import { UserContext } from 'context/UserContext';
import {
  Container,
  Content,
  Menu,
  MenuItem,
  NavLink,
  Line,
  SelectContainer,
  LogoutArea,

  MobileContainer,
  HamburgerMenu,
  MobileMenu,
  MobileOverlay,
  MobileNavLink,
  MobileMenuItem,
  MobilePathText,
  MobileExitButton
} from './style';
import { useDimensions } from 'hooks/useDimensions';
// import { useTheme } from 'styled-components'
import { useAnimation } from 'framer-motion';
import { setMenuStatus, useAppControl } from 'context/AppControl';

const overlayStart = {
  opacity: .9,
  transition: 'ease',
  display: 'unset'
};

const overlayStop = {
  opacity: 0,
  transition: 'ease',
  transitionEnd: {
    display: 'none'
  }
};

const SideBar = ({ ...props }, ref) => {
  const [states, dispatch] = useAppControl();
  const { t, getAvailableLanguages, changeLanguage, language } = React.useContext(LanguageContext);
  const { logout: logoutApp } = React.useContext(UserContext);
  const navRef = React.useRef();
  const dims = useDimensions();
  // const theme = useTheme();
  const navAnim = useAnimation();
  const overlayAnim = useAnimation();

  React.useImperativeHandle(ref, () => ({
    getCurrentWidth: () => {
      return navRef.current ? navRef.current['clientWidth'] : '';
    },
    getCurrentHeight: () => {
      return navRef.current ? navRef.current['clientHeight'] : '';
    },
  }));

  const openMenu = () => {
    overlayAnim.start(overlayStart);
    navAnim.start({
      x: 0,
      display: 'unset',
      transition: {
        bounce: 1,
        type: 'tween',
        velocity: 1,
        easings: ["easeOut", "easeIn"],
      },
    });
  };

  const closeMenu = () => {
    navAnim.start({
      transition: {
        // ease: 'easeIn'
        ease: 'easeOut'
      },
      x: '100%',
      transitionEnd: {
        display: 'none'
      },
    });
    overlayAnim.start(overlayStop);
    setMenuStatus(dispatch, false);
  };

  React.useEffect(() => {
    closeMenu();
    // document.getElementById('root').style.overflow = 'unset';
    // document.documentElement.style.overflow = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dims.isMobile])

  React.useEffect(() => {
    if (states.isMenuOpen) {
      // setOpened(true);
      // document.getElementById('root').style.overflow = 'hidden';
      setTimeout(() => {
        openMenu();
      }, 1);
    } else {
      // document.getElementById('root').style.overflow = 'unset';
      closeMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.isMenuOpen]);

  const logout = async () => {
    await logoutApp();
  };

  if (dims.isMobile) {
    return (
      <MobileContainer ref={navRef}>
        <MobileOverlay
          animate={overlayAnim}
          initial={overlayStop}
          onClick={closeMenu}
        />

        <Content>
          <Link to={'/'}>
            <AppFullLogo title='Gilead' />
          </Link>
        </Content>

        <MobileMenu
          initial="hidden"
          variants={{
            visible: {
              x: '0',
              transition: { type: 'tween' },
              display: 'unset'
            },
            hidden: {
              x: '100%',
              transition: { type: 'tween' },
              display: 'none'
            },
          }}
          animate={navAnim}
        >
          <Menu>
            {MENU.map((item, idx) => (
              <MobileMenuItem key={`mobile_nav_menu_${item.key}`} title={item.name} onClick={closeMenu}>
                <MobileNavLink to={item.path} end={true}>
                  <div className='active_bar' />
                  <item.icon title={t(`side_menu.${item.key}`)}
                    className={`nav_icon ${item.iconStyle ? 'custom' : ''}`}
                  />
                  <MobilePathText>{t(`side_menu.${item.key}`)}</MobilePathText>
                </MobileNavLink>
              </MobileMenuItem>
            ))}
            <Line />

            <MobileMenuItem $mr={'0 0 24px 0'}>
              <MobileNavLink to={'/profile'} onClick={closeMenu}>
                <div className='active_bar' />
                <ProfileIcon title={t('side_menu.profile')} className='nav_icon' />
                <MobilePathText>{t('side_menu.profile')}</MobilePathText>
              </MobileNavLink>
            </MobileMenuItem>
            <MobileExitButton>
              <LogoutArea onClick={logout} $fullSize={false}>
                <LogoutIcon title={t('buttons.logout')} className='nav_icon' />
                <MobilePathText $sx={{ color: '#C50F3C', opacity: '.32' }}>{t('side_menu.exit')}</MobilePathText>
              </LogoutArea>
            </MobileExitButton>

            {/* TODO :: passive multi-lang  */}
            {/* <div className='lang-bar'>
              <SelectContainer
                onChange={(e) => changeLanguage(e.target.value)}
                value={language.code}
                $sx={{ marginTop: '32px', width: '50%' }}
              >
                {getAvailableLanguages().map((lang, index) => (
                  <option key={index} value={lang.code}>
                    {lang.code}
                  </option>
                ))}
              </SelectContainer>
            </div> */}
          </Menu>
        </MobileMenu>

        <HamburgerMenu onClick={() => setMenuStatus(dispatch, !states.isMenuOpen)} $isActive={states.isMenuOpen}>
          <MenuIcon />
        </HamburgerMenu>

      </MobileContainer>
    )
  };

  return (
    <Container ref={navRef}>
      <Content>
        <Link to={'/'}>
          <AppLogo title='Gilead' />
        </Link>
        <Menu>
          {MENU.map((item, idx) => (
            <MenuItem key={`nav_menu_${item.key}`} title={item.name}>
              <NavLink to={item.path} end={true}>
                <div className='active_bar' />
                <item.icon
                  title={t(`side_menu.${item.key}`)}
                  className={`nav_icon ${item.iconStyle ? 'custom' : ''}`}
                />
              </NavLink>
            </MenuItem>
          ))}
          <Line />
          {/* <Menu> */}
          <MenuItem $mr={'0 0 24px 0'}>
            <NavLink to={'/profile'}>
              <div className='active_bar' />
              <ProfileIcon title={t('side_menu.profile')} className='nav_icon' />
            </NavLink>
          </MenuItem>
          <li>
            <LogoutArea onClick={logout}>
              <LogoutIcon title={t('buttons.logout')} className='nav_icon' />
            </LogoutArea>
          </li>
        </Menu>
      </Content>

      {/* TODO :: passive multi-lang  */}
      {/* <SelectContainer
        onChange={(e) => {
          changeLanguage(e.target.value);
        }}
        value={language.code}
      >
        {getAvailableLanguages().map((lang, index) => (
          <option key={index} value={lang.code}>
            {lang.code}
          </option>
        ))}
      </SelectContainer> */}
    </Container>
  )
};

export default React.forwardRef(SideBar);
