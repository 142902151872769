import React from 'react';
import propTypes from 'prop-types';
import People from 'assets/img/images/dummy-people.png';
import { formatDateToNumeric } from 'helpers';
import Button from 'components/Button';
import {
  Card,
  SpeakerImg,
  EventDetailContainer,
  Context,
  UpcomingText,
  EventTitleText,
  EventDescriptionText,
  ButtonArea,
  DetailBtn,
} from './style';
import { LanguageContext } from 'context/withLanguages';

const BannerCard = ({
  eventTitle,
  eventDate,
  eventDescription,
}) => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Card>
      <EventDetailContainer>
        <Context>
          <UpcomingText>{t('pages.home.upcoming_event')}&nbsp; <b>{formatDateToNumeric(eventDate)}</b></UpcomingText>
          <EventTitleText>{eventTitle}</EventTitleText>
          <EventDescriptionText>
            {eventDescription}
          </EventDescriptionText>
        </Context>

        <ButtonArea>
          <Button
            title={'Katılmak İstiyorum'}
            containerStyle={{ backgroundColor: '#fff', color: '#203662', marginRight: '26px' }}
          />
          <DetailBtn to={'/#detail'}>Detail</DetailBtn>
        </ButtonArea>
      </EventDetailContainer>
      <SpeakerImg src={People} />
    </Card>
  )
};

BannerCard.propTypes = {
  eventTitle: propTypes.string,
  eventDate: propTypes.string,
  eventDescription: propTypes.string,
};

BannerCard.defaultProps = {
  eventTitle: 'Febril Nötropenik Hastalarda Antifungal Yönetimi ve Ambisome’un Yeri',
  eventDate: new Date().toLocaleDateString('tr-TR', { year: 'numeric', month: 'numeric', day: 'numeric' }).replace(' ', '.'),
  eventDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum`,
};

export default BannerCard;
