import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    text-decoration: none;
    ::selection {
      background-color: #3C587F;
      color: #fff;
    }
  }
  html {
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
    reach-portal > div {
      &>:first-child {
        z-index: 101;
      }
      &>:last-child {
        z-index: 101;
        &>:first-child {
          box-shadow: none;
        }
      }
    }
  }
  textarea {
    :focus {
      outline: none;
    }
  }
  p,h1,h2,h3,h4,a {
    font-family: 'Poppins';
    color: ${theme.colors.primary};
    font-family: Poppins;
  }
  button {
    cursor: pointer;
  }
  img {
    border-style: none;
  }
  .rc-tooltip-inner {
    min-height: unset !important;
  }

  /* Zoom */
  #zmmtg-root {
    overflow: auto !important;
  }
  .audio-option-menu {
    display: none !important;
  }
  .scroll-content {
    display: none !important;
  }
  .chat-header__header {
    flex-direction: column-reverse;
  }
  .chat-header__dropdown-menu{
    list-style-type: none
  }
  .chat-header__menu {
    margin-left: 20px;
  }
  .chat-header__chat-pop-btn {
    display: none;
  }
  .zmu-toast-wrapper {
    display: none;
  }
  .custom-dropdown-menu.dropdown-menu {
    display: none;
  }
`;

export default GlobalStyle;
