import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoIcon } from 'assets/img/logo/logo.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  Wrapper,
  Content,
  LogoContainer,
  WrapperSmall,
  ContentSmall,
} from './style';

const FSLoading = ({
  type,
  sx,
  containerStyle,
  text
}) => {
  const { t } = React.useContext(LanguageContext);
  if (type === 'small') {
    return (
      <WrapperSmall $containerStyle={containerStyle}>
        <ContentSmall>
          <LogoContainer $sx={sx}>
            <LogoIcon />
          </LogoContainer>
          <p>{text ? text : `${t('words.please_wait')}...`}</p>
        </ContentSmall>
      </WrapperSmall>
    )
  }
  return (
    <Wrapper>
      <Content>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        {/* <p>{t('words.please_wait')}...</p> */}
        <p>{text ? text : `${t('words.please_wait')}...`}</p>
      </Content>
    </Wrapper>
  )
};

FSLoading.propTypes = {
  type: PropTypes.oneOf(['small', 'full'])
};

FSLoading.defaultProps = {
  type: 'full'
};

export default FSLoading;
