import React from 'react';
import EventCard from '../EventCard';
import {
  Wrapper
} from './style';

const EventContainer = ({
  events,
  handleJoinEvent
}) => {
  return (
    <Wrapper>
      {events && events.map((event, idx) => (
        <EventCard
          key={`event-${idx}`}
          id={event.id}
          title={event.title}
          logo={event.product.logo}
          banner={event.image}
          description={event.description}
          startDate={event.start_date}
          endDate={event.end_date}
          handleJoinEvent={handleJoinEvent}
          eventType={event?.event_type?.name}
          event_participant={event?.event_participant}
          is_past={event?.is_past}
          is_active={event?.is_active}
          is_watchable={event?.is_watchable}
        />
      ))}
    </Wrapper>
  )
};

export default EventContainer;
