import { authInstance, iqvaInstance } from './http';
import errorHandler from './errorHandler';
import { getDeviceId } from 'helpers';
// import { getLocalStorage, setLocalStorage } from 'helpers';

export const authLogin = async ({ email, password, lang }) => {
  try {
    const payload = {
      email,
      password,
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang).post(`/login`, payload, { headers: { 'x-panel': 'client' } });
    return data;
  } catch (error) {
    const err = errorHandler(error);
    const payload = { errorText: err, error };
    throw payload;
  }
};

export const authLogout = async ({ lang }) => {
  try {
    const payload = {
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang).post(`/logout`, payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const refreshToken = async ({ refresh_token, lang }) => {
  try {
    const payload = {
      refresh_token,
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang).post('/refresh/tokens', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const userRegister = async ({ lang, user }) => {
  console.log('consents', user.consents);

  // iqva_consent: {
  //   _sms: true,
  //   _phone: false
  // }
  const consentData = {};
  user.consents.forEach((consent) => {
    consentData[consent.key] = consent.is_checked;
  });

  try {
    const payload = {
      email: user.email,
      password: user.password,
      userProfile: {
        name_surname: user.name,
        tckn: user.idNumber,
        gsm: user.phone,
        branch: user.branch,
        title: user.title,
        institution: user.institution,
        city: user.institutionCity,
        iqva_consent: consentData
      }
    };
    const { data } = await authInstance(lang).post('/register', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const verifyUserWithOTP = async ({ lang, email, code }) => {
  try {
    const payload = {
      email: email,
      verification_code: code
    };
    const { data } = await authInstance(lang).post('/verify/user', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const resendVerificationCode = async ({ lang, email }) => {
  try {
    const payload = {
      email: email
    };
    const { data } = await authInstance(lang).post('/send/verification/code', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const forgotPasswordSendCode = async ({ lang, email }) => {
  try {
    const payload = {
      email,
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang)
      .post('/forgot/password', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const forgotPasswordValidateCode = async ({ lang, email, code }) => {
  try {
    const payload = {
      email,
      code,
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang)
      .post('/forgot/password/validation', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const resetPasswordWCode = async ({ lang, email, code, password }) => {
  try {
    const payload = {
      email,
      code,
      password,
      device_uuid: getDeviceId()
    };
    const { data } = await authInstance(lang)
      .post('/reset/password', payload);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const checkMailAndIqva = async ({ lang, email }) => {
  try {
    const { data } = await iqvaInstance(lang).post('/check-email', { email });
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};
