import React from 'react';
import Approved from './components/Approved';
import Pending from './components/Pending';

// Pending
// Approved
const SuccessfulRegister = ({
  type,
  email
}) => {
  const renderType = () => {
    switch (type) {
      case 'Approved':
        return <Approved email={email} />;
      case 'Pending':
        return <Pending email={email} />;
      default:
        return <Pending email={email} />;
    }
  };

  const memoizedValue = React.useMemo(renderType, [email, type]);

  return (
    memoizedValue
  )
};

export default SuccessfulRegister;
