import React from 'react';
import Button from 'components/Button';
import { ReactComponent as PsIcon } from 'assets/img/icons/password.svg';
import {
  Container,
  IconArea,
  Title,
  ButtonsContainer
} from './style';

const SuccessModal = ({
  navigate,
}) => {

  return (
    <Container>
      <IconArea>
        <PsIcon />
      </IconArea>

      <Title>Parolanız başarıyla değiştirilmiştir</Title>

      {/* <Description></Description> */}
      <ButtonsContainer>
        <Button
          fullSize
          size={'md'}
          title={'Giriş Yap'}
          onClick={navigate}
        />
      </ButtonsContainer>
    </Container>
  )
};

export default SuccessModal;
