import React from 'react';
import TextInput from 'components/TextInput';
import Dropdown from 'components/Dropdown';
import { Title, SubDescription, TextSection, Wrapper } from '../commonStyle';
import { LanguageContext } from 'context/withLanguages';
import { AppContext } from 'context/AppContext';
import { useDimensions } from 'hooks/useDimensions';

const JobInfo = ({
  user,
  handleChangeText
}) => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);
  const { appState, getCitiesData, getBranchesData, getTitlesData } = React.useContext(AppContext);

  return (
    <Wrapper>
      {/* <Title><b>Tell us about</b> your company</Title> */}
      <Title
        dangerouslySetInnerHTML={{ __html: t('pages.register.job_info.title') }}
      />
      {/* <SubDescription>Lütfen mesleki bilgilerinizi ve bulunduğunuz ili giriniz.</SubDescription> */}
      <SubDescription>{t('pages.register.job_info.subdescription')}</SubDescription>

      <TextSection $sx={dims.isMobile ? { marginTop: '24px' } : { marginTop: '48px' }}>
        <Dropdown
          title={t('pages.register.job_info.select_branch')}
          placeholder={t('pages.register.job_info.branch')}
          options={getBranchesData().map(branch => ({
            value: branch.id,
            label: branch.name
          }))}
          isLoading={appState.branches.loading}
          onChange={e => handleChangeText('branch', e)}
          containerStyle={dims.isMobile ? { marginBottom: '0' } : { marginRight: '24px' }}
          fullWidth
          error={user.branch.error}
          hasTop
        />

        <Dropdown
          title={t('pages.register.job_info.job_title')}
          placeholder={t('pages.register.job_info.select_job_title')}
          options={getTitlesData().map(title => ({
            value: title.id,
            label: title.name
          }))}
          isLoading={appState.titles.loading}
          onChange={e => handleChangeText('title', e)}
          fullWidth
          error={user.title.error}
          hasTop
          containerStyle={dims.isMobile && { marginBottom: '0' }}
        />

      </TextSection>

      <TextSection $sx={dims.isMobile ? { alignItems: 'unset' } : { marginTop: '17px', alignItems: 'unset' }}>
        <TextInput
          type={'text'}
          label={t('pages.register.job_info.institution')}
          placeholder={t('pages.register.job_info.institution_placeholder')}
          value={user.institution.value}
          onChange={e => handleChangeText('institution', e.target.value)}
          error={user.institution.error}
          fullWidth
          autocomplete={'institution'}
          containerStyle={dims.isMobile ? { marginBottom: 0 } : { marginRight: '24px', marginTop: '-1px' }}
        />

        <Dropdown
          title={t('pages.register.job_info.institution_city')}
          placeholder={t('pages.register.job_info.institution_city_placeholder')}
          options={
            getCitiesData().map(city => ({
              value: city.id,
              label: city.name
            }))}
          isLoading={appState.cities.loading}
          onChange={e => handleChangeText('institutionCity', e)}
          error={user.institutionCity.error}
          fullWidth
          hasTop
          containerStyle={dims.isMobile && { marginBottom: '32px' }}
        />

      </TextSection>
    </Wrapper>
  )
};

export default JobInfo;
