import React from 'react';
import { UserContext } from 'context/UserContext';
import { PATHS } from 'routes/paths';

const Zoom = React.memo(({
  urlInfo
}) => {
  // TODO :: fix userStatus
  const [userStatus, setUserStatus] = React.useState({ error: false, joined: false });
  const { user, userProfile } = React.useContext(UserContext);
  const ZoomMtg = require('@zoomus/websdk').ZoomMtg;
  // ZoomMtg.setZoomJSLib('https://source.zoom.us/2.6.0/lib', '/av');
  ZoomMtg.setZoomJSLib('https://source.zoom.us/2.7.0/lib', '/av');

  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareWebSDK();
  // loads language files, also passes any error messages to the ui
  ZoomMtg.i18n.load('en-US');
  ZoomMtg.i18n.reload('en-US');
  document.getElementById('zmmtg-root').style.display = 'none';

  // var authEndpoint = ''
  const sdkKey = urlInfo?.api_key || '0XyQe-wLSQmTQk426Vnenw'
  const meetingNumber = urlInfo?.meeting_number || '82756183123'
  const passWord = urlInfo?.meeting_password || 'DFY5ff';
  // const role = 0
  const userName = userProfile?.name_surname || 'Guest';
  const userEmail = user?.email || 'guest@mail.com';

  const registrantToken = ''
  const zakToken = ''
  // const leaveUrl = window.location.origin + PATHS.EVENTS + `${window.location.pathname}`;
  // TODO :: fix leaveUrl
  // const leaveUrl = `${window.location.origin}/${window.location.pathname.replace('stage-area', 'events')}`;
  const leaveUrl = window.location.origin + PATHS.EVENT_STATUS;
  // const leaveUrl = '/';

  React.useEffect(() => {
    getSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getSignature() {
    // return startMeeting('MFh5UWUtd0xTUW1UUWs0MjZWbmVudy44Mjc1NjE4MzEyMy4xNjc4NDYyMjA5ODQzLjAuTDFDOE1DaWJwZFFkUlY5THJDeUZUMVIya2QzRkdhenpXL1FsME15WUlrWT0=');
    return startMeeting(urlInfo.url);
  };

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      // meetingInfo: ['topic', 'host', 'dc'],
      // meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype', 'report'],
      meetingInfo: ['topic'],
      disableInvite: true,
      showMeetingHeader: true,
      screenShare: false,
      disableRecord: false,
      disableReport: false,
      isSupportQA: false,
      isSupportCC: false,

      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          apiKey: sdkKey,
          signature: signature,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
            // setUserStatus({ ...userStatus, error: false, joined: true });
            // document.getElementsByClassName('security-option-menu__pop-menu')?.[0]?.remove?.();
          },
          error: (error) => {
            console.log(error);
            // setUserStatus({ ...userStatus, error: true, joined: false });
          }
        })
        ZoomMtg.inMeetingServiceListener('onUserJoin', function (data) {
          console.log('inMeetingServiceListener onUserJoin', data);
          // setUserStatus({ ...userStatus, error: false, joined: true });
          document.getElementsByClassName('audio-option-menu dropup btn-group')?.[0]?.remove?.();
        });
        ZoomMtg.inMeetingServiceListener('onUserLeave', (data) => {
          console.log('inMeetingServiceListener onUserLeave', data);
        });
        ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data) => {
          console.log('inMeetingServiceListener onMeetingStatus', data);
          document.getElementsByClassName('audio-option-menu dropup btn-group')?.[0]?.remove?.();
        });

      },
      error: (error) => {
        console.log(error)
        // setUserStatus({ ...userStatus, error: true, joined: false });
      }
    })
  };

  return (
    <div>
      {userStatus.error &&
        <React.Fragment>
          <p>Zoom bağlantısı kurulamadı. Lütfen daha sonra tekrar deneyiniz.</p>
          <p onClick={getSignature}>Yeniden Dene</p>
        </React.Fragment>
      }
      {/* <main> */}
      {/* <h1>Zoom Meeting SDK Sample React</h1> */}
      {/* <button onClick={getSignature}>Join Meeting</button> */}
      {/* </main> */}
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.urlInfo?.url === nextProps.urlInfo?.url;
});

export default Zoom;