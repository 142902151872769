import React from 'react';
import { ReactComponent as QuestionIcon } from 'assets/img/icons/question.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  // QAButton,
  QAButtonContainer,
  Text,
  IconArea,
} from './style';

const AskQuestion = ({
  openModal
}) => {
  const { t } = React.useContext(LanguageContext);

  return (
    <QAButtonContainer onClick={openModal}>
      <Text>{t('words.ask_question')}</Text>
      <IconArea>
        <QuestionIcon />
      </IconArea>
    </QAButtonContainer>
  )
  // return (
  //   <QAButton onClick={() => { }}>
  //     <QuestionIcon />
  //     Ask Question
  //   </QAButton>
  // )
};

export default AskQuestion;
