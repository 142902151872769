import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ $color }) => $color || '#0064A8'};
  border-radius: 10px;
  /* width: 330px; */
  /* width: calc(100% / 2.05); */
  /* width: 330px; */
  width: 100%;

  height: 135px;
  min-width: 150px;
  /* min-width: 225px; */
  /* margin-bottom: 40px; */
  /* min-width: 200px; */
  /* @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 70%;
  } */
  cursor: pointer;
  overflow: hidden;
  transition-property: width,height,opacity;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);

  &:hover, &:focus {
    opacity: .8;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(50vw - 24px);
  }
`;

export const BrandArea = styled.div`
  display: flex;
  height: 100%;
  /* background: #21212151; */
  align-items: center;
  padding: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    align-items: baseline;
    padding: 16px;
  }
`;

export const BrandDetails = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $sx }) => $sx && $sx};
  /* width: 70%; */
  /* align-items: center; */
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100%;
  };
`;

export const BrandTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  /* line-height: 42px; */
  letter-spacing: -0.02em;
  color: #FFFFFF;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* padding-right: 15px; */
  word-break: break-word;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 20px;
  };

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
    padding-right: 0;
  };
`;

export const EventCount = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 21px;
  };
`;

export const BrandLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin-left: 16px;
  margin-right: 16px;
  width: 25%;
  ${({ $sx }) => $sx && $sx};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 40%;
  };
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 30%;
  };
`;

export const BrandImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 3 / 2;
  user-select: none;
`;
