import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 5px;
  background: #FDEBEB;
  padding: 5px;
  border-radius: 4px;
  ${({ $sx }) => $sx}
  svg {
    /* min-width: min-content; */
  }
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.005em;
    color: #C50F3C;
    margin-left: 6px;
  }
`;
