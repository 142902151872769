import React from 'react';
import { useDimensions } from 'hooks/useDimensions';
import AuthHeader from 'components/AuthHeader';
import {
  Main,
  Section
} from './style';

const AuhtLayout = ({
  children
}) => {
  const { isMobile } = useDimensions();
  const headRef = React.useRef(null);
  const [headerSize, setHeaderSize] = React.useState(0);

  React.useEffect(() => {
    document.documentElement.style.backgroundColor = '#fff';
    setHeaderSize(headRef.current?.clientHeight);
  }, []);

  return (
    <Main>
      <AuthHeader ref={headRef} />
      <Section
        $customStyle={
          isMobile ?
            {
              marginTop: headerSize
            }
            :
            {
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              height: `calc(100vh - ${headerSize}px)`,
              marginTop: headerSize
            }
        }
      >
        {children}
      </Section>
    </Main>
  )
};

export default AuhtLayout;
