import React from 'react';
import IvsPlayer from '../Meetings/IvsPlayer';
import Zoom from '../Meetings/Zoom';
import { Wrapper, Frame, LoaderWrapper, Dot } from './style';

const Iframe = ({
  title,
  src,
  height,
  width,
  onFrameLoad,
  urlType,
  urlInfo,
  eventId,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);
  // const loaderRef = React.useRef(null);

  const handleLoad = (e) => {
    // loaderRef.current.style.display = 'none';
    setLoading(false);
    onFrameLoad?.();
  };

  const getFrame = (url) => {
    // setLoading(true);
    return (
      <Frame
        title={title}
        src={url}
        $height={height}
        $width={width}
        loading='eager'
        onLoad={handleLoad}
        allowFullScreen={true}
        allow={'autoplay; fullscreen; microphone; camera'}
        // sandbox={'allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'}
        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
        {...props}
      />
    )
  };

  const renderEventMeeting = () => {
    // console.log('urlInfo.url', urlInfo.url);
    switch (urlType?.name_slug) {
      case 'youtube-embed':
        return getFrame(urlInfo.url);
      case 'vimeo-embed':
        return getFrame(urlInfo.url);
      case 'zoom-sdk':
        return getFrame(`${window.location.origin}/zoom-client/${eventId}`);
      // return <Zoom urlInfo={urlInfo} />
      case 'rtmps':
        // handleLoad();
        return <IvsPlayer src={urlInfo?.url || 'https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8'} />;
      default:
        return '';
    }
  };

  if (!urlInfo || !urlType) {
    return null;
  };

  return (
    <Wrapper>
      {renderEventMeeting()}
      {/* <Frame
        title={title}
        // src={'https://www.google.com/webhp?igu=1'}
        // src={src}
        src={renderEventMeeting()}
        $height={height}
        $width={width}
        loading='eager'
        onLoad={handleLoad}
        allowFullScreen={true}
        allow={'autoplay'}
        // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
        {...props}
      /> */}

      <LoaderWrapper $loading={loading}/*  ref={loaderRef} */>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </LoaderWrapper>

    </Wrapper>
  )
};

export default React.memo(Iframe, (prevProps, nextProps) => {
  return prevProps.src === nextProps.src;
});
