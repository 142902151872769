import { PATHS } from './paths';
import { AppLayout, AuthLayout } from 'layouts';
import {
  HomePage,
  SpeakersPage,
  CalendarPage,
  EventsPage,
  SingleEventPage,
  ProfilePage,
  StageAreaPage,
  ZoomClientPage,
  EventStatusPage,
  ContentsPage,
  SingleContentPage,

  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  ChangePasswordPage,
  VerifyPage,
  NotFoundPage
} from 'pages';

export const ROUTES = Object.freeze([
  {
    name: 'hc',
    key: 'hc',
    path: '/hc',
    component: () => <div>ok</div>,
    isProtected: false,
  },
  {
    key: 'home',
    name: 'home',
    path: PATHS.HOME,
    component: HomePage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'calendar',
    name: 'calendar',
    path: PATHS.CALENDAR,
    component: CalendarPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'speakers',
    name: 'speakers',
    path: PATHS.SPEAKERS,
    component: SpeakersPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'events',
    name: 'events',
    path: PATHS.EVENTS,
    component: EventsPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'single_events',
    name: 'single events',
    path: PATHS.SINGLE_EVENT,
    component: SingleEventPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'contents',
    name: 'contents',
    path: PATHS.CONTENTS,
    component: ContentsPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'single_contents',
    name: 'single_contents',
    path: PATHS.SINGLE_CONTENT,
    component: SingleContentPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'profile',
    name: 'profile',
    path: PATHS.PROFILE,
    component: ProfilePage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'stage_area',
    name: 'stage area',
    path: PATHS.STAGE_AREA,
    component: StageAreaPage,
    layout: AppLayout,
    isProtected: true,
  },
  {
    key: 'zoom_client',
    name: 'zoom client',
    path: PATHS.ZOOM_CLIENT,
    component: ZoomClientPage,
    isProtected: true,
  },
  {
    key: 'event_status',
    name: 'event status',
    path: PATHS.EVENT_STATUS,
    component: EventStatusPage,
    isProtected: true,
  },

  // Auth
  {
    key: 'auth',
    name: 'auth',
    path: PATHS.AUTH,
    navigatePath: PATHS.LOGIN,
    isRestricted: true,
  },
  {
    key: 'login',
    name: 'login',
    path: PATHS.LOGIN,
    component: LoginPage,
    layout: AuthLayout,
    isProtected: false,
    isRestricted: true,
  },
  {
    key: 'register',
    name: 'register',
    path: PATHS.REGISTER,
    component: RegisterPage,
    layout: AuthLayout,
    isProtected: false,
    isRestricted: true,
  },
  {
    key: 'forgot_password',
    name: 'forgot password',
    path: PATHS.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
    layout: AuthLayout,
    isProtected: false,
    isRestricted: true,
  },
  {
    key: 'change_password',
    name: 'change password',
    path: PATHS.CHANGE_PASSWORD,
    component: ChangePasswordPage,
    layout: AuthLayout,
    isProtected: false,
    isRestricted: true,
  },
  {
    key: 'verify_account',
    name: 'verify account',
    path: PATHS.VERIFY_ACCOUNT,
    component: VerifyPage,
    layout: AuthLayout,
    isProtected: true,
    isRestricted: false,
  },

  {
    key: 'not_found',
    name: 'not found',
    path: '*',
    component: NotFoundPage,
    layout: AuthLayout,
    isProtected: false,
  },
]);
