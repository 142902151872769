import 'react-spring-bottom-sheet/dist/style.css';
import React, { Fragment } from 'react';
import propTypes from 'prop-types';
import ConfirmationModal from './components/Confirmation';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { ReactComponent as CloseIcon } from 'assets/img/icons/x.svg';
import {
  BaseModal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  Overlay,

  SheetHeader,
  SheetCloseBtn
} from './style';
import { useDimensions } from 'hooks/useDimensions';

export const ModalCloseButtonUI = ({ closeModal }) => (
  <CloseButton onClick={() => closeModal?.()}>
    <CloseIcon />
  </CloseButton>
);

const Modal = React.forwardRef(({
  title,
  isCloseOutsideClick,
  size,
  customModalStyle,
  customContentStyle,
  children,
  hasModalHeader,
  modalState,
  customTitle,

  showCloseButton,

  onClose,
  type,
  confirmProps,
  mobileHeightRatio
}, ref) => {
  const dims = useDimensions();
  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (modalState) {
      openModal();
    } else {
      closeModal();
    }
  }, [modalState]);

  const keyListener = (event) => {
    if (event.key === "Escape") {
      isCloseOutsideClick && closeModal();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", keyListener, false);
    return () => {
      document.removeEventListener("keydown", keyListener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useImperativeHandle(ref, () => ({
    openModal: openModal,
    closeModal: closeModal,
  }));

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const renderModal = () => {
    switch (type) {
      case 'confirmation':
        return <ConfirmationModal
          {...confirmProps}
          closeModal={closeModal}
        />
      default:
        return children;
    }
  };

  const onDismiss = () => {
    if (!isCloseOutsideClick) {
      return;
    }
    setVisible(false);
    onClose();
  };

  if (dims.isMobile) {
    return (
      <>
        <BottomSheet
          open={isVisible}
          header={
            <SheetHeader $sx={(!customTitle && !title) && { height: '16px' }}>
              {customTitle ? customTitle : <ModalTitle>{title}</ModalTitle>}
              {showCloseButton && <SheetCloseBtn onClick={closeModal}>
                <CloseIcon />
              </SheetCloseBtn>}
            </SheetHeader>
          }
          snapPoints={({ minHeight }) => mobileHeightRatio > 0 ? dims.height / mobileHeightRatio : minHeight}
          onDismiss={onDismiss}
        >
          <ModalContent $customContentStyle={customContentStyle}>
            {renderModal()}
          </ModalContent>
        </BottomSheet>
      </>
    )
  };

  if (!isVisible) {
    return;
  }

  return (
    <Fragment>
      <Overlay onClick={() => isCloseOutsideClick && closeModal()} />
      <BaseModal
        ref={ref}
        role='presentation'
        aria-labelledby={title || 'Modal Title'}
        $size={size}
        $customModalStyle={customModalStyle}
      >
        <ModalContent $customContentStyle={customContentStyle}>
          {hasModalHeader && <ModalHeader $hasCustomTitle={!!customTitle}>
            {customTitle ? customTitle : <ModalTitle>{title}</ModalTitle>}
            {showCloseButton && <CloseButton onClick={closeModal}>
              <CloseIcon />
            </CloseButton>}
          </ModalHeader>}
          {renderModal()}
        </ModalContent>
      </BaseModal>
    </Fragment>
  )
});

Modal.propTypes = {
  title: propTypes.string,
  isCloseOutsideClick: propTypes.bool,
  modalState: propTypes.bool,
  showCloseButton: propTypes.bool,
  hasModalHeader: propTypes.bool,
  customModalStyle: propTypes.object,
  size: propTypes.oneOf(['md', 'lg']),
  type: propTypes.oneOf(['confirmation']),
  mobileHeightRatio: propTypes.number,
  onClose: propTypes.func,
};

Modal.defaultProps = {
  title: '',
  isCloseOutsideClick: true,
  size: 'lg',
  hasModalHeader: true,
  showCloseButton: true,
  mobileHeightRatio: 1.41,
  onClose: () => { },
};

export default Modal;
