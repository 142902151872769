import styled from "styled-components";

export const Card = styled.div`
  position: relative;
  height: 135px;
  /* width: 212px; */
  width: 100%;
  background: ${({ $bg }) => $bg || '#0064A8'};
  border-radius: 10px;
  transition-property: width,height;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1);
  overflow: hidden;
  min-width: 150px;
  /* margin-bottom: 20px; */
  p {
    color: #fff;
  }
  @media only screen and ((max-width: ${({ theme }) => theme.breakpoints.md}px) or (max-width: 1200px)) {
    width: 100%;
    margin-bottom: 0px;
    height: 100px;
    /* min-width: 225px; */
  };
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(50vw - 24px);
    height: 100px;
  };
`;

export const ShapeTop = styled.div`
  position: absolute;
  width: 325px;
  height: 177px;
  left: -106px;
  top: -30px;

  background: ${({ $overlayColor }) => `linear-gradient(269.9deg, ${$overlayColor} 3.57%, rgba(103, 161, 247, 0) 54.86%)`};
  transform: rotate(-49.08deg);
  z-index: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  
  width: 100%;
  align-items: flex-start;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-45%, -50%);
`;

export const IconArea = styled.div`
  border-radius: 8px;
  padding: 9.5px 11.5px;
  background-color: rgba(255, 255, 255, 0.32);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  margin-right: 15px;
  
  svg  {
    /* width: 18px; */
    /* height: 20px; */
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {    
    padding: 6.5px 8.5px;
    svg {
      width: 25px;
      height: 25px;
    }
  };
`;

export const StatText = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 50%; */
  width: 100%;
  z-index: 1;
  margin-top: 5px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {    
    margin-top: 10px;
  };
`;

export const CountText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 35px;
  color: #FFFFFF;
  overflow: hidden;

  width: 90%;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {    
    font-size: 24px;
    line-height: 27px;
  };
`;

export const Text = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  overflow: hidden;

  width: 90%;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {    
    font-size: 12px;
    line-height: 18px;
  };
`;
