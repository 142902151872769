import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  width: 90%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  video {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  video::-webkit-media-controls-timeline,
  video::-webkit-media-controls-current-time-display {
      display: none;
  }
`;

const videoPlayer = React.createRef(null);

const IvsPlayer = ({
  src
}) => {
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;

  React.useEffect(() => {
    const PlayerState = IVSPlayer.PlayerState;
    const PlayerEventType = IVSPlayer.PlayerEventType;

    if (!isPlayerSupported) {
      console.warn("The current browser does not support the IVS player.");
      return;
    }
    // Initialize player
    const player = IVSPlayer.create();
    videoPlayer.current = player;
    console.log("IVS Player version:", player.getVersion());
    player.attachHTMLVideoElement(document.getElementById("video-player"));

    // Attach event listeners
    player.addEventListener(PlayerState.PLAYING, function () {
      console.log("Player State - PLAYING");
    });
    player.addEventListener(PlayerState.ENDED, function () {
      console.log("Player State - ENDED");
    });
    player.addEventListener(PlayerState.READY, function () {
      console.log("Player State - READY");
    });
    player.addEventListener(PlayerEventType.ERROR, function (err) {
      console.warn("Player Event - ERROR:", err);
    });
    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
      const metadataText = cue.text;
      const position = player.getPosition().toFixed(2);
      console.log(
        `PlayerEvent - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
      );
    });

    player.setAutoplay(true);
    player.load(src);
    player.setVolume(0.5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])


  return (
    <VideoContainer>
      <video
        id="video-player"
        controls
        playsinline
        ref={videoPlayer}
        disablePictureInPicture
        controlsList="nodownload"
      ></video>
    </VideoContainer>
  );
};

export default React.memo(IvsPlayer, (prevProps, nextProps) => {
  return prevProps.src === nextProps.src;
});
