import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 15px;
  width: ${({ $fullWidth }) => $fullWidth ? '100%' : 'fit-content'};
  ${({ $containerStyle }) => $containerStyle};
  ${({ $fullWidth }) => !$fullWidth && css`
    min-width: min-content;
  `};
  
  /* width: calc(100% - 32px); */
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  outline: none;
  background-color: #fff;
  margin-top: 5px;
  
  padding-left: ${({ $hasLeftIcon }) => $hasLeftIcon ? '47px' : '16px'};
  padding-right: ${({ $hasRightIcon }) => $hasRightIcon ? '47px' : '16px'};
  
  font-family: 'Poppins';

  border: 1px solid ${({ $error }) => $error ? '#C50F3C' : '#B0B7C3'};
  transition: border 0.3s ease-in-out;

  width: -webkit-fill-available;
  height: 56px;
  
  border-radius: 16px;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #54565B;
  ${({ $inputStyle }) => $inputStyle};

  ${({ $error }) => $error && css`
    :not(:focus) {
      box-shadow: 0px 12px 23px rgba(255, 86, 48, 0.06);
    }
  `}
  
  &[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    border: 1px solid #377DFF;
  }

  :disabled {
    cursor: not-allowed;
    opacity: .8;
  };

  &[type=password] {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #54565B;
  };

  ::placeholder {
    user-select: none;
    /* color: ${({ $error }) => $error ? '#ff003398' : '#B0B7C3'}; */
    color: #B0B7C3;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: ${({ $error }) => $error ? '#C50F3C' : '#203662'} ;
  padding: 0;
  padding-left: 16px;
  ${({ $labelStyle }) => $labelStyle};
  white-space: nowrap;
`;

export const ErrorText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.005em;
  color: #C50F3C;
  margin-left: 6px;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 21px;
  ${({ $isLeft }) =>
    $isLeft ?
      css`
      left: 17px;
      `
      :
      css`
      right: 17px;
  `}
  ${({ $iconStyle }) => $iconStyle && $iconStyle};
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 0 16px;
  position: absolute;
`;
