import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import Seo from 'helpers/Seo';
import { ReactComponent as EditIcon } from 'assets/img/icons/edit.svg';
import { ReactComponent as EyeNoIcon } from 'assets/img/icons/eye-no.svg';
import { ReactComponent as EyeYesIcon } from 'assets/img/icons/eye-yes.svg';
import { ReactComponent as StatusIcon } from 'assets/img/icons/ok-circle.svg';
import ErrorContainer from 'components/ErrorContainer';
// import CheckBox from 'components/CheckBox';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { UserContext } from 'context/UserContext';
import { AppContext } from 'context/AppContext';
import { useDimensions } from 'hooks/useDimensions';
import Modal from 'components/Modal';
import ChangePhone from './components/ChangePhone';
import ChangeMail from './components/ChangeMail';
import ConfirmCode from './components/ConfirmCode';
import SuccessModal, { SuccessUpdateModal } from './components/SuccessModal';
import {
  Wrapper,
  HeaderContainer,
  Title,
  StartEditButton,
  ProfileSettings,
  UserInfoContainer,
  SubTitleArea,
  SubTitle,
  Line,
  ButtonsContainer,
  MasterSection,
  SectionArea,
  PermissionText,
  InfoText
} from './style';

const IQVA_LINK = process.env.REACT_APP_IQVA;

const USER_SCHEMA = {
  name: { value: '', error: '', },
  email: { value: '', error: '', },
  tckn: { value: '', error: '', },
  gsm: { value: '', error: '', },
  branch: { value: { value: '', label: '' }, error: '', },
  title: { value: { value: '', label: '' }, error: '', },
  institution: { value: '', error: '', },
  city: { value: { value: '', label: '' }, error: '', },
  confirmPassword: { value: '', error: '', },
  newPassword: { value: '', error: '', },
  currentPassword: { value: '', error: '', },
};

const PARTS = {
  personal: false,
  job: false,
  passwords: false,
  permissions: false
};

const MODAL_TYPE = {
  email: {
    type: 'E-posta',
    codeBlankError: 'Lütfen e-posta adresinize gönderilen 4 haneli doğrulama kodunu giriniz',
    confirmTitle: 'Lütfen e-posta adresinize gönderilen kodu giriniz.',
    success: 'E-posta adresiniz başarıyla değiştirildi.',
  },
  phone: {
    type: 'Telefon Numarası',
    codeBlankError: 'Lütfen telefonunuza gönderilen 4 haneli doğrulama kodunu giriniz',
    confirmTitle: 'Lütfen telefonunuza gönderilen kodu giriniz.',
    success: 'Telefon Numarası başarıyla değiştirildi.',
  }
};

const EventStatButtons = ({
  handleStartEdit,
  dims,
  handleSave,
  isShow,
  stats
}) => isShow && (
  <React.Fragment>
    <ErrorContainer message={stats?.error} sx={{ marginTop: 0 }} />
    <ButtonsContainer>
      <Button
        size={'md'}
        title={'Vazgeç'}
        outlined
        containerStyle={dims.isMobile ? { width: '50%', paddingRight: '55px', paddingLeft: '55px', marginRight: '24px' } : { paddingRight: '55px', paddingLeft: '55px', marginRight: '24px' }}
        onClick={handleStartEdit}
      // loading={stats?.loading}
      />

      <Button
        size={'md'}
        title={'Kaydet'}
        containerStyle={dims.isMobile ? { width: '50%', paddingRight: '55px', paddingLeft: '55px' } : { paddingRight: '55px', paddingLeft: '55px' }}
        onClick={handleSave}
        loading={stats?.loading}
      />
    </ButtonsContainer>
  </React.Fragment>
);

const Profile = () => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);
  const { user: userState, userProfile, getUserProfile, updateProfile, updatePassword } = React.useContext(UserContext);
  const { appState, getCitiesData, getBranchesData, getTitlesData } = React.useContext(AppContext);
  const modalRef = React.useRef(null);
  const codeModalRef = React.useRef(null);
  const successModalRef = React.useRef(null);
  const successInfoModalRef = React.useRef(null);
  const consentModal = React.useRef(null);
  const [user, setUser] = React.useState(USER_SCHEMA);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState({ current: false, new: false, confirm: false });
  const [editableParts, setEditableParts] = React.useState(PARTS);
  const [consents, setConsents] = React.useState({ kvkk: false, gilead: false, iqva: false });
  // const [modalType, setModalType] = React.useState({ phone: false, mail: false, pass: false });
  const [modalType, setModalType] = React.useState(null);
  const [modalData, setModalData] = React.useState(null);
  const [consentDetail, setConsentDetail] = React.useState(null);
  const [process, setProcess] = React.useState({ job: { loading: false, error: null }, password: { loading: false, error: null }, permissions: { loading: false, error: null } });

  React.useEffect(() => {
    setUser({
      ...USER_SCHEMA,
      name: { value: `${userProfile?.name_surname}`, error: '' },
      email: { value: userState?.email || '', error: '' },
      tckn: { value: userProfile?.tckn || '', error: '' },
      gsm: { value: userProfile?.gsm || '', error: '' },
      branch: { value: { value: userProfile?.branch?.id, label: userProfile?.branch?.name }, error: '' },
      title: { value: { value: userProfile?.title?.id, label: userProfile?.title?.name }, error: '' },
      institution: { value: userProfile?.institution || '', error: '' },
      city: { value: { value: userProfile?.city?.id, label: userProfile?.city?.name }, error: '' },
    });
  }, [userState, userProfile])

  const cancelEditPassword = () => {
    changePart('passwords');
    setProcess({ ...process, password: { loading: false, error: null } });
    setUser({
      ...user,
      currentPassword: { value: '', error: '' },
      newPassword: { value: '', error: '' },
      confirmPassword: { value: '', error: '' },
    });
  };

  const cancelEditJob = () => {
    changePart('job');
    setProcess({ ...process, job: { loading: false, error: null } });
    setUser({
      ...user,
      branch: { value: { value: userProfile?.branch.id, label: userProfile?.branch.name }, error: '' },
      title: { value: { value: userProfile?.title.id, label: userProfile?.title.name }, error: '' },
      institution: { value: userProfile?.institution || '', error: '' },
      city: { value: { value: userProfile?.city.id, label: userProfile?.city.name }, error: '' },
    });
  };

  const handleCheckboxChange = (status, type) => {
    setConsents({ ...consents, [type]: status });
  };

  const propagation = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleTextChange = (value, key) => {
    setUser({ ...user, [key]: { ...user[key], value, error: '' } });
  };

  const handleSave = async () => {
    console.log(user);
  };

  const changePart = (part) => {
    setEditableParts({ ...editableParts, [part]: !editableParts[part] });
  };

  const handleModalChange = (type) => {
    setModalType(type);
    modalRef.current?.openModal();
  };

  const renderModal = (res = 1) => {
    switch (modalType) {
      case 'email':
        switch (res) {
          case 1:
            return <ChangeMail
              value={user.email.value}
              handleTextChange={handleTextChange}
              openConfirmModal={(e) => { setModalData({ ...modalData, ...e }); codeModalRef.current?.openModal?.() }}
            />;
          case 2:
            return 'E-Posta Değiştir';
          default:
            return null;
        }
      case 'phone':
        switch (res) {
          case 1:
            return <ChangePhone
              value={user.gsm.value}
              handleTextChange={handleTextChange}
              openConfirmModal={(e) => { setModalData({ ...modalData, ...e }); codeModalRef.current?.openModal?.() }}
            />;
          case 2:
            return 'Telefon Numaranı Değiştir';
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const handleMailorPhoneSuccess = async () => {
    successModalRef.current.openModal();
    await getUserProfile(false);
  };

  const changeProfileInfo = async () => {
    setProcess({ ...process, job: { loading: true, error: null } });
    const payload = {
      branch: user.branch.value.value,
      title: user.title.value.value,
      city: user.city.value.value,
      institution: user.institution.value,
    };

    try {
      await updateProfile({ payload, userId: userProfile.user });
      setProcess({ ...process, job: { loading: false, error: null } });
      successInfoModalRef.current.openModal();
      setEditableParts({ ...editableParts, job: false });
    } catch (error) {
      console.log('Profile :: changeProfileInfo error', error);
      setProcess({ ...process, job: { loading: false, error: error } })
    }
  };

  const changePassword = async () => {
    setProcess({ ...process, password: { loading: true, error: null } });

    // TODO :: password validation

    const payload = {
      old_password: user.currentPassword.value,
      new_password: user.newPassword.value,
    };

    try {
      await updatePassword(payload);
      successInfoModalRef.current.openModal();
      setEditableParts({ ...editableParts, passwords: false });
    } catch (error) {
      console.log('Profile :: changePassword error', error);
      setProcess({ ...process, password: { loading: false, error: error } });
    }
  };

  return (
    <Wrapper>
      <Seo
        title={t('pages.profile.title')}
      />
      <Modal
        ref={modalRef}
        mobileHeightRatio={0}
        title={renderModal(2)}
        size={'lg'}
      >
        {renderModal()}
      </Modal>

      <Modal
        ref={codeModalRef}
        mobileHeightRatio={0}
        title={'Confirm Code'}
        size={'md'}
        isCloseOutsideClick={false}
      >
        <ConfirmCode
          modalType={modalType}
          // onClose={() => { codeModalRef.current?.closeModal?.(); modalRef.current?.closeModal?.() }}
          // changedData={{ email: user.email.value, phone: user.gsm.value }}
          changedData={modalData}
          onSuccess={handleMailorPhoneSuccess}
          modalTypes={MODAL_TYPE}
        />
      </Modal>

      <Modal
        ref={successModalRef}
        mobileHeightRatio={0}
        title={'İşlem Başarılı'}
        size={'md'}
        isCloseOutsideClick={false}
      >
        <SuccessModal
          onClose={() => { successModalRef.current?.closeModal?.(); codeModalRef.current?.closeModal?.(); modalRef.current?.closeModal?.() }}
          modalType={modalType === 'email' ? 'Email' : 'Telefon numarası'}
        />
      </Modal>

      {/* TODO :: Fix this */}
      <Modal
        ref={successInfoModalRef}
        mobileHeightRatio={0}
        title={'İşlem Başarılı'}
        size={'md'}
        isCloseOutsideClick={false}
      >
        <SuccessUpdateModal onClose={() => successInfoModalRef.current.closeModal()} />
      </Modal>

      <Modal
        ref={consentModal}
        mobileHeightRatio={1.09}
        title={''}
        size={'lg'}
      >
        <div
          style={{ padding: '20px' }}
          dangerouslySetInnerHTML={{
            __html: consentDetail
          }}
        />
      </Modal>

      <HeaderContainer>
        <Title>{t('pages.profile.title')}</Title>
      </HeaderContainer>

      <ProfileSettings>
        <MasterSection>
          {/* Personal */}
          <SectionArea>
            <SubTitleArea>
              <SubTitle>Kişisel Bilgilerim</SubTitle>
              {/* <StartEditButton onClick={() => changePart('personal')} $isActive={editableParts.personal}>
                <EditIcon />
              </StartEditButton> */}
              <Line />
            </SubTitleArea>
            <UserInfoContainer>
              <TextInput
                type={'text'}
                label={'Adı Soyadı'}
                placeholder={'Adı Soyadı'}
                value={user.name.value}
                onChange={e => handleTextChange(e.target.value, 'name')}
                disabled={!editableParts.personal}
                error={user.name.error}
                name='name'
                containerStyle={{ width: '100%' }}
              />

              <TextInput
                type={'text'}
                label={'TC Kimlik No'}
                placeholder={'TC Kimlik No'}
                value={user.tckn.value}
                onChange={e => {
                  if (e.target.value >= 0) {
                    handleTextChange(e.target.value, 'tckn');
                  }
                }}
                disabled={!editableParts.personal}
                error={user.tckn.error}
                containerStyle={dims.isTablet ? { width: '100%', marginBottom: '12px' } : { width: '100%' }}
                maxLength={11}
                pattern="[0-9]*"
              />

              <TextInput
                type={'tel'}
                label={'Cep Telefonu'}
                name={'phone'}
                placeholder={'555 555 55 55'}
                value={user.gsm.value}
                onChange={e => {
                  if (e.target.value >= 0) {
                    handleTextChange(e.target.value, 'gsm');
                  }
                }}
                // disabled={!editableParts.personal}
                disabled={true}
                error={user.gsm.error}
                containerStyle={{ width: '100%' }}
                maxLength={10}
                autoComplete={'tel'}
                labelStyle={{ display: 'flex', justifyContent: 'space-between', padding: '2px', width: '95%', margin: '0 auto' }}
                // labelRight={<span style={{ color: '#0072CE', cursor: 'pointer' }} onClick={() => handleModalChange('phone')}>Değiştir</span>}
              />
              <TextInput
                type={'email'}
                name={'email'}
                label={'E-posta Adresi'}
                placeholder={'E-posta Adresi'}
                value={user.email.value}
                onChange={e => handleTextChange(e.target.value, 'email')}
                // disabled={!editableParts.personal}
                disabled={true}
                error={user.email.error}
                containerStyle={{ width: '100%' }}
                autocomplete={'email'}
                labelStyle={{ display: 'flex', justifyContent: 'space-between', padding: '2px', width: '95%', margin: '0 auto' }}
                // labelRight={<span style={{ color: '#0072CE', cursor: 'pointer' }} onClick={() => handleModalChange('email')}>Değiştir</span>}
              />
            </UserInfoContainer>
            <EventStatButtons
              dims={dims}
              handleSave={() => { }}
              handleStartEdit={() => changePart('personal')}
              isShow={editableParts.personal}
            />
          </SectionArea>

          {/* Jobs */}
          <SectionArea>
            <SubTitleArea>
              <SubTitle>Mesleki Bilgilerim</SubTitle>
              <StartEditButton onClick={cancelEditJob} $isActive={editableParts.job}>
                <EditIcon />
              </StartEditButton>
              <Line />
            </SubTitleArea>
            <UserInfoContainer>
              <Dropdown
                title={'Branşım'}
                placeholder={'Branşım'}
                options={getBranchesData().map(branch => ({
                  value: branch.id,
                  label: branch.name
                }))}
                isLoading={appState.branches.loading}
                onChange={e => handleTextChange(e, 'branch')}
                containerStyle={{ marginRight: '24px' }}
                value={user.branch.value}
                fullWidth
                error={user.branch.error}
                disabled={!editableParts.job}
              />
              <Dropdown
                title={'Unvanım'}
                placeholder={'Unvanım'}
                options={getTitlesData().map(title => ({
                  value: title.id,
                  label: title.name
                }))}
                isLoading={appState.titles.loading}
                onChange={e => handleTextChange(e, 'title')}
                value={user.title.value}
                fullWidth
                disabled={!editableParts.job}
                error={user.title.error}
                name='organization-title'
              />
              <TextInput
                type={'text'}
                label={'Çalıştığım Kurum/Hastane'}
                placeholder={'Çalıştığım Kurum/Hastane'}
                value={user.institution.value}
                onChange={e => handleTextChange(e.target.value, 'institution')}
                disabled={!editableParts.job}
                error={user.institution.error}
                containerStyle={{ width: '100%' }}
              />
              <Dropdown
                title={'Bulunduğum İl'}
                placeholder={'Bulunduğum İl'}
                options={
                  getCitiesData().map(city => ({
                    value: city.id,
                    label: city.name
                  }))}
                value={user.city.value}
                isLoading={appState.cities.loading}
                onChange={e => handleTextChange(e, 'city')}
                error={user.city.error}
                fullWidth
                disabled={!editableParts.job}
              />

            </UserInfoContainer>
            <EventStatButtons
              dims={dims}
              handleSave={changeProfileInfo}
              // handleStartEdit={() => changePart('job')}
              handleStartEdit={cancelEditJob}
              isShow={editableParts.job}
              stats={process.job}
            />
          </SectionArea>
        </MasterSection>

        {/* Passwords */}
        <SectionArea>
          <SubTitleArea>
            <SubTitle>Güvenlik</SubTitle>
            <StartEditButton onClick={cancelEditPassword} $isActive={editableParts.passwords}>
              <EditIcon />
            </StartEditButton>
            <Line />
          </SubTitleArea>
          <UserInfoContainer>
            <TextInput
              label={'Parola'}
              placeholder={'Parola'}
              value={user.currentPassword.value}
              onChange={e => handleTextChange(e.target.value, 'currentPassword')}
              disabled={!editableParts.passwords}
              type={isPasswordVisible.current ? 'text' : 'password'}
              rightIcon={() => !isPasswordVisible.current ? <EyeNoIcon /> : <EyeYesIcon />}
              rightIconClick={() => setIsPasswordVisible({ ...isPasswordVisible, current: !isPasswordVisible.current })}
              error={user.currentPassword.error}
              name={'current-password'}
              autoComplete={'current-password'}
              containerStyle={dims.isTablet ? { width: '100%', marginBottom: '12px' } : { marginBottom: '25px', width: 'calc(100% / 4 - 16px)' }}
            />
            {editableParts.passwords &&
              <TextInput
                label={'Yeni Parola'}
                placeholder={'Yeni Parola'}
                value={user.newPassword.value}
                onChange={e => handleTextChange(e.target.value, 'newPassword')}
                disabled={!editableParts.passwords}
                type={isPasswordVisible.new ? 'text' : 'password'}
                rightIcon={() => !isPasswordVisible.new ? <EyeNoIcon /> : <EyeYesIcon />}
                rightIconClick={() => setIsPasswordVisible({ ...isPasswordVisible, new: !isPasswordVisible.new })}
                error={user.newPassword.error}
                name={'new-password'}
                autoComplete={'new-password'}
                // containerStyle={dims.isMobile ? { width: '100%' } : { marginBottom: '25px', width: 'calc(100% / 4 - 16px)' }}
                containerStyle={dims.isTablet ? { width: '100%', marginBottom: '12px' } : { marginBottom: '25px', width: 'calc(100% / 4 - 16px)' }}
              />
            }
            {editableParts.passwords &&
              <TextInput
                label={'Yeni Parola Tekrar'}
                placeholder={'Yeni Parola Tekrar'}
                value={user.confirmPassword.value}
                onChange={e => handleTextChange(e.target.value, 'confirmPassword')}
                disabled={!editableParts.passwords}
                type={isPasswordVisible.confirm ? 'text' : 'password'}
                rightIcon={() => !isPasswordVisible.confirm ? <EyeNoIcon /> : <EyeYesIcon />}
                rightIconClick={() => setIsPasswordVisible({ ...isPasswordVisible, confirm: !isPasswordVisible.confirm })}
                error={user.confirmPassword.error}
                name={'current-password'}
                autoComplete={'current-password'}
                // containerStyle={dims.isMobile ? { width: '100%' } : { marginBottom: '25px', width: 'calc(100% / 4 - 16px)' }}
                containerStyle={dims.isTablet ? { width: '100%', marginBottom: '12px' } : { marginBottom: '25px', width: 'calc(100% / 4 - 16px)' }}
              />
            }
          </UserInfoContainer>
          <EventStatButtons
            dims={dims}
            handleSave={changePassword}
            handleStartEdit={cancelEditPassword}
            isShow={editableParts.passwords}
            stats={process.password}
          />
        </SectionArea>

        {/* Permissions */}
        <SectionArea>
          <SubTitleArea>
            <SubTitle>İzinler</SubTitle>
            {/* <StartEditButton onClick={() => changePart('permissions')} $isActive={editableParts.permissions}>
              <EditIcon />
            </StartEditButton> */}
            <Line />
          </SubTitleArea>
          {/* Rights */}


          {/* TODO :: style fix */}
          {
            userProfile?.iqva_consent_detail?.map?.((item, idx) => {
              return (
                <PermissionText
                  key={`consent_${idx}`}
                  $isChecked={item.is_checked}
                  $indent={['_Phone_Use', '_SMS_Use', '_Email_Use'].includes(item.key)}
                >
                  <StatusIcon />
                  <span>{item.title}
                    {item.text && (<span
                      style={{ color: '#0072c6', cursor: 'pointer', marginLeft: '5px', fontSize: '12px' }}
                      onClick={() => { setConsentDetail(item.text); consentModal.current.openModal(); }}
                    >detay</span>)}
                  </span>
                </PermissionText>
              )
            })
          }
          {/* Daha önce vermiş olduğunuz izinler, yukarıda listelenmiştir. Bu izinleri IQVA platformundan güncelleyebilirsiniz. "IQVA'ya Git" */}
          <InfoText>Daha önce vermiş olduğunuz izinler, yukarıda listelenmiştir. Bu izinleri Gilead Onam platformundan güncelleyebilirsiniz.</InfoText>
          <Button
            size={'md'}
            title={'Gilead Onam Sistemine Git'}
            containerStyle={{ marginTop: '10px' }}
            onClick={() => window.open(IQVA_LINK, '_blank')}
            {...(dims.isMobile && { fullSize: true })}
          />
        </SectionArea>
      </ProfileSettings>
    </Wrapper>
  )
}

export default Profile;
