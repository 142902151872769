import React from 'react';
import propTypes from 'prop-types';
import {
  MainContainer,
  StepContainer,
  StepWrapper,
  StepStyle,
  Circle
} from './style';

const ProgressSteps = React.forwardRef(({
  steps,
  containerStyle
}, ref) => {
  const [activeStep, setActiveStep] = React.useState(1);

  React.useImperativeHandle(ref, () => ({
    nextStep,
    prevStep,
    getCurrentStep: () => activeStep,
    setStep: (idx) => setStep(idx),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [activeStep]);

  const setStep = (s) => {
    if (activeStep < steps.length && activeStep > 0) {
      setActiveStep(s);
    }
  };

  const nextStep = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1)
    }
  };

  const prevStep = () => {
    if (activeStep > 1) {
      setActiveStep(activeStep - 1)
    }
  };

  const width = `${(100 / (steps.length - 1)) * (activeStep - 1)}%`

  if (steps.length === 0) {
    return null;
  };

  return (
    <MainContainer ref={ref} $containerStyle={containerStyle}>
      <StepContainer width={width}>
        {steps.map(({ label, step }) => (
          <StepWrapper key={`${label}_${step}`}>
            <StepStyle step={activeStep >= step ? 'completed' : 'incomplete'}>
              {activeStep >= step && <Circle step={activeStep >= step ? 'completed' : 'incomplete'} />}
              {/* <Circle step={activeStep >= step ? 'completed' : 'incomplete'} /> */}
            </StepStyle>
          </StepWrapper>
        ))}
      </StepContainer>
    </MainContainer>
  )
});

ProgressSteps.propTypes = {
  steps: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string,
    step: propTypes.number,
  })),
  containerStyle: propTypes.object,
};

ProgressSteps.defaultProps = {
  steps: []
};

export default ProgressSteps;
