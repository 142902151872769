import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 24px;
  padding-right: 24px;
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
`;

export const SpeakerContainer = styled.div`
  position: relative;
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 24px;
  padding-right: 24px;
  max-width: 2900px;
  margin: 0 auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.xxl + 'px'}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xl + 'px'}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg + 'px'}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm + 'px'}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
