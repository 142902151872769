import styled from 'styled-components';

export const QAButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  border: none;
  outline: none;
  /* right: 0; */
  bottom: 105px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;
  padding: 10px;
  padding-right: 5px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #203662;
  
  svg {
    margin-right: 4px;
  }

  transition: right 0.2s ease;
  right: -100px;
  &:hover {
    right: 0;
  }
`;

export const QAButtonContainer = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  height: max-content;
`;

export const Text = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #0064A8;
  margin-right: 10px;
  white-space: nowrap;
`;

export const IconArea = styled.div`
  border: 2px dashed #0064A870;
  border-radius: 100%;
  display: grid;
  place-items: center;
  svg {
    padding: 5px;
  }
`;
