import React from 'react'
import Zoom from 'pages/App/StageArea/components/Meetings/Zoom'
import { useParams } from 'react-router-dom';
import { watchEventService } from 'services/eventService';
import FSLoading from 'components/FSLoading';

const ZoomClient = () => {
  const [urlInfo, setUrlInfo] = React.useState({ data: null, loading: true, error: null })
  const { id } = useParams();

  const getEvent = async () => {
    setUrlInfo({ ...urlInfo, loading: true });
    try {
      const data = await watchEventService({ lang: 'tr', eventId: id });
      setUrlInfo({ ...urlInfo, data: data.url_info, loading: false, error: null });
    } catch (error) {
      setUrlInfo({ ...urlInfo, data: null, loading: false, error });
    }
  };

  React.useEffect(() => {
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (urlInfo.loading) {
    return <FSLoading
      type={'small'}
      text={'Zoom bağlantısı bekleniyor...'}
      containerStyle={{ height: '70vh' }}
    />;
  };

  if (urlInfo.error || !urlInfo.data) {
    return <React.Fragment>
      <p onClick={getEvent}>Zoom bağlantısı alınamadı. Tekrar denemek için tıklayınız.</p>
    </React.Fragment>;
  };

  return (
    <Zoom urlInfo={urlInfo.data} />
  )
}

export default ZoomClient;
