import styled from 'styled-components';
import BackImage from 'assets/img/images/login-bg.svg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  overflow: hidden; // Circles
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    /* overflow: unset; */
    min-height: calc(100vh + 40px);
    padding-bottom: 40px;
  }
`;

export const LeftContainer = styled.div`
  position: relative;
  width: 60%;
  background-image: url(${BackImage});
  background-repeat: no-repeat;
  background-size: 100vh 100%;
  min-width: 500px;
  background-size: cover;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    height: 48vh;
    background-size: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
    position: relative;
    top: 40px;
    padding-bottom: 40px;
  }
`;

export const ContextCard = styled.div`
  position: relative;
  bottom: 30px;
  width: 80%;
  overflow: auto;
  padding: 36px 0;
  top: 5px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: unset;
    bottom: 0;
    width: 93.6%;
  }
`;

export const OnBoardingArea = styled.div`
  background-color: #203662;
  border-radius: 8px;
  /* width: 521px; */
  width: 60%;
  height: auto;
  transition: all 0.3s ease-in-out;
  left: 3.19%;
  /* position: relative; */
  position: absolute;
  /* bottom: 150px; */
  bottom: 10vh;
  /* padding: 30px; */
  /* padding-bottom: 57px; */
  /* padding-right: 0; */
  background-color: #20366295;
  /* background-color: #20366240; */
  /* backdrop-filter: blur(10px); */

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    bottom: -20px;
    width: 93vw;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 91vw;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xsm}px) {
    width: 91vw;
  }
`;

export const OnBoardingTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  
  padding: 30px;
  padding-bottom: 12px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 24px;
    padding: 12px;
  }
`;

export const OnBoardingSubText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  padding: 30px;
  padding-top: 0;
  padding-right: 40px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    padding: 12px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const ForgotPassArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const SubText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #B0B7C3;
  margin-top: 16px;
  margin-bottom: 32px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`;

export const ForgotLinkText = styled(Link)`
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #B0B7C3;
`;

export const LinkText = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => $align || 'flex-end'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${({ $fw }) => $fw ? $fw : '400'};
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: ${({ $color }) => $color || '#B0B7C3'} ;
`;

export const CircleContainer = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 250px;
  left: 50px;
`;

export const BackgroundLine = styled.div`
  border: 1px solid #FFFFFF;
  position: absolute;
  width: ${({ $size }) => $size || '100%'};
  height: ${({ $size }) => $size || '100%'};
  border-radius: 100%;
  opacity: .3;
`;

export const ConsentError = styled.div`
  padding: 20px;
`;

export const ConsentDescription = styled.p`
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  color: #203662;
  padding-bottom: 20px;
`;

export const ConsentText = styled.p`
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
`;
