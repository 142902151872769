import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 8px;
  padding-bottom: 14px;
  padding-top: 15px;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background: #FFFFFF;
  opacity: 0.96;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuccessfulContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 40%;
`;

export const SuccessTitleText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #203662;
`;

export const IconContainer = styled.div`
  display: grid;
  place-items: center;
  padding: 20px;
  border: 1.12963px solid #203662;
  border-radius: 100.286px;
`;

export const SuccessText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #54565B;
`;
