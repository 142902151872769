import styled from 'styled-components';
import BackImage from 'assets/img/images/forgot-bg.svg';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-bottom: 50px;
    min-height: calc(100vh - 140px);
    flex-direction: column;
    width: 94%;
    margin: 0 auto;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #203662;
  }
`;

export const SubDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-top: 16px;
  padding-right: 30%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-right: 10%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-bottom: 24px;
    padding-right: 0;
  }
`;

export const LeftContainer = styled.div`
  width: 60%;
  overflow-y: auto;
  transition: all .3s ease;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 55%;
    overflow-y: auto;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 100%;
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 40%;
  background-image: url(${BackImage});
  background-repeat: no-repeat;
  min-width: 500px;
  background-position: center;
  background-size: cover;
  transition: all .3s ease;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 45%;
    min-width: 300px;
    background-position: right;
  }
`;

export const SubText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-top: 24px;
  margin-bottom: 64px;
`;

export const CircleContainer = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 80px;
  left: 50px;
`;

export const BackgroundLine = styled.div`
  border: 1px solid #B0B7C3;
  position: absolute;
  width: ${({ $size }) => $size || '100%'};
  height: ${({ $size }) => $size || '100%'};
  border-radius: 100%;
  opacity: .3;
`;

export const Form = styled.div`
  position: relative;
  /* bottom: 30px; */
  /* width: 70%; */
  top: 15%;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 30px;
  /* padding: 100px 0; */
  /* overflow-y: auto; */

  transition: all .3s ease;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    height: auto;
    width: 100%;
    bottom: 0;
  }
`;

export const BackButtonArea = styled.button`
  z-index: 1;
  outline: none;
  border: none;
  background-color: transparent;
  
  /* position: absolute; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 24px;
  left: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #B0B7C3;

  cursor: pointer;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: unset;
    justify-content: flex-start;
    font-size: 14px;
    /* font-size: 12px; */
    letter-spacing: 0.05em;
    margin-top: 12px;
    margin-bottom: 32px;
  }
`;

export const BackButton = styled.div`
  width: 38px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  display: grid;
  place-items: center;
  margin-right: 16px;
  & > svg {
    transform: rotate(270deg);
   & > path {
      fill: #B0B7C3;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* width: 22px;
    height: 22px; */
    width: 28px;
    height: 28px;
    margin-right: 8px;
    & > svg > path {
      width: 6.7px;
      height: 3.95px;
    }
  }
`;

export const Content = styled.div`

`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 52px;
`;

export const Timer = styled.div`
  width: 72px;
  height: 72px;
  background: #FFFFFF;
  border: 1.12963px solid #203662;
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-bottom: 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #203662;
`;

export const TimerTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`;

export const TimerText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-bottom: 5px;
`;

export const ReSendBtn = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  text-decoration-line: underline;
  color: #0064A8;
  transition: color .3s ease-in-out;
  &:disabled {
    color: #B0B7C3;
    cursor: not-allowed;
  }
`;
