
import { createRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'assets/img/icons/error.svg';
import {
  Wrapper,
  InputContainer,
  Input,
  Label,
  ErrorContainer,
  ErrorText,
  IconContainer,
} from './style';

const TextInput = ({
  type,
  label,
  placeholder,
  value,
  onChange,
  error,
  required,
  disabled,
  fullWidth,
  containerStyle,
  inputStyle,
  labelStyle,
  labelRight,

  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  rightIconClick,

  isResponsive,
  iconStyle,
  ...props
}) => {
  const inputRef = createRef();

  return (
    <Wrapper $containerStyle={containerStyle} $responsive={isResponsive} $fullWidth={fullWidth}>
      {label && <Label $labelStyle={labelStyle} /* $error={!!error} */ onClick={() => inputRef.current.focus()}>{label} {labelRight}</Label>}
      <InputContainer>
        {LeftIcon
          &&
          <IconContainer
            $isLeft={true}
            onClick={() => inputRef.current.focus()}
            $iconStyle={iconStyle}
          >
            <LeftIcon />
          </IconContainer>
        }
        <Input
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          $error={!!error}
          $hasLeftIcon={!!LeftIcon}
          $hasRightIcon={!!RightIcon}
          $responsive={isResponsive}
          pattern={type === 'email' ? '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$' : null}
          $inputStyle={inputStyle}
          {...props}
        />
        {RightIcon
          &&
          <IconContainer
            $isLeft={false}
            onClick={() => { !disabled && rightIconClick?.() }}
            $responsive={isResponsive}
            $iconStyle={iconStyle}
          >
            <RightIcon />
          </IconContainer>
        }
        {!!error && <ErrorContainer>
          {typeof error !== 'boolean' && <ErrorIcon />}
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
        }
      </InputContainer>

    </Wrapper>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
};

TextInput.defaultProps = {
  label: '',
  placeholder: '',
  type: 'text',
  required: false,
  disabled: false,
  containerStyle: {},
  inputStyle: {},
  onChange: () => { },
};

export default TextInput;