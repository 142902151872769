import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 80px;
`;

export const BackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  /* position: relative; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  cursor: pointer;
  user-select: none;
  position: sticky;
  top: 24px;
  margin-top: 24px;
  svg {
    margin-right: 17px;
  }
`;

export const Container = styled.div`
  position: relative;
  top: 14px;
  width: calc(100% - 14px);
  /* height: calc(100vh - 180px); */
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: flex-end;
`;

export const Title = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  max-width: 85%;
  /* display: flex; */
  /* align-items: center; */
`;

export const QAModalTitleContainer = styled.div`
  display: flex;
  align-items: center;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #203662;

  svg {
    margin-right: 6px;
    path {
      fill: #203662;
    }
  }
`;

export const DetailButton = styled.span`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  user-select: none;
  margin-left: 12px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #0064A8;

  svg {
    margin-left: 5px;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
