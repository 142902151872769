import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  /* position: relative; */
  &::-webkit-scrollbar {
    display: none;
  };
  & > div {
    width: calc(100% / 4);
    max-width: 500px;
  }
`;
