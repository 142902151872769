import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8.5px 24px;
  background-color: #fff;
  align-items: center;
  position: fixed;
  top: 0;
  /* z-index: 100; */
  width: calc(100vw - 48px);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: fixed;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12));
    width: calc(100vw - 24px);
    z-index: 100;
    top: 0;
    padding: 12px;
    a {
      display: flex;
      align-items: center;
    }
    a > svg {
      width: 116px;
      height: 32px;
    }
  }
  @media only screen and (orientation: landscape) and (max-height: 768px) and (max-width: 900px) {
    z-index: 100;
  }
`;

export const SelectContainer = styled.select`
  width: 150px;
  height: 40px;
  border: 1px solid #0064A8;
  border: none;
  outline: none;
  background-color: #FAFBFC;
  border-radius: 16px;
  /* padding: 0 10px; */
  user-select: none;
  
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M9.87998 1.28957L5.99998 5.16957L2.11998 1.28957C1.72998 0.89957 1.09998 0.89957 0.70998 1.28957C0.31998 1.67957 0.31998 2.30957 0.70998 2.69957L5.29998 7.28957C5.68998 7.67957 6.31998 7.67957 6.70998 7.28957L11.3 2.69957C11.69 2.30957 11.69 1.67957 11.3 1.28957C10.91 0.90957 10.27 0.89957 9.87998 1.28957Z' fill='%23B0B7C3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 18px center;

  padding: 0 32px 0 22px;

  color: #B0B7C3;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  text-transform: uppercase;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-weight: 500;
    width: 77px;
  }
`;
