
const eventSpeaker = [
  {
    name: 'Merve Demir',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: 'https://virtualdr.ca/wp-content/uploads/2022/02/online-doctor-Ontario.webp',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text.\n\n\n It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
  },
  {
    name: 'Abdülkadir Doyan',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'Yeditepe Üniversitesi Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: 'https://as1.ftcdn.net/v2/jpg/02/95/51/80/1000_F_295518052_aO5d9CqRhPnjlNDTRDjKLZHNftqfsxzI.jpg',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. \n\n\nRichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.\n\n\n The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
  },
  {
    name: 'Murat Kahraman',
    title: 'Doç. Dr.',
    profession: 'Mikrobiyoloji',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi',
    avatar: 'https://media.istockphoto.com/id/942262770/photo/portrait-of-female-doctor-against-wall-in-hospital.jpg?s=612x612&w=0&k=20&c=tBReoGH7Jr7moeawtl0JGke7Ew9pXRokoUoTYwHb93I=',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,\n\n making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.\n\n\n Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
  }
];
export const events = [
  {
    id: 1,
    title: 'Febril Nötropenik Hastalarda Antifungal Yönetimi ve Ambisome’un Yeri',
    logo: 'https://cdn.cookielaw.org/logos/bf68afa0-3c8d-4d2e-9472-6b54f14f5822/c0260794-d5a4-4dee-be60-c94a329c4569/14f6654a-d2ca-4468-a2e3-1c154ca392fd/Gilead_Logo_standard_RGB.png',
    banner: 'https://www.nobelkitabevi.com.tr/20519/febril-notropeni.jpg',
    description: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.',
    startDate: new Date(new Date().getTime() + 20 * 60000),
    endDate: new Date(new Date().getTime() + 1 * 60 * 60000),
    isPhysical: false,
    moderator: eventSpeaker[1],
    speakers: eventSpeaker,
    url: 'https://www.youtube.com/embed/LS_-S7imylQ'
  },
  {
    id: 2,
    title: 'Febril Nötropenik Hastalarda Antifungal Yönetimi ve Ambisome’un Yeri 2',
    logo: 'https://cdn.cookielaw.org/logos/bf68afa0-3c8d-4d2e-9472-6b54f14f5822/c0260794-d5a4-4dee-be60-c94a329c4569/14f6654a-d2ca-4468-a2e3-1c154ca392fd/Gilead_Logo_standard_RGB.png',
    banner: 'https://www.nobelkitabevi.com.tr/20519/febril-notropeni.jpg',
    description: '2 In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.',
    startDate: new Date(new Date().getTime() + 40 * 60000),
    endDate: new Date(new Date().getTime() + 2 * 60 * 60000),
    isPhysical: true,
    moderator: eventSpeaker[1],
    speakers: [...eventSpeaker, ...eventSpeaker],
    url: 'https://www.youtube.com/embed/LS_-S7imylQ'
  },
  {
    id: 3,
    title: 'Febril Nötropenik Hastalarda Antifungal Yönetimi ve Ambisome’un Yeri 3',
    logo: 'https://cdn.cookielaw.org/logos/bf68afa0-3c8d-4d2e-9472-6b54f14f5822/c0260794-d5a4-4dee-be60-c94a329c4569/14f6654a-d2ca-4468-a2e3-1c154ca392fd/Gilead_Logo_standard_RGB.png',
    banner: 'https://www.nobelkitabevi.com.tr/20519/febril-notropeni.jpg',
    description: '2 In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.',
    startDate: new Date(new Date().getTime() + 44 * 60000),
    endDate: new Date(new Date().getTime() + 3 * 60 * 60000),
    isPhysical: false,
    moderator: eventSpeaker[1],
    speakers: [...eventSpeaker, ...eventSpeaker],
    url: 'https://www.youtube.com/embed/LS_-S7imylQ'
  },
];

export const speakers = [
  {
    name: 'Merve Demir',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: 'https://virtualdr.ca/wp-content/uploads/2022/02/online-doctor-Ontario.webp',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text.\n\n\n It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: [...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events, ...events]
  },
  {
    name: 'Murat Kahraman',
    title: 'Doç. Dr.',
    profession: 'Mikrobiyoloji',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi',
    avatar: 'https://media.istockphoto.com/id/942262770/photo/portrait-of-female-doctor-against-wall-in-hospital.jpg?s=612x612&w=0&k=20&c=tBReoGH7Jr7moeawtl0JGke7Ew9pXRokoUoTYwHb93I=',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,\n\n making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.\n\n\n Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: [events[0]]
  },
  {
    name: 'Merve Demir',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: '',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: events
  },
  {
    name: 'Abdülkadir Doyan',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'Yeditepe Üniversitesi Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: 'https://as1.ftcdn.net/v2/jpg/02/95/51/80/1000_F_295518052_aO5d9CqRhPnjlNDTRDjKLZHNftqfsxzI.jpg',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. \n\n\nRichard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance.\n\n\n The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: events
  },
  {
    name: 'Merve Demir',
    title: 'Dr. Öğretim Üyesi',
    profession: 'Anestezi ve Reanimasyon',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi Eğitim ve Araştırma Hastanesi Hemotoloji Birimi',
    avatar: 'https://virtualdr.ca/wp-content/uploads/2022/02/online-doctor-Ontario.webp',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.\n\n\n Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: events
  },
  {
    name: 'Murat Kahraman',
    title: 'Doç. Dr.',
    profession: 'Mikrobiyoloji',
    jobLocation: 'İstanbul Üniversitesi Cerrahpaşa Tıp Fakültesi',
    avatar: 'https://media.istockphoto.com/id/942262770/photo/portrait-of-female-doctor-against-wall-in-hospital.jpg?s=612x612&w=0&k=20&c=tBReoGH7Jr7moeawtl0JGke7Ew9pXRokoUoTYwHb93I=',
    description: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin \n\n\nliterature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,\n\n\n consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`,
    events: events
  },
];

export const notifications = [
  {
    id: 1,
    type: 'EVENT_STARTED',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt lacinia, nunc est aliquam nisl, eu aliquet nunc nisl eget nunc. Sed euismod, nisl vel tincidunt lacinia, nunc est aliquam nisl, eu aliquet nunc nisl eget nunc.',
    startDate: new Date(new Date().getTime() + 40 * 60000),
    endDate: new Date(new Date().getTime() + 2 * 60 * 60000),
    isRead: false
  },
  {
    id: 2,
    type: 'EVENT_ENDED',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt lacinia, nunc est aliquam nisl, eu aliquet nunc nisl eget nunc. Sed euismod, nisl vel tincidunt lacinia, nunc est aliquam nisl, eu aliquet nunc nisl eget nunc.',
    startDate: new Date(new Date().getTime() + 40 * 60000),
    endDate: new Date(new Date().getTime() + 2 * 60 * 60000),
    isRead: false
  },
];