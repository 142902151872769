import React from 'react';
import Button from 'components/Button';
import {
  Container,
  IconArea,
  Title,
  Description,
  ButtonsContainer
} from '../style';
import CheckBox from 'components/CheckBox';

const ConfirmationModal = ({
  icon,
  title,
  description,
  onConfirm,
  closeModal,
  onConfirmText,
  onConfirmClick,
  cancelText = 'Cancel',
  isLeave
}) => {
  const [isRemind, setRemind] = React.useState(true);
  const handleOnConfirm = () => {
    onConfirmClick?.(isRemind);
    closeModal();
  };

  return (
    <Container>
      <IconArea>
        {icon}
      </IconArea>

      <Title>{title}</Title>

      <Description>{description}</Description>
      {!isLeave &&
        <CheckBox
          checked={isRemind}
          setChecked={e => setRemind(e)}
          label={<span>Hatırlatıcı ekle</span>}
        />
      }
      <ButtonsContainer>
        <Button
          fullSize
          outlined
          size={'md'}
          title={cancelText}
          containerStyle={{ marginRight: '20px' }}
          onClick={closeModal}
        />
        <Button
          fullSize
          size={'md'}
          title={onConfirmText}
          onClick={handleOnConfirm}
        />
      </ButtonsContainer>
    </Container>
  )
};

export default ConfirmationModal;
