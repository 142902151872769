import styled from 'styled-components';
import NotFoundImg from 'assets/img/images/not-found.png';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  position: relative;
  display: grid;
  place-content: center;
  width: 100%;
`;

export const BackImage = styled.div`
  position: relative;
  background: url(${NotFoundImg}) no-repeat center center;
  background-size: cover;
  width: 96vw;
  height: 90vh;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const OnBoardingArea = styled.div`
  background-color: #203662;
  border-radius: 48.8834px;
  max-width: 50%;
  padding: 20px;
  padding-bottom: 50px;
  height: auto;
  transition: all 0.3s ease-in-out;
  left: 2%;
  position: absolute;
  bottom: 4vh;
  background-color: #20366295;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 90%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xsm}px) {
    max-width: 80%;
  }
`;

export const OnBoardingTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 114.061px;
  line-height: 134px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  
  padding: 30px;
  padding-bottom: 12px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 50px;
    line-height: 64px;
    padding: 0 10px;
  }
`;

export const OnBoardingSubText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-left: 30px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
    margin-left: 10px;
  }
`;

export const GoHome = styled(Link)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  margin-left: 30px;

  display: flex;
  align-items: center;
  width: max-content;
  svg {
    margin-right: 20px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
  }
`;
