import React from 'react';
import Button from 'components/Button';
import {
  Container,
  Description
} from '../style';

const SuccessModal = ({
  onClose,
  modalType
}) => {
  return (
    <Container $sx={{ height: '76%', display: 'grid', placeContent: 'space-evenly' }}>
      <Description>{modalType} başarılı bir şekilde değiştirildi.</Description>
      <Button
        size={'md'}
        title={'Kapat'}
        fullSize
        containerStyle={{ marginBottom: '20px' }}
        onClick={() => onClose?.()}
      />
    </Container>
  )
};

export const SuccessUpdateModal = ({ onClose }) => {
  return (
    <Container $sx={{ height: '76%', display: 'grid', placeContent: 'space-evenly' }}>
      <Description>Güncelleme başarılı şekilde gerçekleştirildi.</Description>
      <Button
        size={'md'}
        title={'Kapat'}
        fullSize
        containerStyle={{ marginBottom: '20px' }}
        onClick={() => onClose?.()}
      />
    </Container>
  )
};

export default SuccessModal;
