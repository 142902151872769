import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 20px;
  padding-top: 10px;
  ${({ $sx }) => $sx || {}};
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  /* padding-right: 30%; */
  padding: 15px 20px;
  text-align: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-right: 10%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-bottom: 24px;
    padding-right: 0;
  }
`;
