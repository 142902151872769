import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 30px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 5px;
  }
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 180px 1fr;
  padding-bottom: 24px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-bottom: 10px;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    place-items: baseline;
  }
`;

export const Time = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

export const SectionSpeaker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-right: 24px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 16px;
  }
  /* gap: 58px; */
`;

export const SpeakerCard = styled.div`
  display: flex;
  width: 301px;
  height: 114px;
`;

export const SpeakerPhoto = styled.img`
  width: 56px;
  height: 72px;
  border-radius: 8px;
`;

export const SpeakerContext = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const TTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #B0B7C3;
`;

export const TName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #203662;
`;

export const TProfession = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #203662;
`;

export const TLocation = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: #B0B7C3;

  overflow: hidden;
  /* max-height: 20px; */

   text-overflow: ellipsis;
   display: -webkit-box;
   line-clamp: 4;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
`;