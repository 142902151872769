import styled, { css } from 'styled-components';
import { ReactComponent as Arrow } from 'assets/img/icons/arrow.svg';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: ${({ $fullWidth }) => $fullWidth ? '100%' : 'fit-content'};
  ${({ $hasTop }) => $hasTop && css`
    /* top: -8px; */
  `};
  ${({ $containerStyle }) => $containerStyle && css`
    ${$containerStyle}
  `};
`;

export const Label = styled.label`
  font-size: 12px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: ${({ $error }) => $error ? '#C50F3C' : '#203662'} ;
  padding: 0;
  padding-left: 16px;
  position: relative;
  ${({ $labelStyle }) => $labelStyle};
  white-space: nowrap;
  /* top: -7px; */
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 4px; */
  margin-top: 10px;
  padding: 0 16px;
  position: absolute;
`;

export const ErrorText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.005em;
  color: #C50F3C;
  margin-left: 6px;
`;

export const MobileWrapper = styled.div`
  margin-bottom: 15px;
  width: ${({ $fullWidth }) => $fullWidth ? '100%' : 'fit-content'};
  ${({ $containerStyle }) => $containerStyle};
`;

export const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  height: 56px;
  ${({ $containerStyle }) => $containerStyle};
`;

export const MobileInput = styled.input`
  outline: none;
  background-color: #fff;
  margin-top: 5px;
  
  padding-left: 16px;
  padding-right: 40px;

  border: 1px solid ${({ $error }) => $error ? '#C50F3C' : '#B0B7C3'};
  transition: border 0.3s ease-in-out;
  
  width: -webkit-fill-available;
  /* height: 56px; */
  height: inherit;
  border-radius: 16px;
  
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #54565B;
  
  ${({ $error }) => $error && css`
    :not(:focus) {
      box-shadow: 0px 12px 23px rgba(255, 86, 48, 0.06);
    }
  `}
  
  :focus {
    border: 1px solid #377DFF;
  }

  ${({ $open }) => $open && css`
    border: 1px solid #377DFF;
  `}

  &:disabled {
    opacity: 1;
    -webkit-text-fill-color: currentcolor;
    color: #54565B;
    -webkit-text-fill-color: #54565B;
    -webkit-opacity: 1;
    text-overflow: ellipsis;
  }

  ${({ $disabled }) => $disabled && css`
    cursor: not-allowed;
    opacity: .8;
  `}

  &::placeholder {
    user-select: none;
    -webkit-text-fill-color: currentcolor;
    color: #B0B7C3;
    -webkit-text-fill-color: #B0B7C3;
  }
`;

export const MobileLabel = styled.label`
  padding: 0;
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ $error }) => $error ? '#C50F3C' : '#203662'} ;
  ${({ $labelStyle }) => $labelStyle};
`;

export const MobileErrorText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.005em;
  color: #C50F3C;
  margin-left: 6px;
`;

export const MobileErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 0 16px;
  position: absolute;
`;

export const SheetHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SheetCloseBtn = styled.button`
  position: absolute;
  right: 16px;
  /* padding: 8.04px; */
  width: 24px;
  height: 24px;
  outline: none;
  box-sizing: border-box;
  position: absolute;
  background: #FFFFFF;
  border: 0.6px solid #E1E1E1;
  border-radius: 4.8px;
`;

export const MobileOptionContainer = styled.li`
  display: flex;
  align-items: center;
  border-color: ${({ $selected }) => $selected ? '#377DFF' : '#B0B7C3'};
  color: ${({ $selected }) => $selected ? '#377DFF' : '#B0B7C3'};
`;

export const OptionCircle = styled.div`
  width: 14px;
  height: 14px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 2px solid;
  margin-right: 8px;
  ::before {
    content: '';
    display: ${({ $selected }) => $selected ? 'block' : 'none'};
    width: 12px;
    height: 12px;
    background: #377DFF;
    border-radius: 100%;
    margin: 1px;
  }
`;

export const OptionText = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 250%;
  letter-spacing: 0.02em;
  color: inherit;
`;

export const OptionWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 16px;
  /* padding-bottom: 0; */
`;

export const IconContainer = styled.div`
  position: absolute;
  /* top: 27px; */
  top: ${({ $iconTop }) => $iconTop || 27}px;
  right: 17px;
`;

export const ArrowIcon = styled(Arrow)`
  width: 14px;
  height: 14px;
  transform: ${({ $open }) => $open ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: transform 0.3s linear;
  path {
    fill: #B0B7C3;
  }
`;
