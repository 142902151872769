import React from 'react';

const EventStatus = () => {
  return (
    <>
    </>
  )
}

export default EventStatus;
