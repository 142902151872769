import React from 'react';
import {
  CheckboxContainer,
  StyledCheckbox,
  Label
} from './style';

const CheckBox = ({
  checked,
  setChecked,
  label,
  containerStyle,
  disabled,
  hasButtonAction,
  Icon,
  error
}) => {

  const changeHandler = () => {
    !disabled && setChecked(!checked);
  };

  if (Icon) {
    return (
      <CheckboxContainer onClick={hasButtonAction ? () => { } : changeHandler} checked={checked} $containerStyle={containerStyle} $disabled={disabled}>
        <div onClick={hasButtonAction ? () => { } : changeHandler}>
          {Icon}
        </div>
        <Label onClick={changeHandler}>{label}</Label>
      </CheckboxContainer>
    )
  }

  return (
    <CheckboxContainer
      onClick={hasButtonAction ? () => { } : changeHandler}
      checked={checked}
      $containerStyle={containerStyle}
      $disabled={disabled}
      $error={error}
    >
      <StyledCheckbox onChange={changeHandler} checked={checked} $error={error} />
      <Label onClick={changeHandler}>{label}</Label>
    </CheckboxContainer>
  )
}

export default CheckBox;
