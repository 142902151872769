import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 22px 22px 22px;
  height: calc(100% - 82px);
  justify-content: space-between;
`;

export const IconArea = styled.div`
  width: 72px;
  height: 72px;
  background: #FFFFFF;
  border: 1.12963px solid #203662;
  border-radius: 100.286px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 16px; */
  margin-top: 12px;
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  text-align: center;
  padding: 15px 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 22px;
`;
