import React from 'react';
import { UserContext } from 'context/UserContext';
import Button from 'components/Button';
import OneTimeCode from 'components/OneTimeCode';
import ErrorContainer from 'components/ErrorContainer';
import { inputValidate } from 'helpers/validate';
import {
  Container,
  Description
} from '../style';

const ConfirmCode = ({
  modalType,
  changedData,
  onSuccess,
  modalTypes
}) => {
  const { changeMailConfirmCode, changePhoneConfirmCode } = React.useContext(UserContext);
  const [otp, setOTP] = React.useState('');
  const [stat, setStat] = React.useState({ loading: false, error: null });

  const handleConfirm = async () => {
    if (!!inputValidate(otp.trim(), 4, 'otp')) {
      // setStat({ loading: false, error: 'Lütfen e-posta adresinize gönderilen 4 haneli doğrulama kodunu giriniz' });
      setStat({ loading: false, error: `${modalTypes[modalType].codeBlankError}` });
      return;
    };
    setStat({ error: null, loading: true });
    try {
      const payload = {
        ...(modalType === 'email' && { email: changedData.email }),
        ...(modalType === 'phone' && { gsm: changedData.phone })
      };
      if (modalType === 'phone') {
        await changePhoneConfirmCode({ ...payload, code: otp });
      } else if (modalType === 'email') {
        await changeMailConfirmCode({ ...payload, code: otp });
      }
      // await changeMailConfirmCode({ ...payload, code: otp });
      setStat({ loading: false, error: null });
      // onClose?.();
      await onSuccess?.();
    } catch (error) {
      console.log('Profile :: Confirm code error :: ', error);
      setStat({ loading: false, error: error });
    }
  };

  return (
    <Container>
      <Description>{`${modalTypes[modalType].confirmTitle}`}</Description>
      <OneTimeCode
        value={otp}
        valueLength={4}
        onChange={val => { setOTP(val); setStat({ ...stat, error: null }) }}
        mobileStyle={{ justifyContent: 'center' }}
        containerStyle={{ justifyContent: 'center', marginBottom: '20px' }}
        error={!!stat.error}
      />
      {stat.error && <ErrorContainer message={stat.error} />}
      <Button
        size={'md'}
        title={'Onayla'}
        fullSize
        containerStyle={{ marginBottom: '20px' }}
        onClick={handleConfirm}
        loading={stat.loading}
      />
    </Container>
  )
};

export default ConfirmCode;
