import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LanguageContext } from 'context/withLanguages';
import { PATHS } from 'routes/paths';
import { slugify } from 'helpers';
import Seo from 'helpers/Seo';
import { getSingle } from 'services/contentsService';
import { ReactComponent as BackIcon } from 'assets/img/icons/back.svg';
import FSLoading from 'components/FSLoading';
import {
  Wrapper,
  BackButton,
  Container,
  ContentDetail,
  Title,
  TopHeader,
  TopBanner,
  LoaderContainer,
  ErrorContainer,
  ErrorText
} from './style';

const SingleContent = () => {
  const { slug } = useParams();
  const [content, setContent] = React.useState({ loaded: false, data: null, error: null });
  const { t, language } = React.useContext(LanguageContext);
  const nav = useNavigate();

  const parseSlugToId = (text) => {
    const textArr = text.split('-');
    const id = textArr[textArr.length - 1];
    return id;
  };

  const getContent = async () => {
    const contentId = parseSlugToId(slug);
    try {
      const data = await getSingle({ lang: language.code, id: contentId });
      setContent({ ...content, loaded: true, data });

      const slugText = slugify(`${data?.title}-${data?.id}`);
      if (slugText !== window.location.pathname.split('/')[2]) {
        window.history.replaceState(null, null, window.location.search ? slugText + window.location.search : slugText);
      }

    } catch (error) {
      setContent({ ...content, error: 'İçerik Bulunamadı.' });
    }
  };

  React.useEffect(() => {
    getContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const handleBack = () => {
    if (global.navigation.canGoBack) {
      window.history.back();
    } else {
      nav(PATHS.CONTENTS);
    }
  };

  if (content.error) {
    return <ErrorContainer>
      <ErrorText>{content.error}</ErrorText>
    </ErrorContainer>;
  }

  if (!content.loaded) {
    return <LoaderContainer>
      <FSLoading type='small' />
    </LoaderContainer>;
  }

  return (
    <Wrapper>
      <Seo title={`${content.data.title}`} />

      <BackButton
        onClick={handleBack}
      >
        <BackIcon />
        {t('words.back')}
      </BackButton>

      <TopHeader>
        <TopBanner src={content.data?.image} />
      </TopHeader>
      <Container>
        {/* <Banner src={content.data.image} /> */}
        {/* <BrandLogo src={content.data.product.logo} /> */}
        <Title>{content.data.title}</Title>
        <ContentDetail>{content.data.description}</ContentDetail>
      </Container>
    </Wrapper>
  )
}

export default SingleContent;
