import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import { eventFormatDateStartEnd, slugify } from 'helpers';
import ImagePlaceHolder from 'assets/img/images/image-placeholder.svg';
import { PATHS } from 'routes/paths';
import {
  Card,
  EventHeader,
  Context,
  Image,
  Title,
  Description,
  EventDetailArea,
  InfoArea,
  DateText,
  DetailBtn,
  LogoImage
} from './style';

const EventCard = ({
  id,
  title,
  logo,
  banner,
  description,
  startDate,
  endDate,
  eventType
}) => {
  const { t, language } = React.useContext(LanguageContext);
  
  const generateUrl = () => {
    try {
      const titleSlug = slugify(title);
      return `${PATHS.EVENTS}/${titleSlug + '-' + id}`;
      
    } catch (error) {
      return PATHS.EVENTS;
    }
  };

  return (
    <Card>
      <EventHeader>
        <Image
          $width={'56px'}
          // $width={'62px'}
          $height={'80%'}
          $border={true}
          src={banner}
          alt={title}
          onError={(e) => {
            e.onerror = null;
            e.target.src = ImagePlaceHolder;
            e.target.style.height = '75px';
            e.target.style.objectFit = 'scale-down';
          }}
        />
        <Context>
          <LogoImage
            $width={'fit-content'}
            // $maxWidth={'30%'}
            $height={'25px'}
            src={logo}
            alt={title}
            onError={(e) => {
              e.onerror = null;
              e.target.src = ImagePlaceHolder;
              e.target.style.objectFit = 'scale-down';
            }}
          />
          <Title title={title}>{title}</Title>
        </Context>
      </EventHeader>

      <Description title={description}>{description}</Description>

      <EventDetailArea>
        <DateText>{eventFormatDateStartEnd(startDate, endDate, language)}</DateText>
        <InfoArea>
          {/* {eventType && `${t('pages.home.physical_activities')} •\u00A0`} */}
          {eventType + ' •\u00A0'}
          <DetailBtn to={generateUrl()} >{t('words.detail')}</DetailBtn>
        </InfoArea>
      </EventDetailArea>
    </Card>
  )
};

export default EventCard;
