import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ $pd }) => $pd && `padding-right: ${$pd}px;`};
  padding-bottom: 20px;
  padding-top: 24px;
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  padding-bottom: 31px;
`;
