import React from 'react';
// import { LanguageContext } from 'context/withLanguages';
import Button from 'components/Button';
import { slugify } from 'helpers';
import ImagePlaceHolder from 'assets/img/images/image-placeholder.svg';
import { PATHS } from 'routes/paths';
import { Link } from 'react-router-dom';
import {
  Card,
  Container,
  Content,
  BannerImg,
  Title,
  Description,
  DetailContainer,
} from './style';

const ContentCard = ({
  id,
  title,
  image,
  description,
  is_active
}) => {
  // const { t, language } = React.useContext(LanguageContext);

  const generateUrl = () => {
    const titleSlug = slugify(title || '');
    // return `/contents/${titleSlug + '-' + id}`;
    return `${PATHS.SINGLE_CONTENT.split(':')[0]}${titleSlug + '-' + id}`;
  };

  return (
    <Card>
      <Container>
        <BannerImg
          src={image}
          alt={title}
          onError={(e) => {
            e.onerror = null;
            e.target.src = ImagePlaceHolder;
            e.target.style.objectFit = 'scale-down';
            e.target.style.border = '1.5625px solid #B0B7C3';
            e.target.style.padding = '0';
          }}
        />

        <Content>
          <div>
            <Title title={title}>{title}</Title>
            <Description>{description}</Description>
          </div>
          <DetailContainer>
            <Link to={generateUrl()}>
              <Button
                title={'Devamını Oku'}
                size={'sm'}
              />
            </Link>
          </DetailContainer>
        </Content>
      </Container>

    </Card>
  )
};

export default ContentCard;
