import React from 'react';
import { LanguageContext } from './withLanguages';
import {
  getAll
} from 'services/contentsService';

export const ContentsContext = React.createContext();

const initialState = {
  contents: {
    data: null,
    loading: false,
    error: null,
  }
};

const TYPE = {
  FETCH_CONTENTS_LOADING: 'FETCH_CONTENTS_LOADING',
  FETCH_CONTENTS_SUCCESS: 'FETCH_CONTENTS_SUCCESS',
  FETCH_CONTENTS_FAILED: 'FETCH_CONTENTS_FAILED'
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE.FETCH_CONTENTS_LOADING:
      return {
        ...state,
        contents: {
          ...state.contents,
          loading: true,
          error: null,
        },
      };
    case TYPE.FETCH_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: {
          ...state.contents,
          loading: false,
          data: action.payload.data,
        },
      };
    case TYPE.FETCH_CONTENTS_FAILED:
      return {
        ...state,
        contents: {
          ...state.contents,
          loading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export const ContentsProvider = ({ children }) => {
  const [states, dispatch] = React.useReducer(reducer, initialState);
  const { language } = React.useContext(LanguageContext);

  const getContents = async () => {
    dispatch({ type: TYPE.FETCH_CONTENTS_LOADING });
    try {
      const data = await getAll({ lang: language.code });
      dispatch({ type: TYPE.FETCH_CONTENTS_SUCCESS, payload: { data } });
    } catch (error) {
      dispatch({ type: TYPE.FETCH_CONTENTS_FAILED, payload: { error } });
    }
  };

  return (
    <ContentsContext.Provider
      value={{
        states,
        dispatch,
        getContents
      }}>
      {children}
    </ContentsContext.Provider>
  )
};
