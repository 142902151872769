import React from 'react';
import TextInput from 'components/TextInput';
import { Title, SubDescription, TextSection } from '../commonStyle';
import { LanguageContext } from 'context/withLanguages';
import {
  Wrapper,
} from './style';
import { useDimensions } from 'hooks/useDimensions';

const PersonalInfo = ({
  user,
  handleChangeText
}) => {
  const dims = useDimensions();
  const { t } = React.useContext(LanguageContext);

  return (
    <Wrapper>
      <Title
        dangerouslySetInnerHTML={{ __html: t('pages.register.personal_info.title') }}
      />
      <SubDescription>{t('pages.register.personal_info.subdescription')}</SubDescription>

      <TextSection $sx={dims.isMobile ? { marginTop: '24px' } : { marginTop: '48px' }}>
        <TextInput
          type={'text'}
          label={t('pages.register.personal_info.name_surname')}
          placeholder={t('pages.register.personal_info.name_surname_placeholder')}
          value={user.name.value}
          onChange={e => handleChangeText('name', e.target.value)}
          error={user.name.error}
          name='name'
          containerStyle={dims.isMobile ? { width: '100%' } : { width: '100%', marginRight: '24px' }}
        />

        <TextInput
          name={'idNumber'}
          label={t('pages.register.personal_info.id')}
          placeholder={t('pages.register.personal_info.id_placeholder')}
          value={user.idNumber.value}
          onChange={e => {
            if (e.target.value >= 0) {
              handleChangeText('idNumber', e.target.value);
            }
          }}
          error={user.idNumber.error}
          containerStyle={{ width: '100%' }}
          maxLength={11}
        />
      </TextSection>

      <TextSection $sx={dims.isMobile ? {} : { marginTop: '17px' }}>
        <TextInput
          type={'email'}
          name={'email'}
          label={t('pages.register.personal_info.email')}
          placeholder={t('pages.register.personal_info.email_placeholder')}
          value={user.email.value}
          onChange={e => handleChangeText('email', e.target.value)}
          error={user.email.error}
          fullWidth
          autocomplete={'email'}
          containerStyle={dims.isMobile ? { width: '100%' } : { width: '100%', marginRight: '24px' }}
        />
        <TextInput
          type={'tel'}
          name={'phone'}
          label={t('pages.register.personal_info.gsm')}
          placeholder={t('pages.register.personal_info.gsm_placeholder')}
          value={user.phone.value}
          onChange={e => {
            if (e.target.value >= 0) {
              handleChangeText('phone', e.target.value);
            }
          }}
          error={user.phone.error}
          containerStyle={{ width: '100%' }}
          maxLength={10}
          autoComplete={'tel'}
        />
      </TextSection>
    </Wrapper>
  )
};

export default PersonalInfo;
