export const AUTH_PATH = '/auth/login';

export const PATHS = Object.freeze({
  HOME: '/',
  SPEAKERS: '/speakers',
  CALENDAR: '/calendar',
  EVENTS: '/events',
  PROFILE: '/profile',
  SINGLE_EVENT: '/events/:slug',
  STAGE_AREA: '/stage-area/:slug',
  ZOOM_CLIENT: '/zoom-client/:id',
  EVENT_STATUS: '/events/watch-status',
  CONTENTS: '/contents',
  SINGLE_CONTENT: '/contents/:slug',

  AUTH: '/auth',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CHANGE_PASSWORD: '/auth/forgot-password/:token',
  VERIFY_ACCOUNT: '/auth/verify',
});
