import React from 'react';
import { AppContext } from 'context/AppContext';
import ProductBox from './components/ProductBox';
import {
  Wrapper
} from './style';

const CategoryBoxes = ({
  selectedCategory
}) => {
  const { appState, getCategories } = React.useContext(AppContext);

  React.useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (appState.categories.loading) {
    return (
      <Wrapper>
        {[...Array(4)].map((_, idx) => (
          <ProductBox key={`prod_box_${idx}`} loading />
        ))}
      </Wrapper>
    )
  };

  if (appState.categories.data?.length === 0) {
    return null;
  };

  return (
    <Wrapper>
      {appState.categories.data?.map((product, idx) => (
        <ProductBox
          key={`product_box_${idx}`}
          name={product.name}
          logo={product?.logo}
          color_type={product?.color_type}
          color={product?.color}
          event_count={product?.event_count}
          onClick={() => selectedCategory({ value: product.id, label: product.name })}
        />
      ))}
    </Wrapper>
  )
};

export default CategoryBoxes;
