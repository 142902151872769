import React from 'react';
import propTypes from 'prop-types';
import { timeFormatter } from 'helpers';
import {
  Container,
  Content,
  Time,
  Description
} from './style';

const EventCalendarTab = ({
  events
}) => {

  if (events.length === 0) {
    return null;
  }

  return (
    <Container>
      {events.map((event, idx) => {
        if (!event.is_moderator) {
          return (
            <Content key={`event_calendar_${idx}`}>
              <Time>{timeFormatter(event.start_date, event.end_date)}</Time>
              <Description>{event.description}</Description>
            </Content>
          )
        } else {
          return null;
        }
      })}
    </Container>
  )
};

EventCalendarTab.defaultProps = {
  events: []
};

EventCalendarTab.propTypes = {
  events: propTypes.array.isRequired
};

export default EventCalendarTab;
