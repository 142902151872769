import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 2px;
  width: calc(100% - 4px);
  /* width: 100%; */
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
  }
`;

export const SubDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-top: 16px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $sx }) => $sx}

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
  }
`;

export const SubTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  ${({ $sx }) => $sx}
`;

export const ConsentWarning = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  padding: 10px 0 5px 0;
`;

export const IqvaConsent = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin: 8px 0;

  ${({ $containerStyle }) => $containerStyle}

  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  ${({ $isChecked }) => !$isChecked && css`
    svg {
      path {
        fill: #B0B7C3;
      }
    }
  `};
`;
