import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  width: 25%;
  /* height: 210px; */
  height: 410px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;

`;

const avatarGap = 30;

export const Avatar = styled.img`
  position: relative;
  width: 130px;
  height: 100%;
  object-fit: cover;
  top: ${avatarGap}px;
  border-radius: 8px;
  user-select: none;
  pointer-events: none;
`;

export const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12px;
  padding-top: calc(${avatarGap}px + 20px);
  height: calc(100% - 40px);
  border-radius: 16px;
  width: calc(100% - 40px);
`;

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
`;

export const TTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #B0B7C3;
`;

export const TName = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #203662;
`;

export const TProfession = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #203662;
  margin-bottom: 12px;
`;

export const TLocation = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #B0B7C3;
  overflow: hidden;
  /* max-height: 20px; */

   text-overflow: ellipsis;
   display: -webkit-box;
   line-clamp: 4;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
`;

export const PlaceHolderAvatar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${avatarGap}px;
  width: 130px;
  height: 265px;
  padding: 25px 8px;
  border-radius: 8px;
  user-select: none;
  pointer-events: none;
  background: #8DC1C5;
`;

export const DetailBtn = styled.button`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.005em;
  color: #0064A8;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  outline: none;
  border: none;
  background-color: transparent;
`;
