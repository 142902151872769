import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 14px;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding-bottom: 20px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
  }
`;
