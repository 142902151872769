import errorHandler from './errorHandler';
import {
  contentInstance
} from 'services/http';

export const getAll = async ({ lang }) => {
  try {
    const { data } = await contentInstance({ lang }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getSingle = async ({ lang, id }) => {
  try {
    const { data } = await contentInstance({ lang }).get(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
