import React from 'react';
import { LanguageContext } from './withLanguages';
import {
  getAllCities,
  getAllBranches,
  getAllTitles,
  getAllProducts,
  getAllCategories
} from 'services/appService';
import {
  getDashboardCounts
} from 'services/dashboardService';
import { getAllEventTypes } from 'services/eventService';

export const AppContext = React.createContext();

const initialState = {
  cities: {
    data: null,
    loading: false,
    error: null,
  },
  branches: {
    data: null,
    loading: false,
    error: null,
  },
  titles: {
    data: null,
    loading: false,
    error: null,
  },
  products: {
    data: null,
    loading: false,
    error: null,
  },
  categories: {
    data: null,
    loading: false,
    error: null,
  },
  eventTypes: {
    data: null,
    loading: false,
    error: null,
  },
  dashboardCounts: {
    data: null,
    loading: false,
    error: null,
  },
};

const TYPE = {
  CITIES_FETCH_SUCCESS: 'CITIES_FETCH_SUCCESS',
  CITIES_FETCH_FAILED: 'CITIES_FETCH_FAILED',
  CITIES_FETCH_LOADING: 'CITIES_FETCH_LOADING',
  BRANCHES_FETCH_SUCCESS: 'BRANCHES_FETCH_SUCCESS',
  BRANCHES_FETCH_FAILED: 'BRANCHES_FETCH_FAILED',
  BRANCHES_FETCH_LOADING: 'BRANCHES_FETCH_LOADING',
  TITLES_FETCH_SUCCESS: 'TITLES_FETCH_SUCCESS',
  TITLES_FETCH_FAILED: 'TITLES_FETCH_FAILED',
  TITLES_FETCH_LOADING: 'TITLES_FETCH_LOADING',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_LOADING: 'GET_PRODUCTS_LOADING',
  GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_LOADING: 'GET_CATEGORY_LOADING',
  GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',

  GET_EVENT_TYPES_SUCCESS: 'GET_EVENT_TYPES_SUCCESS',
  GET_EVENT_TYPES_LOADING: 'GET_EVENT_TYPES_LOADING',
  GET_EVENT_TYPES_FAILED: 'GET_EVENT_TYPES_FAILED',

  GET_DASHBOARD_COUNTS_SUCCESS: 'GET_DASHBOARD_COUNTS_SUCCESS',
  GET_DASHBOARD_COUNTS_LOADING: 'GET_DASHBOARD_COUNTS_LOADING',
  GET_DASHBOARD_COUNTS_FAILED: 'GET_DASHBOARD_COUNTS_FAILED',
};

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE.CITIES_FETCH_SUCCESS:
      return {
        ...state,
        cities: {
          data: {
            ...state.cities.data,
            [action.payload.lang]: [...action.payload.data]
          },
          loading: false,
          error: null,
        }
      };
    case TYPE.CITIES_FETCH_FAILED:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.CITIES_FETCH_LOADING:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: true,
          error: null,
        }
      };
    case TYPE.BRANCHES_FETCH_SUCCESS:
      return {
        ...state,
        branches: {
          data: {
            ...state.branches.data,
            [action.payload.lang]: [...action.payload.data]
          },
          loading: false,
          error: null,
        }
      };
    case TYPE.BRANCHES_FETCH_FAILED:
      return {
        ...state,
        branches: {
          ...state.branches,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.BRANCHES_FETCH_LOADING:
      return {
        ...state,
        branches: {
          ...state.branches,
          loading: true,
          error: null,
        }
      };
    case TYPE.TITLES_FETCH_SUCCESS:
      return {
        ...state,
        titles: {
          data: {
            ...state.titles.data,
            [action.payload.lang]: [...action.payload.data]
          },
          loading: false,
          error: null,
        }
      };
    case TYPE.TITLES_FETCH_FAILED:
      return {
        ...state,
        titles: {
          ...state.titles,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.TITLES_FETCH_LOADING:
      return {
        ...state,
        titles: {
          ...state.titles,
          loading: true,
          error: null,
        }
      };
    case TYPE.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: [...action.payload.data],
          loading: false,
          error: null,
        }
      };
    case TYPE.GET_PRODUCTS_FAILED:
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.GET_PRODUCTS_LOADING:
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
          error: null,
        }
      };
    case TYPE.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          data: [...action.payload.data],
          loading: false,
          error: null,
        }
      };
    case TYPE.GET_CATEGORY_FAILED:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.GET_CATEGORY_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          error: null,
        }
      };
    case TYPE.GET_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        eventTypes: {
          data: {
            ...state.eventTypes.data,
            [action.payload.lang]: [...action.payload.data]
          },
          loading: false,
          error: null,
        }
      };
    case TYPE.GET_EVENT_TYPES_FAILED:
      return {
        ...state,
        eventTypes: {
          ...state.eventTypes,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.GET_EVENT_TYPES_LOADING:
      return {
        ...state,
        eventTypes: {
          ...state.eventTypes,
          loading: true,
          error: null,
        }
      };
    case TYPE.GET_DASHBOARD_COUNTS_SUCCESS:
      return {
        ...state,
        dashboardCounts: {
          ...state.dashboardCounts,
          data: action.payload.data,
          loading: false,
          error: null,
        }
      };
    case TYPE.GET_DASHBOARD_COUNTS_FAILED:
      return {
        ...state,
        dashboardCounts: {
          ...state.dashboardCounts,
          loading: false,
          error: action.payload.error,
        }
      };
    case TYPE.GET_DASHBOARD_COUNTS_LOADING:
      return {
        ...state,
        dashboardCounts: {
          ...state.dashboardCounts,
          loading: true,
          error: null,
        }
      };
    default:
      throw new Error();
  }
};

let timer = null;
export const AppProvider = ({ children }) => {
  const [appState, dispatch] = React.useReducer(reducer, initialState);
  const { language } = React.useContext(LanguageContext);

  const getCities = async () => {
    dispatch({ type: TYPE.CITIES_FETCH_LOADING });
    try {
      const data = await getAllCities({ lang: language.code });
      dispatch({ type: TYPE.CITIES_FETCH_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.CITIES_FETCH_FAILED, payload: { error } });
    }
  };

  const getBranches = async () => {
    dispatch({ type: TYPE.BRANCHES_FETCH_LOADING });
    try {
      const data = await getAllBranches({ lang: language.code });
      dispatch({ type: TYPE.BRANCHES_FETCH_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.BRANCHES_FETCH_FAILED, payload: { error } });
    }
  };

  const getTitles = async () => {
    dispatch({ type: TYPE.TITLES_FETCH_LOADING });
    try {
      const data = await getAllTitles({ lang: language.code });
      dispatch({ type: TYPE.TITLES_FETCH_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.TITLES_FETCH_FAILED, payload: { error } });
    }
  };

  const initData = async () => {
    if (!Object.keys(appState.cities.data || {}).includes(language.code)) {
      getCities();
    };
    if (!Object.keys(appState.branches.data || {}).includes(language.code)) {
      getBranches();
    };
    if (!Object.keys(appState.titles.data || {}).includes(language.code)) {
      getTitles();
    };
    if (!Object.keys(appState.eventTypes.data || {}).includes(language.code)) {
      getEventTypes();
    };
    // getCities();
    // getBranches();
    // getTitles();
  };

  React.useEffect(() => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    // TODO :: check if language changed timeout is needed
    timer = setTimeout(() => {
      initData();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language.code]);

  const getCitiesData = () => {
    return appState.cities.data?.[language.code] || [];
  };

  const getBranchesData = () => {
    return appState.branches.data?.[language.code] || [];
  };

  const getTitlesData = () => {
    return appState.titles.data?.[language.code] || [];
  };

  const getEventTypesData = () => {
    return appState.eventTypes.data?.[language.code] || [];
  };

  const getProducts = async () => {
    if (!!appState.products.data) {
      return appState.products.data;
    }
    dispatch({ type: TYPE.GET_PRODUCTS_LOADING });
    try {
      const data = await getAllProducts({ lang: language.code });
      dispatch({ type: TYPE.GET_PRODUCTS_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.GET_PRODUCTS_FAILED, payload: { error } });
    };
  };

  const getCategories = async () => {
    if (!!appState.categories.data) {
      return appState.categories.data;
    }
    dispatch({ type: TYPE.GET_CATEGORY_LOADING });
    try {
      const data = await getAllCategories({ lang: language.code });
      dispatch({ type: TYPE.GET_CATEGORY_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.GET_CATEGORY_FAILED, payload: { error } });
    };
  };

  const getEventTypes = async () => {
    dispatch({ type: TYPE.GET_EVENT_TYPES_LOADING });
    try {
      const data = await getAllEventTypes({ lang: language.code });
      dispatch({ type: TYPE.GET_EVENT_TYPES_SUCCESS, payload: { data, lang: language.code } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.GET_EVENT_TYPES_FAILED, payload: { error } });
    };
  };

  const getDashboardStats = async () => {
    dispatch({ type: TYPE.GET_DASHBOARD_COUNTS_LOADING });
    try {
      const data = await getDashboardCounts({ lang: language.code });
      dispatch({ type: TYPE.GET_DASHBOARD_COUNTS_SUCCESS, payload: { data } });
      return data;
    } catch (error) {
      dispatch({ type: TYPE.GET_DASHBOARD_COUNTS_FAILED, payload: { error } });
    };
  }

  return (
    <AppContext.Provider
      value={{
        appState,
        dispatch,
        getCities,
        getBranches,
        initData,
        getCitiesData,
        getBranchesData,
        getTitlesData,
        getProducts,
        getCategories,
        getEventTypesData,
        getDashboardStats,
      }}>
      {children}
    </AppContext.Provider>
  )
};
