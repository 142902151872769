import { ButtonUI, Loader, Dot, CustomContainer } from './style';
import PropTypes from 'prop-types';

const Button = ({
  title,
  fullSize,
  containerStyle,
  loading,
  size,
  outlined,
  disabled,
  hasPulseAnimation,
  pulseColor,
  ...props
}) => {
  return (
    <ButtonUI
      $fullSize={fullSize}
      $loading={loading}
      disabled={loading || disabled}
      $containerStyle={containerStyle}
      $size={size}
      $outlined={outlined}
      $hasPulseAnimation={hasPulseAnimation}
      $pulseColor={pulseColor}
      {...props}
    >
      {!loading ?
        title
        :
        <Loader>
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
          <Dot />
        </Loader>
      }
    </ButtonUI>
  )
};

export const CustomButton = ({
  Cmp,
  title,
  loading,
  dotColor,
  dotSize,
  ...props
}) => {
  if (Cmp === undefined) {
    return;
  }
  return (
    <Cmp
      $loading={loading}
      {...props}
    >
      <CustomContainer>
        {title}
        {loading
          &&
          <Loader $sx={{ marginLeft: '10px' }} $dotSize={dotSize}>
            <Dot $dotColor={dotColor} />
            <Dot $dotColor={dotColor} />
            <Dot $dotColor={dotColor} />
            <Dot $dotColor={dotColor} />
            <Dot $dotColor={dotColor} />
            <Dot $dotColor={dotColor} />
          </Loader>
        }
      </CustomContainer>
      {/* {!loading ?
        title
        :
        <Loader>
          <Dot $dotColor={dotColor} />
          <Dot $dotColor={dotColor} />
          <Dot $dotColor={dotColor} />
          <Dot $dotColor={dotColor} />
          <Dot $dotColor={dotColor} />
          <Dot $dotColor={dotColor} />
        </Loader>
      } */}
    </Cmp>
  )
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  fullSize: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  outlined: PropTypes.bool,
};

Button.defaultProps = {
  title: '',
  fullSize: false,
  containerStyle: {},
  size: 'sm',
  outlined: false,
};


export default Button;