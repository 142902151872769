import React from 'react';
import { ReactComponent as BellIcon } from 'assets/img/icons/bell.svg';
import { ReactComponent as NoEventIcon } from 'assets/img/icons/nf-event.svg';
import { LanguageContext } from 'context/withLanguages';
import { useDimensions } from 'hooks/useDimensions';
import { EventContext } from 'context/EventContext';
import FSLoading from 'components/FSLoading';
import CustomCalendar from 'components/Calendar';
// import { events } from 'helpers/mocks';
import SessionCard from '../SessionCard';
import {
  MobileWrapper,
  Wrapper,
  TopArea,
  NotificationButton,
  RedDot,
  Title,
  CalendarArea,
  SessionContainer,
  BlankContainer,
  BlankContent,
  BlankText,
} from './style';

export const BaseNotificationButton = ({ onClick, hasNew }) => {
  return (
    <NotificationButton onClick={onClick}>
      <BellIcon />
      <RedDot $active={hasNew} />
    </NotificationButton>
  )
};

const SidePart = React.forwardRef(({
  openNotification
}, ref) => {
  const { t } = React.useContext(LanguageContext);
  const { /* states: { events }, */ getCalendarEvents, getCalendarDots } = React.useContext(EventContext);
  const [events, setEvents] = React.useState({ data: [], loading: false, error: null });
  const dims = useDimensions();
  const [currentDate, onChangeDate] = React.useState(null);
  const [currentMY, setCurrentMY] = React.useState(null);
  const [eventDots, setEventDots] = React.useState({ data: [], loading: false, error: null });
  // const [eventDots, setEventDots] = React.useState({ data: demo, loading: false, error: null });

  React.useEffect(() => {
    if (currentMY) {
      getSelectedDateDots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMY]);

  const getSelectedDateDots = async () => {
    setEventDots({ ...eventDots, data: [], loading: true });
    try {
      const selectedCalendar = new Date(`${currentMY.month + 1}/1/${currentMY.year}`);
      // console.clear()
      const tsDate = selectedCalendar.getTime() / 1000;
      const data = await getCalendarDots({ today: tsDate });
      setEventDots({ ...eventDots, data, loading: false });
    } catch (error) {
      console.log('SidePart :: getSelectedDateDots error: ', error);
      setEventDots({ ...eventDots, error, loading: false });
    }
  };

  const isDateEqual = (startDate, endDate) => {
    try {
      return startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear();
    } catch (error) {
      return false;
    }
  };

  React.useEffect(() => {
    if (currentDate) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate])

  const getEvents = async () => {
    setEvents({ ...events, loading: true });
    try {
      // console.log('currentDate', new Date(currentDate));
      const eventList = await getCalendarEvents({ day: currentDate });
      setEvents({ ...events, data: eventList, loading: false });
      return eventList;
    } catch (error) {
      setEvents({ ...events, error, loading: false });
      // console.log('getEvents error: ', error);
      return [];
    }
  };

  const changeMonth = (date) => {
    setCurrentMY(date);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const eventList = React.useMemo(() => getEvents(), [currentDate, events.loading]);

  if (dims.isMobile) {
    return (
      <MobileWrapper>
        <CalendarArea>
          <CustomCalendar
            isMini={true}
            dayContainerStyle={{ fontSize: '13px' }}
            containerStyle={{ padding: 0 }}
            tableStyle={{ background: '#fff' }}
            events={events.data}
            changeDate={onChangeDate}
            onCalendarChange={changeMonth}
            eventDots={eventDots.data}
            loading={eventDots.loading}
          />
        </CalendarArea>

        <SessionContainer>
          {events.loading && <FSLoading type='small' containerStyle={{ padding: '113px 0' }} />}
          {!events.loading &&
            (
              events.data?.length > 0 ?
                events.data.map((event, idx) => (
                  <SessionCard
                    key={`event-${idx}`}
                    {...event}
                  />
                ))
                :
                <BlankContainer>
                  <BlankContent>
                    <NoEventIcon />
                    <BlankText>{t('pages.calendar.no_events_today')}</BlankText>
                  </BlankContent>
                </BlankContainer>
            )
          }
        </SessionContainer>
      </MobileWrapper>
    )
  };

  return (
    <Wrapper ref={ref} id={'side_part'}>
      <TopArea>
        <Title>{t('words.calendar')}</Title>
        {/* <BaseNotificationButton
          onClick={() => openNotification?.()}
          hasNew
        /> */}
      </TopArea>

      <CalendarArea>
        <CustomCalendar
          isMini={true}
          dayContainerStyle={{ fontSize: '13px' }}
          containerStyle={{ padding: 0 }}
          tableStyle={{ background: '#fff' }}
          events={events.data}
          changeDate={onChangeDate}
          onCalendarChange={changeMonth}
          eventDots={eventDots.data}
          loading={eventDots.loading}
        />
      </CalendarArea>

      <SessionContainer>
        {events.loading && <FSLoading type='small' containerStyle={{ paddingTop: '40px' }} />}
        {!events.loading &&
          (
            events.data?.length > 0 ?
              events.data.map((event, idx) => (
                <SessionCard
                  key={`event-${idx}`}
                  {...event}
                />
              ))
              :
              <BlankContainer>
                <BlankContent>
                  <NoEventIcon />
                  <BlankText>{t('pages.calendar.no_events_today')}</BlankText>
                </BlankContent>
              </BlankContainer>
          )
        }
      </SessionContainer>
    </Wrapper>
  )
});

export default SidePart;
