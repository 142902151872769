import React from 'react';
import { ReactComponent as BackIcon } from 'assets/img/icons/arrow.svg';
import { useNavigate } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import Button from 'components/Button';
import Seo from 'helpers/Seo';
import ErrorContainer from 'components/ErrorContainer';
import { UserContext } from 'context/UserContext';
import { inputValidate } from 'helpers/validate';
import OneTimeCode from 'components/OneTimeCode';
import { parseQuery } from 'helpers';
import { resendVerificationCode } from 'services/authService';
import { LanguageContext } from 'context/withLanguages';
import { useDimensions } from 'hooks/useDimensions';
import {
  Wrapper,
  Title,
  SubDescription,
  LeftContainer,
  RightContainer,
  CircleContainer,
  BackgroundLine,
  Form,
  BackButtonArea,
  BackButton,
  Content,
  TimerContainer,
  Timer,
  TimerTexts,
  TimerText,
  ReSendBtn
} from './style';

const TIMER_COUNT = 180;
let timerId = null;
const Verify = () => {
  const { verifyUser, user, logout } = React.useContext(UserContext);
  const { t, language } = React.useContext(LanguageContext);
  const [process, setProcess] = React.useState({ loading: false, error: '' });
  const [otp, setOtp] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [timer, setTimer] = React.useState(TIMER_COUNT);
  const nav = useNavigate();
  const dims = useDimensions();

  React.useEffect(() => {
    const parsedQuery = parseQuery(window.location.search);
    if (parsedQuery?.email) {
      setEmail(parsedQuery.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counter = () => {
    if (timerId !== null) {
      clearInterval(timerId);
    }
    timerId = setInterval(() => {
      setTimer((prev) => {
        if (prev === 0) {
          clearInterval(timerId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  React.useEffect(() => {
    counter();
    return () => clearInterval(timerId);
  }, []);

  const handleReSendCode = async () => {
    if (timer > 0) {
      return;
    }
    try {
      await resendVerificationCode({ email: email || user.email, lang: language.code });
      setTimer(TIMER_COUNT);
      counter();
    } catch (error) {
      console.log('resend code error:', error);
    }
  };

  const handleBack = () => {
    logout();
  };

  const handleVerifyUser = async () => {
    if (timer === 0) {
      setProcess({ loading: false, error: '' });
      return;
    };

    if (!!inputValidate(otp.trim(), 4, 'otp')) {
      setProcess({ loading: false, error: 'Lütfen e-posta adresinize gönderilen 4 haneli doğrulama kodunu giriniz' });
      return;
    };

    clearInterval(timerId);
    setProcess({ loading: true, error: '' });
    try {
      await verifyUser({ code: otp, email: email || user.email });
      setProcess({ loading: false, error: '' });
      user.is_verified = true;
      // TODO :: fix next line to new get user
      localStorage.setItem('user', JSON.stringify({ ...user, is_verified: true }));
      nav(PATHS.HOME);
    } catch (error) {
      setProcess({ loading: false, error: error });
      counter();
    }
  };

  return (
    <Wrapper
      initial={{ opacity: 0, x: window.innerWidth }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: window.innerWidth }}
    >
      <Seo
        title={t('pages.verify.verify_membership_title')}
      />

      <LeftContainer>
        <BackButtonArea onClick={handleBack} >
          <BackButton>
            <BackIcon />
          </BackButton>
          {t('words.sign_out')}
        </BackButtonArea>

        <CircleContainer>
          <BackgroundLine $size={'100px'} />
          <BackgroundLine $size={'220px'} />
          <BackgroundLine $size={'320px'} />
          <BackgroundLine $size={'420px'} />
          <BackgroundLine $size={'520px'} />
          <BackgroundLine $size={'620px'} />
          <BackgroundLine $size={'720px'} />
          <BackgroundLine $size={'820px'} />
          <BackgroundLine $size={'920px'} />
        </CircleContainer>

        <Form>
          <Title
            dangerouslySetInnerHTML={{ __html: t('pages.verify.verify_membership') }}
          />
          <SubDescription>{t('pages.verify.verify_membership_description')}</SubDescription>

          <Content>
            <TimerContainer>
              <Timer>{timer}</Timer>
              <TimerTexts>
                <TimerText>{t('pages.verify.resend_code')}</TimerText>
                <ReSendBtn
                  onClick={handleReSendCode}
                  disabled={timer > 0}
                >{t('pages.verify.resend')}</ReSendBtn>
              </TimerTexts>
            </TimerContainer>

            <OneTimeCode
              value={otp}
              valueLength={4}
              onChange={val => setOtp(val)}
              mobileStyle={{ justifyContent: 'flex-start' }}
              error={process.error}
            />
          </Content>

          {process.error &&
            <ErrorContainer
              message={process.error}
              sx={{ marginTop: '33px' }}
            />
          }
          <Button
            title={t('pages.verify.verify_button')}
            onClick={handleVerifyUser}
            loading={process.loading}
            disabled={timer === 0}
            size={'md'}
            containerStyle={dims.isMobile ? { width: '100%', marginTop: '24px' } : {}}
          />
        </Form>

      </LeftContainer>

      {!dims.isMobile &&
        <RightContainer />
      }

    </Wrapper>
  )
};

export default Verify;
