import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #F1F5FF;
`;

const pulse = keyframes`
  0% {
    transform: scale(.95);
    /* box-shadow: 0 0 0 0 rgba(197, 15, 60, 0.7); */
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(197, 15, 60, 0);
  }
  100% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(197, 15, 60, 0);
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const LogoContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulse} 1.5s infinite ease-in-out;
  margin-bottom: 15px;
  ${({ $sx }) => $sx || {}};
`;

export const WrapperSmall = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $containerStyle }) => $containerStyle || {}};
`;

export const ContentSmall = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
