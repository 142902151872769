import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Card,
  ShapeTop,
  Content,
  StatText,
  IconArea,
  Text,
  CountText
} from './style';

const StatCard = ({
  icon: Icon,
  value,
  text,
  overlayColor,
  bg,
  isLoading
}) => {
  return (
    <Card $bg={bg}>
      <ShapeTop $overlayColor={overlayColor} />
      <Content>
        <IconArea>
          {Icon && <Icon />}
        </IconArea>
        <StatText>
          <CountText>{
            isLoading ? <Skeleton
              count={1}
              height={30}
              baseColor={'#FFFCF9'}
              style={{ opacity: .4 }}
              highlightColor={'#21212150'}
            /> :
              value
          }</CountText>
          <Text>{text}</Text>
        </StatText>
      </Content>
    </Card>
  )
};

export default StatCard;
