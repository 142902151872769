import styled from 'styled-components';
import BackImage from 'assets/img/images/forgot-bg.svg';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-bottom: 50px;
    min-height: calc(100vh - 140px);
    flex-direction: column;
    width: 94%;
    margin: 0 auto;
  }
`;

export const LeftContainer = styled.div`
  width: 50%;
  overflow-y: auto;
  transition: all .3s ease;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    overflow-y: auto;
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    width: 60%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* display: flex; */
    /* flex-direction: column; */
    height: auto;
    overflow-y: unset;
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  width: 50%;
  background-image: url(${BackImage});
  background-repeat: no-repeat;
  min-width: 500px;
  background-position: center;
  background-size: cover;
  transition: all .3s ease;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 40%;
    min-width: 300px;
    background-position: right;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  /* color: #203662; */
  color: #11142D;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #203662;
  }
`;

export const SubText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-top: 16px;
  margin-bottom: 41px;
  
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    margin-bottom: 24px;
  }
`;

export const CircleContainer = styled.div`
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 80px;
  left: 50px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: fixed;
  }
`;

export const BackgroundLine = styled.div`
  border: 1px solid #B0B7C3;
  position: absolute;
  width: ${({ $size }) => $size || '100%'};
  height: ${({ $size }) => $size || '100%'};
  border-radius: 100%;
  opacity: .3;
`;

export const Form = styled.div`
  position: relative;
  width: 70%;
  top: 60px;
  margin: 0 auto;
  /* padding-bottom: 30px; */
  padding-bottom: 10px;
  transition: all .3s ease;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
    top: 30px;
    height: auto;
    width: 100%;
    bottom: 0;
  }
`;

export const BackButtonArea = styled.button`
  z-index: 1;
  outline: none;
  border: none;
  background-color: transparent;
  
  /* position: absolute; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  top: 24px;
  left: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #B0B7C3;

  cursor: pointer;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    position: unset;
    justify-content: flex-start;
    font-size: 14px;
    /* font-size: 12px; */
    letter-spacing: 0.05em;
    margin-top: 12px;
    /* margin-bottom: 32px; */
  }
`;

export const BackButton = styled.div`
  width: 38px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  display: grid;
  place-items: center;
  margin-right: 16px;
  & > svg {
    transform: rotate(270deg);
   & > path {
      fill: #B0B7C3;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    /* width: 22px;
    height: 22px; */
    width: 28px;
    height: 28px;
    margin-right: 8px;
    & > svg > path {
      width: 6.7px;
      height: 3.95px;
    }
  }
`;

export const PasswordInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;  
`;

export const PasswordSecurityContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  margin-bottom: 25px;
  &>:not(:last-child) {
    margin-right: 32px;
  }
`;

export const PasswordSecurity = styled.div`
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: ${({ $isFine }) => $isFine ? '#688C38' : '#B0B7C3'};
`;

export const PasswordInfoContext = styled.div`
  display: flex;
  align-items: center;
`;

export const PasswordInfoText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 156%;
  letter-spacing: 0.005em;
  color: #54565B;
  margin-left: 5px;
`;
