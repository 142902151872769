import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

export function useDimensions() {
  const theme = useTheme();

  const hasWindow = typeof window !== 'undefined';

  const getDeviceInfo = (userAgent) => {
    const isAndroid = () => Boolean(userAgent.match(/Android/i));
    const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
    const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
    const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
    const isSSR = () => Boolean(userAgent.match(/SSR/i));
    const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows());
    const isDesktop = () => Boolean(!isMobile() && !isSSR());
    const isTablet = () => Boolean(/ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(navigator.userAgent.toLowerCase()));

    const getOrientation = () => {
      let orientation;
      const or = window.orientation || window.screen.orientation;
      if (Math.abs(or) === 90) {
        orientation = 'landscape';
      } else {
        orientation = 'portrait';
      }
      return orientation;
    };

    return {
      isMobile: isMobile(),
      isDesktop: isDesktop(),
      isAndroid: isAndroid(),
      isIos: isIos(),
      isTablet: isTablet(),
      isSSR: isSSR(),
      orientation: getOrientation()
    }
  };

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    const deviceInfo = getDeviceInfo(navigator.userAgent);
    return {
      width,
      height,
      // isMobile: width < theme.breakpoints.md,
      isMobile: width < theme.breakpoints.md || deviceInfo.isMobile,
      isTablet: width < theme.breakpoints.lg || deviceInfo.isTablet,
      orientation: deviceInfo.orientation
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    // eslint-disable-next-line
  }, [hasWindow]);

  return windowDimensions;
};
