import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { PATHS } from 'routes/paths';
import { LanguageContext } from 'context/withLanguages';
import { UserContext } from 'context/UserContext';
import { AppContext } from 'context/AppContext';
import { useDimensions } from 'hooks/useDimensions';
import { ReactComponent as ProfileLogo } from 'assets/img/icons/nav_menu/profile.svg';
import { ReactComponent as CalendarLogo } from 'assets/img/icons/calendar.svg';
import { ReactComponent as EventDoneLogo } from 'assets/img/icons/event-done.svg';
import { ReactComponent as CalendarBtnIcon } from 'assets/img/icons/calendar-button.svg';
// import CardSlider from 'components/CardSlider';
import Modal from 'components/Modal';
import { notifications } from 'helpers/mocks'; // TODO :: Mock data
import { StatCard, /* SpeakerCard, EventCard, */ SidePart, Notifications, CategoryBoxes, Events } from './components';
// import { BaseNotificationButton } from './components/SidePart';
import Onboarding from 'components/Onboarding';
import {
  Container,
  HeaderContainer,
  UserName,
  WelcomeText,
  ProfileButton,
  CardsArea,
  Stats,
  LeftContainer,
  UserHeader,
  RightContainer,
  CalendarButtom
} from './style';

const Home = () => {
  const [navAreaW, setNavAreaW] = React.useState(0);
  const { appState, getDashboardStats } = React.useContext(AppContext);
  const { t } = React.useContext(LanguageContext);
  const { userProfile: user } = React.useContext(UserContext);
  // const nav = useNavigate();
  const sideRef = React.useRef(null);
  const notifyModalRef = React.useRef(null);
  const eventModalRef = React.useRef(null);
  const { isMobile, width } = useDimensions();
  const [selectedTerapatic, setTerapatic] = React.useState(null)

  React.useEffect(() => {
    getDashboardStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useLayoutEffect(() => {
    setNavAreaW(sideRef.current?.offsetWidth);
  }, [isMobile, width]);

  return (
    <Container $pd={isMobile ? 0 : navAreaW}>
      <Modal ref={notifyModalRef} title={t('words.notifications')}>
        <Notifications
          notifications={notifications}
        />
      </Modal>
      {isMobile &&
        <Modal
          ref={eventModalRef}
          mobileHeightRatio={1.07}
          title={t('pages.calendar.calendar')}
        >
          <SidePart />
        </Modal>
      }

      <HeaderContainer>
        <LeftContainer>
          <UserHeader>
            <UserName>{t('pages.home.hi')} {user.name_surname},</UserName>
            <ProfileButton to="/profile">
              <ProfileLogo />
            </ProfileButton>
          </UserHeader>
          <WelcomeText>{t('pages.home.welcome')}!</WelcomeText>
        </LeftContainer>
        {isMobile &&
          <RightContainer>
            <CalendarButtom onClick={() => eventModalRef.current?.openModal()}>
              <CalendarBtnIcon />
            </CalendarButtom>

            {/* <BaseNotificationButton
              onClick={() => notifyModalRef.current?.openModal()}
            /> */}
          </RightContainer>
        }
      </HeaderContainer>

      {/* Onboarding */}
      {/* <Onboarding /> */}

      {/* Cards */}
      <CardsArea id={'stat_cards'}>
        {/* <BannerCard /> */}
        <CategoryBoxes
          selectedCategory={e => setTerapatic(e)}
        />
        <Stats $isNoProduct={!appState.products.loading && appState.products.data?.length === 0}>
          <StatCard
            overlayColor={'#3B87FA'}
            bg={'#0064A8'}
            value={appState.dashboardCounts.data?.incoming_event_count}
            text={t('pages.home.upcoming_events')}
            icon={CalendarLogo}
            isLoading={appState.dashboardCounts.loading}
          />
          <StatCard
            overlayColor={'#68BFE3'}
            bg={"#8DC1C5"}
            value={appState.dashboardCounts.data?.joined_event_count}
            text={t('pages.home.attended_events')}
            icon={EventDoneLogo}
            isLoading={appState.dashboardCounts.loading}
          />
        </Stats>
      </CardsArea>
      {/* Events */}
      <Events selectedTerapatic={selectedTerapatic} />
      {!isMobile && <SidePart ref={sideRef} openNotification={notifyModalRef.current?.openModal} />}
    </Container>
  )
};

export default Home;
