import React from 'react';
// import propTypes from 'prop-types';
import { timeFormatter } from 'helpers';
import {
  Container,
  Section,
  Time,
  SectionSpeaker,
  SpeakerCard,
  SpeakerPhoto,
  SpeakerContext,
  TTitle,
  TName,
  TProfession,
  TLocation,
} from './style';

const SpeakersTab = ({
  sections,
}) => {
  return (
    <Container>
      <Section>
        {sections.map((section, ix) => {
          if (!section.is_moderator) {
            return (
              <React.Fragment key={`sec__${ix}`}>
                <Time>{timeFormatter(section.start_date, section.end_date)}</Time>
                <SectionSpeaker>
                  {section.speakers && section.speakers.map((speaker, idx) => (
                    <SpeakerCard key={`spekars_tab_${idx}`}>
                      <SpeakerPhoto src={speaker.image} alt={`speaker`} />
                      <SpeakerContext>
                        <TTitle>{speaker.title.name}</TTitle>
                        <TName>{speaker.name_surname}</TName>
                        <TProfession>{speaker.branch.name}</TProfession>
                        <TLocation>{speaker.institution}</TLocation>
                      </SpeakerContext>
                    </SpeakerCard>
                  ))}
                </SectionSpeaker>
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment key={`sec__${ix}`}>
                <Time>Moderator</Time>
                <SectionSpeaker>
                  {section.speakers && section.speakers.map((speaker, idx) => (
                    <SpeakerCard key={`spekars_tab_${idx}`}>
                      <SpeakerPhoto src={speaker.image} alt={`speaker`} />
                      <SpeakerContext>
                        <TTitle>{speaker.title.name}</TTitle>
                        <TName>{speaker.name_surname}</TName>
                        <TProfession>{speaker.branch.name}</TProfession>
                        <TLocation>{speaker.institution}</TLocation>
                      </SpeakerContext>
                    </SpeakerCard>
                  ))}
                </SectionSpeaker>
              </React.Fragment>
            )
          }
        }
        )}
      </Section>
    </Container>
  )
};

SpeakersTab.propTypes = {
  // spekaers: propTypes.arrayOf(propTypes.shape({
  //   avatar: propTypes.string,
  //   title: propTypes.string,
  //   name: propTypes.string,
  //   profession: propTypes.string,
  //   jobLocation: propTypes.string,
  // })),
};

SpeakerCard.defaultProps = {
  sections: [],
};

export default SpeakersTab;
