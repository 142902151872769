import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 10px;
`;

export const SpeakerInfoArea = styled.div`
  display: flex;
  /* padding: 0 24px; */
  /* padding-left: 24px;
  padding-right: 10px; */
  margin-top: 25px;
`;

export const Image = styled.img`
  width: 258px;
  height: 328px;
  border-radius: 50%;
  background: #8DC1C5;
  border-radius: 16px;
  user-select: none;
  pointer-events: none;
  object-fit: cover;
`;

export const SpeakerDetail = styled.div`
  padding: 0 24px;
  overflow-y: scroll;
  height: 328px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb:disabled {
    background: red;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #B0B7C3;
    border-radius: 4px;
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #B0B7C3;
  margin-bottom: 2px;
`;

export const Name = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #203662;
`;

export const Profession = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #203662;
  margin-bottom: 8px;
`;

export const JobLocation = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #54565B;
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #54565B;
  white-space: break-spaces;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(233, 234, 236, 0.6);
  margin-top: ${({ $mt }) => $mt || '8px'};
  margin-bottom: ${({ $mb }) => $mb || '16px'};
`;

export const SubTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #203662;
`;

export const EventsContainer = styled.div`

`;

export const SingleEvent = styled.div`
  margin-bottom: 16px;
`;

export const EventHeader = styled.div`
  display: flex;
`;

export const EventContext = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const EventImage = styled.img`
  max-width: ${({ $maxWidth }) => $maxWidth};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  border-radius: 4px;
  user-select: none;
  ${({ $border }) => $border && 'border: 0.5px solid #B0B7C3'}
`;

export const EventTitle = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #203662;

  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const EventDescription = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #54565B;
  margin-top: 6px;

  /* margin-top: 5px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
