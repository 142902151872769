import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrow.svg';
import { ReactComponent as NoEventIcon } from 'assets/img/icons/nf-event.svg';
import { isValidDate } from 'helpers';
import SessionCard from '../SessionCard';
import {
  Wrapper,
  Content,
  Container,
  TopArea,
  Title,
  Navigations,
  NavButton,
  BlankContainer,
  BlankContent,
  BlankText,
  NextDayBtn
} from './style';

const SidePart = React.forwardRef(({
  currentDate,
  nextDay,
  prevDay,
  events
}, ref) => {
  const { t, language } = React.useContext(LanguageContext);

  const formatDate = (d) => {
    const date = new Date(d);
    if (!isValidDate(date) || !d) {
      return;
    };
    const day = date.getDate();
    const month = date.toLocaleString(language.code, { month: 'short' });
    const year = date.getFullYear();
    const dayName = date.toLocaleString(language.code, { weekday: 'short' });
    return `${day} ${month} ${year} | ${dayName}`;
  };

  const isDateEqual = (startDate, endDate) => {
    try {
      return startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear();
    } catch (error) {
      return false;
    }
  };

  const getEvents = () => {
    const onDay = events.filter(e => isDateEqual(new Date(e.startDate), new Date(currentDate)));
    // console.log('currentDate', onDay);
    return onDay;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventList = React.useMemo(() => getEvents(), [currentDate]);

  const handlePrevDay = () => {
    prevDay();
  };

  const handleNextDay = () => {
    nextDay();
  };

  return (
    <Wrapper ref={ref}>
      <Content>
        <TopArea>
          <Title>{formatDate(currentDate)}</Title>
          <Navigations>
            <NavButton $isLeft={true} onClick={handlePrevDay}>
              <ArrowIcon /* onClick={handlePrevDay} */ />
            </NavButton>
            <NavButton onClick={handleNextDay}>
              <ArrowIcon /* onClick={handleNextDay} */ />
            </NavButton>
          </Navigations>
        </TopArea>

        <Container>
          {eventList?.length > 0 ?
            eventList.map((event, idx) => (
              <SessionCard
                key={idx}
                {...event}
              />
            ))
            :
            <BlankContainer>
              <BlankContent>
                <NoEventIcon />
                <BlankText>{t('pages.calendar.no_events_today')}</BlankText>
                <NextDayBtn onClick={handleNextDay}>{t('pages.calendar.next_day')}</NextDayBtn>
              </BlankContent>
            </BlankContainer>
          }
        </Container>
      </Content>
    </Wrapper>
  )
});

export default SidePart;
