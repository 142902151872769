import React from 'react';
import { eventFormatDateStartEnd } from 'helpers';
import eventDoneIcon from 'assets/img/icons/event-done.svg';
import eventStartIcon from 'assets/img/icons/nav_menu/calendar.svg';
import {
  Wrapper,
  Container,
  SingleNotify,
  NotifyIcon,
  // Icon,
  NotifyContext,
  Title,
  Text,
  Line
} from './style';

const Notifications = ({
  notifications
}) => {

  const getNotificationType = (type) => {
    switch (type) {
      case 'EVENT_STARTED':
        return eventStartIcon;
      case 'EVENT_ENDED':
        return eventDoneIcon;
      default:
        return eventDoneIcon;
    }
  };

  return (
    <Wrapper>
      <Container>
        {notifications.map((item, index) => (
          <SingleNotify key={`notification-${index}`}>
            <NotifyIcon>
              <img
                src={getNotificationType(item.type)}
                alt='icon'
              />
            </NotifyIcon>
            <NotifyContext>
              <Title>{item.title}</Title>
              <Text $fSize={'16px'}>{eventFormatDateStartEnd(item?.startDate, item?.endDate)}</Text>
              <Text $fSize={'14px'}>{item.description}</Text>
            </NotifyContext>
            <Line />
          </SingleNotify>
        ))}

      </Container>
    </Wrapper>
  )
}

export default Notifications;
