import styled from 'styled-components';

export const CodeInputArea = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: ${({ $inputMargin }) => $inputMargin || '13px'};
  }
  margin-bottom: 55px;
  ${({ $containerStyle }) => $containerStyle}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    justify-content: center;
    ${({ $mobileStyle }) => $mobileStyle}
  }
`;

export const CodeInput = styled.input`
  width: 56px;
  height: 56px;
  background: #FFFFFF;
  border: 0.811015px solid #C6CAC6;
  border-radius: 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25.9525px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #B0B7C3;
  ${({ $error }) => $error && `
    border: 1px solid red;
  `}
  &:focus {
    color: #0064A8;
  }
`;
