import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;

export const Container = styled.div`
  width: 100%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 24px);
  }
`;

export const SingleNotify = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  position: relative;
  margin: 8px 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    all: unset;
    display: flex;
    padding: 12px;
  }
`;

export const NotifyIcon = styled.div`
  /* width: 45px; */
  /* height: 45px; */
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  background: #FFC000;
  background: red;
  opacity: 0.32;
  border-radius: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 55px;
`;

export const NotifyContext = styled.div`
  width: calc(100% - 8px);
  padding-left: 8px;
  margin-bottom: 8px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 53px);
  }
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $fSize }) => $fSize || '16px'};
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #B0B7C3;
  margin-bottom: 8px;
`;

export const Line = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: rgba(233, 234, 236, 0.6);
  bottom: 0;
`;
