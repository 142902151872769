import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 24px;
  padding-right: 24px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 0;
    padding-right: 12px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #203662;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  background: #FFFFFF;
  border: 0.5px solid rgba(198, 202, 198, 0.14);
  border-radius: 60px;
  position: relative;
  width: 226px;
  padding: 4px;
  height: 44px;
  justify-content: center;
  margin-left: 16px;
`;

export const ButtonItem = styled.button`
  outline: none;
  border: none;
  background: ${({ $isActive }) => $isActive ? '#0064A8' : '#fff'};
  /* border: ${({ $isActive }) => $isActive ? '0.25px solid #B0B7C3' : '0.5px solid rgba(198, 202, 198, 0.14)'}; */
  border: ${({ $isActive }) => !$isActive && '0.25px solid #B0B7C3'};
  color: ${({ $isActive }) => $isActive ? '#fff' : '#B0B7C3'};
  border-radius: ${({ $left }) => $left ? '60px 0px 0px 60px' : '0px 60px 60px 0px'};
  /* width: 50%; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  svg {
    margin-right: 8px;
  }
  svg > path {
    fill: ${({ $isActive }) => $isActive ? '#ffff' : '#B0B7C3'}
  };
`;
