import React from 'react';
import { ReactComponent as ErrorIcon } from 'assets/img/icons/error.svg';
import { Container } from './style';

const ErrorContainer = ({ message, sx }) => {
  if (!message) {
    return null;
  };

  return (
    <Container $sx={sx}>
      <ErrorIcon />
      <span>{message}</span>
    </Container>
  )
}

export default ErrorContainer;
