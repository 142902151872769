// import { ReactComponent as CalendarLogo } from 'assets/img/icons/nav_menu/calendar.svg';
import { ReactComponent as EventsLogo } from 'assets/img/icons/nav_menu/events.svg';
// import { ReactComponent as SpeakersLogo } from 'assets/img/icons/nav_menu/speakers.svg';
import { ReactComponent as BlogLogo } from 'assets/img/icons/nav_menu/article.svg';
import { ReactComponent as DashboardLogo } from 'assets/img/icons/nav_menu/dashboard.svg';
import { PATHS } from 'routes/paths';

export const MENU = Object.freeze([
  {
    name: 'Dashboard',
    key: 'dashboard',
    path: PATHS.HOME,
    icon: DashboardLogo
  },
  // {
  //   name: 'Calendar',
  //   key: 'agenda',
  //   path: PATHS.CALENDAR,
  //   icon: CalendarLogo
  // },
  // {
  //   name: 'Spekaers',
  //   key: 'speakers',
  //   path: PATHS.SPEAKERS,
  //   icon: SpeakersLogo
  // },
  // {
  //   name: 'Contents',
  //   key: 'contents',
  //   path: PATHS.CONTENTS,
  //   icon: BlogLogo,
  //   iconStyle: {
  //     width: '27px',
  //     height: '27px',
  //   }
  // },
  {
    name: 'Events',
    key: 'events',
    path: PATHS.EVENTS,
    icon: EventsLogo
  },
]);


