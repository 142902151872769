import React from 'react';
import {
  Container
} from './style';

const ActivityDocumentsTab = () => {
  return (
    <Container>
      <p>ActivityDocuments</p>
    </Container>
  )
};

export default ActivityDocumentsTab;
