import {
  eventInstance,
  eventParticipantInstance,
  eventMasterInstance,
  dashboardInstance
} from 'services/http';
import errorHandler from './errorHandler';

export const getAllEvents = async ({ lang }) => {
  try {
    const { data } = await eventInstance({ lang }).get('/', { params: { order_desc: 1 } });
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const filterEvent = async ({ lang, filter }) => {
  try {
    const { data } = await eventInstance({ lang }).get('', { params: { ...filter } });
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getSingleEvent = async ({ lang, id }) => {
  try {
    const { data } = await eventInstance({ lang }).get(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const joinEvent = async ({ lang, id, is_reminder }) => {
  try {
    const { data } = await eventParticipantInstance({ lang }).post(`/${id}`, { is_reminder });
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const leaveEventRequest = async ({ lang, id }) => {
  try {
    const { data } = await eventParticipantInstance({ lang }).delete(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const changeReminder = async ({ lang, id }) => {
  try {
    const { data } = await eventParticipantInstance({ lang }).put(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

// Delete this
export const getJoinedEvent = async ({ lang, id }) => {
  try {
    const { data } = await eventParticipantInstance({ lang }).get(`/${id}`);
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getAllEventTypes = async ({ lang }) => {
  try {
    const { data } = await eventMasterInstance({ lang, path: 'event-type' }).get();
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const getCalendarEventDots = async ({ lang, today }) => {
  try {
    // const tsDay = day?.getTime?.() / 1000;
    const { data } = await dashboardInstance({ lang }).get('/calendar', { params: { today: today } });
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const createQuestion = async ({ lang, question, eventId }) => {
  try {
    const payload = {
      message: question,
      event: eventId
    };
    const { data } = await eventMasterInstance({ lang, path: 'event-question' }).post('/', payload)
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const watchEventService = async ({ lang, eventId }) => {
  try {
    const { data } = await eventMasterInstance({ lang }).get(`/watch/${eventId}`)
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};

export const eventParticipantService = async ({ lang, eventId }) => {
  try {
    const { data } = await eventMasterInstance({ lang, path: 'event-participant' })
      .put(`/watched/${eventId}`)
    return data;
  } catch (error) {
    const err = errorHandler(error);
    throw err;
  }
};
