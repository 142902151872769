import React from 'react';
import AvatarPlaceHolder from 'assets/img/icons/speaker.svg';
import ImagePlaceHolder from 'assets/img/images/image-placeholder.svg';
import { LanguageContext } from 'context/withLanguages';
import {
  Wrapper,
  SpeakerInfoArea,
  Image,
  SpeakerDetail,
  Title,
  Name,
  Profession,
  JobLocation,
  Description,
  Line,
  SubTitle,

  EventsContainer,
  SingleEvent,
  EventHeader,
  EventContext,
  EventImage,
  EventTitle,
  EventDescription
} from './style';

const SpeakerModal = ({
  name,
  title,
  profession,
  jobLocation,
  avatar,
  description,
  events
}) => {
  const { t } = React.useContext(LanguageContext);
  return (
    <Wrapper>
      <SpeakerInfoArea>
        <Image
          src={avatar}
          onError={(e) => {
            e.onerror = null;
            e.target.src = AvatarPlaceHolder;
          }}
        />
        <SpeakerDetail>
          <Title>{title}</Title>
          <Name>{name}</Name>
          <Profession>{profession}</Profession>
          <JobLocation>{jobLocation}</JobLocation>
          <Line />
          <Description>{description}</Description>
          <Description>{description}</Description>

        </SpeakerDetail>
      </SpeakerInfoArea>
      <Line $mt={'16px'} $mb={'16px'} />
      <SubTitle>{t('pages.speakers.speaker_events')}</SubTitle>


      {/* Events */}
      <EventsContainer>
        {events?.map((event, idx) => (
          <SingleEvent key={`modal_speaker_events_${idx}`}>
            <EventHeader>
              <EventImage
                $width={'44px'}
                $height={'80%'}
                $border={true}
                src={event.banner}
                alt={description}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = ImagePlaceHolder;
                  e.target.style.height = '75px'
                }}
              />
              <EventContext>
                <EventImage
                  $width={'fit-content'}
                  $maxWidth={'30%'}
                  $height={'25px'}
                  src={event.logo}
                  alt={title}
                  onError={(e) => {
                    e.onerror = null;
                    e.target.src = ImagePlaceHolder;
                  }}
                />
                <EventTitle title={event.title}>{event.title}</EventTitle>
              </EventContext>
            </EventHeader>
            <EventDescription title={event.description}>{event.description}</EventDescription>
            <Line $mt={'13px'} />
          </SingleEvent>
        ))}
      </EventsContainer>


    </Wrapper>
  )
};

export default SpeakerModal;
