import React from 'react';
import { LanguageContext } from 'context/withLanguages';
import Speaker from './components/Speaker';
import SpeakerModal from './components/SpeakerModal';
import Modal from 'components/Modal';
import {
  Wrapper,
  Title,
  SpeakerContainer
} from './style';
import { speakers } from 'helpers/mocks'; // TODO :: mock data

const Speakers = () => {
  const [selectedSpeaker, setSelectedSpeaker] = React.useState(null);
  const { t } = React.useContext(LanguageContext);
  const speakerModalRef = React.useRef(null);

  const openSpeakerDetail = (speaker) => {
    setSelectedSpeaker(speaker)
    speakerModalRef.current.openModal();
  };

  return (
    <Wrapper>
      <Title>{t('pages.speakers.title')}</Title>
      <Modal
        ref={speakerModalRef}
        title={t('pages.speakers.speaker')}
      >
        <SpeakerModal
          name={selectedSpeaker?.name}
          avatar={selectedSpeaker?.avatar}
          title={selectedSpeaker?.title}
          profession={selectedSpeaker?.profession}
          jobLocation={selectedSpeaker?.jobLocation}
          description={selectedSpeaker?.description}
          events={selectedSpeaker?.events}
        />
      </Modal>
      <SpeakerContainer>
        {speakers.map((speaker, idx) => (
          <Speaker
            key={`speaker_${idx}`}
            name={speaker.name}
            title={speaker.title}
            profession={speaker.profession}
            jobLocation={speaker.jobLocation}
            avatar={speaker.avatar}
            description={speaker.description}
            events={speaker.events}
            openSpeakerDetail={openSpeakerDetail}
          />
        ))}
      </SpeakerContainer>
    </Wrapper>
  )
}

export default Speakers;
