import styled from 'styled-components';

export const MobileWrapper = styled.aside`
  background: #E6ECFE;
  padding: 20px 35px 60px 35px;
`;

export const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  /* width: 354px; */
  width: 300px;
  height: calc(100% - 24px);
  background: #E6ECFE;
  padding: 0 24px 24px 24px;
  overflow-y: auto;
/* 
  transition-property: width,height;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1); */

  @media only screen and (max-width: 1030px) {
    width: 260px;
  };

  @media only screen and (max-width: 875px) {
    width: 260px;
  };
`;

export const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 6px;
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: #E6ECFE;
`;

export const NotificationButton = styled.button`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  background: #8DC1C5;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: grid;
  place-items: center;
  position: relative;
`;

export const RedDot = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #DF6675;
  border: 1px solid #FFFFFF;
  top: -10px;
  right: -5px;
`;

export const Title = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #203662;
`;

export const CalendarArea = styled.div`
  margin-top: 30px;
  padding: 16px 13px;
  background-color: #fff;
  border-radius: 16px;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 0;
  };
`;

export const SessionContainer = styled.div`
  margin-top: 20px;
  & > :not(:last-child) {
   margin-bottom: 16px;
  }
`;

export const BlankContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #FFFFFF;
  border-radius: 16px;
  min-height: 40vh;
`;

export const BlankContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
`;

export const BlankText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #203662;
  padding: 0 30px;
  margin-top: 24px;
`;
